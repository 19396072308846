import i18next from 'i18next'
import React from 'react';
import { GasComponent, COLOR_WETNOTES, WTNPanel } from "../WTNCommons";
import { PSCRSettings } from "./PSCRSettings";
import MdSettings from 'react-ionicons/lib/MdSettings'
import MdClose from 'react-ionicons/lib/MdClose'
import { PSCRModel } from './PSCRModel';
import { PSCRComponent } from './PSCRComponent';
import { DiverComponent } from '../wetnotes/DiverComponent';
import { EnvironmentComponent } from '../wetnotes/EnvironmentComponent';
import { PSCRTableEstablishedComponent } from './PSCRTableEstablished';
import { O2DropComponent } from './O2DropComponent';

export class PSCRScreen extends React.Component {
	constructor(props){
		super(props);
		this.id = props.id !== undefined?props.id:'defaultPSCR';
		this.pscrModel = new PSCRModel();
		window.pscrModel = this.pscrModel;
		this.restoreStateOfPSCR();
		this.state = { toggleSettingsOn : false};
	}
	restoreStateOfPSCR(){
		const loadedState = localStorage.getItem(this.id);
		if(loadedState === null) return;
		this.pscrModel.setStateJSON(JSON.parse(loadedState));
	}
	componentDidMount() {
		this.pscrModel.addPropertyChangeListener(this);
	}
	componentWillUnmount() {
		this.pscrModel.removePropertyChangeListener(this);
	}
	propertyChange(propertyChangeEvent){
		if(propertyChangeEvent.getSource() === this.pscrModel){
			this.saveStateOfPSCR();
		}
	}
	saveStateOfPSCR(){
		localStorage.setItem(this.id, JSON.stringify(this.pscrModel.getStateJSON()));
	}
	render() {
		const divStyle = {  minWidth: '22em', };
		const rowToggledOn="row";
		const rowToggledOff="row d-none d-lg-flex";
			return (
						<div className = "container p-0 mt-2 text-left">
						<h4>{i18next.t('pscr.screen.title')}</h4>
						<div className = "row">
							<div className = "col-lg-5">
								<div className={this.state.toggleSettingsOn?rowToggledOff:rowToggledOn}>
									<div className = "col">
										<WTNPanel style = {divStyle}>
											<PSCRComponent pscrModel = {this.pscrModel} nameI18n = "pscr.title"></PSCRComponent>
											<EnvironmentComponent className="mt-1" pscrModel = {this.pscrModel} nameI18n = "pscr.divesite"></EnvironmentComponent>
										</WTNPanel>					
									</div>
								</div>
								<div className={this.state.toggleSettingsOn?rowToggledOff:rowToggledOn}>
									<div className = "col">
									<WTNPanel style = {divStyle}>
											<GasComponent gasModel = {this.pscrModel.gasModel} bulkWrapper = {true} showPressure = {false}></GasComponent>
											<DiverComponent className="mt-1" pscrModel = {this.pscrModel} nameI18n = "pscr.diver"></DiverComponent>
									</WTNPanel>					
									</div>
								</div>
								<div className={this.state.toggleSettingsOn?rowToggledOn:rowToggledOff}>
									<div className = "col">
										<PSCRSettings></PSCRSettings>
									</div>
								</div>
							</div>
							<div className = "col-lg-7 ">
							<div className={this.state.toggleSettingsOn?rowToggledOff:rowToggledOn}>
								<div className = "col">
									<WTNPanel style = {divStyle}>
										<O2DropComponent pscrModel = {this.pscrModel}></O2DropComponent>
									
									</WTNPanel>	
									<WTNPanel style = {divStyle}>
										<PSCRTableEstablishedComponent pscrModel = {this.pscrModel}></PSCRTableEstablishedComponent>
									</WTNPanel>	
								</div>
								</div>
							</div>
						</div>
						
						<div className="wtn-settings-toggler d-inline-block d-lg-none" onClick={e => this.setState({toggleSettingsOn: !this.state.toggleSettingsOn})}>
								{this.state.toggleSettingsOn?
									<MdClose fontSize="3em" color={COLOR_WETNOTES}></MdClose>:<MdSettings fontSize="3em" color={COLOR_WETNOTES}></MdSettings>
								}
						</div>
						</div>
		)
	}
	}