/* (c) Oleg Gurevich, 2019
 * This stuff here is copyrighted under MIT.
 */
export class PhysicsCore {
    /* Common library for physics constants and functions */
    static get PSI_IN_ONE_BAR() { return 14.5037738007218; }
    static get KELVIN_DELTA_CELSIUS() { return 273.15; }
    static get BAR_IN_ONE_ATA() { return 1.01325; }
    static get LITER_IN_ONE_CUFT() { return 28.3168; }
    static get CUI_IN_ONE_LITER() { return 61.0237441; }
    static get PaOfATA() { return 101325; } //Pa 
    static get Pa2Bar() { return 1.0E5; } //Pa in one Bar 
    static barToAta(barValue) {
        return barValue / PhysicsCore.BAR_IN_ONE_ATA;
    }
    /**
	 * computes AirPressure on the surface for a given elevation (meters)
	 * @param elevationMeters
	 * @return airPressure at give elevation in Pa
	 */
    static getAirPressureAtElevationPa(elevationMeters){
		const ata = PhysicsCore.getAirPressureAtElevationAta(elevationMeters);
		const result = PhysicsCore.PaOfATA * ata;
	    return result;
	}
	/**
	 * computes AirPressure on the surface for a given elevation (meters)
	 * @param elevationMeters
	 * @return airPressure at give elevation in Bar
	 */
    static getAirPressureAtElevationBar(elevationMeters){
		const ata = PhysicsCore.getAirPressureAtElevationAta(elevationMeters);
		const result = PhysicsCore.BAR_IN_ONE_ATA * ata;
	    return result;
	}
    /**
	 * computes AirPressure on the surface for a given elevation (meters)
	 * @param elevationMeters
	 * @return airPressure at give elevation in Pa
	 */
    static getAirPressureAtElevationAta(elevationMeters){
		// Formula: P(h) = 101325*(1 - 0.0065 * h / 288.15)**5.255
		const d = 1.0 - 0.0065 * elevationMeters / 288.15;
		const ata = Math.pow(d, 5.255);
	    return ata;
	}
    
}