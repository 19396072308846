import { WetNotes } from '../wetnotes/wetnotes';
import i18next from 'i18next';
export class PSCRResultBuilder{
	constructor(pscrModel){
		this.pscrModel = pscrModel;
	}

	getEstablishedMaxOperativeDepth(){
		let depth = 0;
		for(let d = depth; d < 220; d++){
			depth = d;
			if( this.getPartialPressure(this.pscrModel.getEstablishedFiO2(d), d) 
					>= WetNotes.SETTINGS.getMaxPpO2()) break;
		}
		return depth;
	}
	buildChartData(depth){
		const chartData = [];
		const fiStabilized = this.pscrModel.getEstablishedFiO2(depth);
		for(let timeLine = 0; timeLine < 960; timeLine = timeLine + 60){
			const secs = (timeLine / 60).toFixed(0) + " " + i18next.t('minutes.short');
			chartData.push({"name" : secs, 
			"fiO2" : this.pscrModel.getFiO2AtTime(depth, timeLine),
			"fiO2Stabilized" : fiStabilized
		})
		}
		return chartData;
	}
	createResultTableData() {
		const resultTableData = [];
		if(this.pscrModel.gasModel.getO2() === 0) return resultTableData;

		const maxDepth = this.getEstablishedMaxOperativeDepth();
		const minDepth = 0;

		let depthStep = 2;
		let startDepth = minDepth;

		startDepth = Math.floor(startDepth / depthStep) * depthStep;

		if(!WetNotes.SETTINGS.isMetricSystem()){
      // redefine start depth and step value
      // use 10 feets as step on imperial system
			depthStep = 10.0 / WetNotes.MEASSURES.Depth.getK2Imperial();
			let imperialStartDepth = WetNotes.MEASSURES.Depth.getAdjustedOfMetric(minDepth);
			imperialStartDepth =  Math.floor(imperialStartDepth / 10) * 10;
			startDepth = imperialStartDepth / WetNotes.MEASSURES.Depth.getK2Imperial();
    }
    
    if((maxDepth - minDepth) / depthStep > 96){
      depthStep = depthStep * 3;
    }else
    if((maxDepth - minDepth) / depthStep > 42){
      depthStep = depthStep * 2;
    }

		for(let d = startDepth; d < maxDepth; d = d + depthStep){
      // row's data
			const resultTableRow = this.getResultRow(d);
			resultTableData.push(resultTableRow);
		}
		// row's data
    const d = maxDepth;
		const resultTableRow = this.getResultRow(d);
		resultTableData.push(resultTableRow);
    		return resultTableData;
	}
	
	getResultRow(depth){
		const resultTableRow = {};
		resultTableRow.depth = depth;
		resultTableRow.startFiO2 = this.pscrModel.gasModel.getFiO2();
		resultTableRow.ppO2supplied = this.getPartialPressure(resultTableRow.startFiO2, depth);
		resultTableRow.fiHe = this.pscrModel.gasModel.getFiHe();
		resultTableRow.fiN2 = this.pscrModel.gasModel.getFiN2();
		resultTableRow.establishedFiO2 = this.pscrModel.getEstablishedFiO2(depth);
		resultTableRow.ppEstablishedO2 = this.getPartialPressure(resultTableRow.establishedFiO2, depth);
		resultTableRow.ppN2 = this.getPartialPressure(resultTableRow.fiN2, depth);
		resultTableRow.ppHe = this.getPartialPressure(resultTableRow.fiHe, depth);
		resultTableRow.narcPP = this.getNarcPP(resultTableRow);
		resultTableRow.end = this.getEND(resultTableRow);
		return resultTableRow;
	}
	getPartialPressure(fraction, depth) {
		const barsOnSurface = this.pscrModel.environmentModel.getPressureAtSurface_Bar();
		return fraction * (depth / 10.0 + barsOnSurface);
	}
	getNarcPP(resultTableRow) {
		const kNarcO2N = WetNotes.SETTINGS.getNacroticEffectFactorO2ToN2();
		const kNarcHe2N = WetNotes.SETTINGS.getNacroticEffectFactorHeToN2();
		const nacrFraction = resultTableRow.fiN2 + resultTableRow.fiHe * kNarcHe2N + 
			resultTableRow.establishedFiO2 * kNarcO2N;
		return this.getPartialPressure(nacrFraction, resultTableRow.depth);
	}
	/** 
	* @return END in meters
	*/
	getEND(resultTableRow){
		const depthMeters = resultTableRow.depth;
    const kNarcO2N = WetNotes.SETTINGS.getNacroticEffectFactorO2ToN2();
		const kNarcHe2N = WetNotes.SETTINGS.getNacroticEffectFactorHeToN2();
		const nacrFraction = resultTableRow.fiN2 + resultTableRow.fiHe * kNarcHe2N + 
			resultTableRow.establishedFiO2 * kNarcO2N;
		let end = (100 * nacrFraction / (79.0 + 21.0 * kNarcO2N) * (10.0 + depthMeters) - 10);
		if(end < 0) return undefined;
		return end;
	}
		
}
