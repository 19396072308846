
import React from 'react';
import  {WetNotes, WetNotesSettings}  from '../wetnotes/wetnotes.js'

import { createAnId, WTN_Number_Model_Component, COLOR_WETNOTES, COLOR_MAX_PPO2, COLOR_MIN_PPO2, COLOR_MAX_END } from '../WTNCommons'
import { useTranslation } from 'react-i18next';
import { OptionUnits, OptionLanguageUI, OptionShowDetails } from '../SharedOptions'
import MdBookmark from 'react-ionicons/lib/MdBookmark'


import MdSettings from 'react-ionicons/lib/MdSettings'
import i18next from 'i18next'


export function PSCRSettings(props){
  const { t } = useTranslation();
  return (
    <div className="bg-white p-3 rounded-lg border mb-4">
      <h5><MdSettings fontSize="2em" color={COLOR_WETNOTES}></MdSettings> {t('pscr.settings')}</h5>
      <div className="row">
        <div className="col-lg">
				  <OptionShowDetails></OptionShowDetails>
          <OptionMaxEND></OptionMaxEND>
          <h5>{t('Partial pressure of Oxygen')} </h5>
          <OptionMaxPpO2></OptionMaxPpO2>
          <OptionMinPpO2></OptionMinPpO2>
          <h5>{t('Narcotic effect factor to Nitrogen')}</h5>
          <OptionNarcoticEffectFactorO2></OptionNarcoticEffectFactorO2>
          <OptionNarcoticEffectFactorHe></OptionNarcoticEffectFactorHe>
          <OptionUnits></OptionUnits>
          <OptionLanguageUI></OptionLanguageUI>
        </div>
      </div>
    </div>
  )
}
/**
 * Max END 20 .. 65 m
 */
export class OptionMaxEND extends WTN_Number_Model_Component{
  constructor(props){
    super(props);
    this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
    this.state = {
      editMode: false, 
      editValue: this.formatToView(this.modelToView())
    };
  }
  formatToView(viewValue){
    return WetNotes.formatDepth(viewValue);
  }
  
  componentDidMount() {
    WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_MAX_END, WetNotesSettings.PROPERTY_UNITS]);
  }
  
  componentWillUnmount() {
    WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_MAX_END, WetNotesSettings.PROPERTY_UNITS]);
  }
  isEditorValueValid(){
    if(this.state.editValue === "") return false;
    return true;
  }
  modelToView(){
    return WetNotes.MEASSURES.Depth.getAdjustedOfMetric(WetNotes.SETTINGS.getMaxEND());
  }
  applyEditValueOnModel(e){
    if (this.isEditorValueValid()){
      const numberValue = Number.parseFloat(this.state.editValue);
      let newNumberValue = WetNotes.MEASSURES.Depth.getMetricOfCurrent(numberValue);
      if(newNumberValue < 20) newNumberValue = 20;
      if(newNumberValue > 65) newNumberValue = 65;
      WetNotes.SETTINGS.setMaxEND(newNumberValue);
    }
  }
  getLabel(props){
    return (
      <span>
       {i18next.t('Maximum END')} ({WetNotes.MEASSURES.Depth.getLabel()})<MdBookmark color = { COLOR_MAX_END }></MdBookmark>
      </span>
    )
  }
}

/** 1.2 - 1.8 */
export class OptionMaxPpO2 extends React.Component{
  constructor(props){
    super(props);
    this.id = (undefined === props.id)?createAnId():props.id; 
  }
  componentDidMount() {
    WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_MAX_PPO2]);
  }

  componentWillUnmount() {
    WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_MAX_PPO2]);
  }
  propertyChange(propertyChangeEvent){
    this.setState({
      lastChangeEvent : propertyChangeEvent
    }); 
  }
  render(props){
   	const options = [];
		for (let ppO2 = 1.2; ppO2 < 1.9; ppO2+= 0.1){
			options.push(<option value={Number.parseFloat(ppO2.toFixed(1))}>{ppO2.toFixed(1)}</option>)
    }
    options[0] = (<option value={1.2}>{i18next.t('1.2 - (conservative)')}</option> );
    options[2] = (<option value={1.4}>{i18next.t('1.4 - (default)')}</option> );
    options[4] = (<option value={1.6}>{i18next.t('1.6 - (maximal)')}</option> );
    return (
      <div className="form-group row">
      
        <label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>
        {i18next.t('Maximum p.p. O2')} <MdBookmark color = { COLOR_MAX_PPO2 }></MdBookmark> </label>
        <div className="col-sm-7">
          <select id={this.id} className="form-control" value={WetNotes.SETTINGS.getMaxPpO2()} 
              onChange={(event) => WetNotes.SETTINGS.setMaxPpO2(event.target.value)}>
							{ options }
          </select>
          
        </div>
      </div>
    )
  }
}
/** 0.14 - 0.20 */
export class OptionMinPpO2 extends React.Component{
  constructor(props){
    super(props);
    this.id = (undefined === props.id)?createAnId():props.id; 
  }
  componentDidMount() {
    WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_MIN_PPO2]);
  }

  componentWillUnmount() {
    WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_MIN_PPO2]);
  }
  propertyChange(propertyChangeEvent){
    this.setState({
      lastChangeEvent : propertyChangeEvent
    }); 
  }
  render(props){
    const options = [];
		for (let ppO2 = 0.14; ppO2 < 0.21; ppO2+= 0.01){
			options.push(<option value={Number.parseFloat(ppO2.toFixed(2))}>{ppO2.toFixed(2)}</option>)
    }
    options[2] = (<option value={0.16}>{i18next.t('0.16 - (default)')}</option> );
    return (
      <div className="form-group row">
        <label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>
        {i18next.t('Minimum p.p. O2')} <MdBookmark color = { COLOR_MIN_PPO2 }></MdBookmark> </label>
        <div className="col-sm-7">
          <select id={this.id} className="form-control" value={WetNotes.SETTINGS.getMinPpO2()} 
              onChange={(event) => WetNotes.SETTINGS.setMinPpO2(event.target.value)}>
							{ options }
          </select>
          
        </div>
      </div>
    )
  }
}
/** 0.0 - 1.0 */
export class OptionNarcoticEffectFactorO2 extends React.Component{
  constructor(props){
    super(props);
    this.id = (undefined === props.id)?createAnId():props.id; 
  }
  componentDidMount() {
    WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_O2_TO_N2]);
  }

  componentWillUnmount() {
    WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_O2_TO_N2]);
  }
  propertyChange(propertyChangeEvent){
    this.setState({
      lastChangeEvent : propertyChangeEvent
    }); 
  }
  render(props){
		const options = [];
		for (let f = 0.0; f < 1.0; f+= 0.1){
			options.push(<option value={Number.parseFloat(f.toFixed(1))}>{f.toFixed(1)} x 1 N2</option>)
    }
    options[0] = (<option value={0}>{i18next.t('no effect (recreational)')}</option> );
    options[10] = (<option value={1}>{i18next.t('1.0 x 1 N2 (DIR)')}</option> );
    return (
      <div className="form-group row">
        <label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>
        {i18next.t('Narcotic effect of Oxygen')} </label>
        <div className="col-sm-7">
          <select id={this.id} className="form-control" value={WetNotes.SETTINGS.getNacroticEffectFactorO2ToN2()} 
              onChange={(event) => WetNotes.SETTINGS.setNacroticEffectFactorO2ToN2(event.target.value)}>
							{ options }
          </select>
          
        </div>
      </div>
    )
  }
}
/** 0.0 - 0.25 */
export class OptionNarcoticEffectFactorHe extends React.Component{
  constructor(props){
    super(props);
    this.id = (undefined === props.id)?createAnId():props.id; 
  }
  componentDidMount() {
    WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_HE_TO_N2]);
  }

  componentWillUnmount() {
    WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_HE_TO_N2]);
  }
  propertyChange(propertyChangeEvent){
    this.setState({
      lastChangeEvent : propertyChangeEvent
    }); 
  }
  render(props){
		const options = [];
		for (let f = 0.0; f < 0.3; f+= 0.05){
			options.push(<option value={Number.parseFloat(f.toFixed(2))}>{f.toFixed(2)} x 1 N2</option>)
    }
    options[0] = (<option value={0.0}>{i18next.t('no effect (default)')}</option>)
    return (
      <div className="form-group row">
        <label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>
        {i18next.t('Narcotic effect of Helium')} </label>
        <div className="col-sm-7">
          <select id={this.id} className="form-control" value={WetNotes.SETTINGS.getNacroticEffectFactorHeToN2()} 
              onChange={(event) => WetNotes.SETTINGS.setNacroticEffectFactorHeToN2(event.target.value)}>
							{ options }
          </select>
          
        </div>
      </div>
    )
  }
}

