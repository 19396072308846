/* (c) Oleg Gurevich, 2019
 * This stuff here is copyrighted under MIT.
 */
import { PropertyChangeSupport } from './PropertyChangeSupport.js'
import { PhysicsCore } from './PhysicsCore.js'
import { PartialGas, GasCoefficient, RealGas } from './realgas.js'
import i18next from 'i18next';

/**
 * base class with propertyChangeSupport
 */
export class WetModel {
    constructor() {
        this.propertyChangeSupport = new PropertyChangeSupport(this);
    }
    addPropertyChangeListener(listener, property_name = null) {
        this.propertyChangeSupport.addPropertyChangeListener(listener, property_name);
    }
    removePropertyChangeListener(listener, property_name = null) {
        this.propertyChangeSupport.removePropertyChangeListener(listener, property_name);
    }
}

export class BlendingMode {
    static get He_O2_TopOff() { return "He_O2_TopOff"; }
    static get O2_He_TopOff() { return "O2_He_TopOff"; }
    static get O2_TopOff_He() { return "O2_TopOff_He"; }
    static get He_AutoEAN() { return "He_AutoEAN"; }
}



class Meassure {
    constructor(i18nMetric, i18nImperial, k2Imperial) {
            this.labelMetricI18n = i18nMetric;
            this.labelImperialI18n = i18nImperial;
            this.k2Imperial = k2Imperial;
        }
        /**
         * provides a value of model in actual MEASURES System
         * @param metricValue
         * @return metric Value if Metric System set or Imperial Value
         */
    getAdjustedOfMetric(metricValue) {
        if (WetNotes.SETTINGS.isMetricSystem()) return metricValue;
        else return this.convert2Imperial(metricValue);
    }
    getK2Imperial(){
        return this.k2Imperial;
    }

    /**
     * provides a value for model in METRIC
     * @param ValueInCurrentMode
     * @return metric Value
     */
    getMetricOfCurrent(currentValue) {
        if (WetNotes.SETTINGS.isMetricSystem()) return currentValue;
        else return this.convert2Metric(currentValue);
    }


    convert2Metric(imperialValue) {
        return imperialValue / this.k2Imperial;
    }

    convert2Imperial(metricValue) {
        return metricValue * this.k2Imperial;
    }

    /**
     * @return the labelMetricI18n
     */
    getLabelMetric() {
        return i18next.t(this.labelMetricI18n);
    }

    /**
     * @return the labelImperialI18n
     */
    getLabelImperial() {
            return i18next.t(this.labelImperialI18n);
        }
        /**
         * @return the label18n
         */
    getLabel() {
        if (WetNotes.SETTINGS.isMetricSystem()) return this.getLabelMetric();
        else return this.getLabelImperial();
    }
}

const MEASSURES = {
    "Pressure": new Meassure('bar', 'psi', PhysicsCore.PSI_IN_ONE_BAR),
    "Depth": new Meassure('meter', 'feet', 3.2808),
    "Percent": new Meassure("%", "%", 1),
    "Volume": new Meassure('liter', 'cui', PhysicsCore.CUI_IN_ONE_LITER),
    "LiterCuft": new Meassure("liter", "cuft",
        1.0 / PhysicsCore.LITER_IN_ONE_CUFT),
    "Masse": new Meassure('kg', 'pound', 2.2046),
    "Density" : new Meassure('gramm.per.liter', 'lb.per.yd3', 1.685658),
    "DensityGramLiter" : new Meassure('gramm.per.liter', 'gramm.per.liter', 1),
    "BarLiter": new Meassure('barliter', 'atacuft', -1),
    "Temperatur": new Meassure("celsius.short", "faraday.short", -1)
};

MEASSURES.Temperatur.getAdjustedOfMetric = function(metricValue) {
    if (WetNotes.SETTINGS.isMetricSystem()) return metricValue;
    else return MEASSURES.Temperatur.convert2Imperial(metricValue);
}
MEASSURES.Temperatur.getMetricOfCurrent = function(currentValue) {
    if (WetNotes.SETTINGS.isMetricSystem()) return currentValue;
    else return MEASSURES.Temperatur.convert2Metric(currentValue);
}
MEASSURES.Temperatur.convert2Metric = function(imperialValue) {
    return (imperialValue - 32) * 5 / 9
}
MEASSURES.Temperatur.convert2Imperial = function(metricValue) {
    return metricValue * 9 / 5 + 32
}
MEASSURES.capacityImperial2VolumeMetric = function(capacityValue, tankModel) {
    return capacityValue * PhysicsCore.LITER_IN_ONE_CUFT /
        PhysicsCore.barToAta(tankModel.getNominalWorkingPressureBar());
}
MEASSURES.view2ModelVolumeOrCapacity = function(viewValue, tankModel) {
    let volumeLiter = viewValue;
    if (!WetNotes.SETTINGS.isMetricSystem()) {
        volumeLiter = MEASSURES.capacityImperial2VolumeMetric(volumeLiter, tankModel);
    }
    return volumeLiter
}
MEASSURES.model2ViewVolumeOrCapacity = function(tankModel, value = tankModel.getVolume_dm3()) {
    if (!WetNotes.SETTINGS.isMetricSystem()) {
        let literCuft = MEASSURES.LiterCuft.getAdjustedOfMetric(value *
            PhysicsCore.barToAta(tankModel.getNominalWorkingPressureBar()));
        value = literCuft
    }
    return value
}
  
export class WetNotesSettings extends WetModel {
    static get PROPERTY_NAME() { return "prop_name" };
    static get PROPERTY_LANGUAGE_UI() { return "prop_language_ui" };

    static get PROPERTY_UNITS() { return "prop_units" };
    static get PROPERTY_TEMPERATURE() { return "prop_temperature" };
    static get PROPERTY_REAL_GAS_MODEL() { return "model_real_gas_model" };
    static get PROPERTY_SHOW_DETAILS() { return "model_show_details" };
    static get PROPERTY_DISPLAY_SLIDERS() { return "model_display_sliders" };

    static get PROPERTY_BLENDING_MODE() { return "prop_blending_mode" };
    static get PROPERTY_STANDARD_GAS_SET() { return "prop_standard_gas_set" };
    static get PROPERTY_CURRENCY_LABEL() { return "prop_currencyLabel" };
    static get PROPERTY_PRICE_HE() { return "prop_priceHe" };
    static get PROPERTY_PRICE_N2() { return "prop_priceN2" };
    static get PROPERTY_PRICE_O2() { return "prop_priceO2" };
    static get PROPERTY_PRICE_TopOff() { return "prop_priceTopOff" };
    static get PROPERTY_TopOff_flat_price() { return "prop_TopOff_flat_price" };

    static get PROPERTY_MAX_END() { return "prop_maxEND" };
    static get PROPERTY_MAX_PPO2() { return "prop_maxPPO2" };
    static get PROPERTY_MIN_PPO2() { return "prop_minPPO2" };
    static get PROPERTY_NARCOTIC_EFFECT_FACTOR_O2_TO_N2() { return "narcoticEffectFactorO2ToN2" };
    static get PROPERTY_NARCOTIC_EFFECT_FACTOR_He_TO_N2() { return "narcoticEffectFactorHeToN2" };

    constructor() {
        super();
        this.blendingMode = BlendingMode.He_O2_TopOff;
        this.realGas = true;
        this.showDetails = false;
        this.displaySliders = true;
        this.languageUI = 'en';
        this.metricSystem = true;
        this.temperatureK = 293.15;
        this.temperatureK_min = 263.15;
        this.temperatureK_max = 320.15;
        this.volumeLiter_init = 24;
        this.volumeLiter_max = 250;
        this.volumeLiter_min = 1;
        this.pressure_max_bar = 400;
        this.pressure_min_bar = 0;
        /** Maximal Equivalent nitogen(narcotic) depth  */
        this.maxEND = 30.0;
        this.maxPpO2 = 1.4;
        this.minPpO2 = 0.16;
        this.narcoticEffectFactorO2ToN2 = 0; // Recreation - 0, DIR = 1.0 (0 .. 2.0)
        this.narcoticEffectFactorHeToN2 = 0;// 0 .. 0.3

        this.currencyLabel = '€';
        this.priceHe = 0.02;
		this.autoTopoffFiO2Min = 0.21;
		this.autoTopoffFiO2Max = 0.45;
        this.priceO2 = 0.002;
        this.priceN2 = 0.002;
        this.priceTopoff = 0;
        this.priceFlatModeTopoff = true;
        this.standardGasSet = [];
    }
    getStateJSON(){
        return {
            blendingMode : this.getBlendingMode(),
            realGas : this.isRealGasModel(),
            languageUI : this.getLanguageUI(),
            showDetails : this.isShowDetails(),
            displaySliders : this.isDisplaySliders(),
            metricSystem : this.isMetricSystem(),
            temperatureK : this.getTemperatureK(),
            priceHe : this.getPriceHe(),
            priceO2 : this.getPriceO2(),
            currencyLabel : this.getCurrencyLabel(),
            priceN2 : this.getPriceN2(),
            priceTopoff : this.getPriceTopoff(),
            priceFlatModeTopoff : this.isPriceFlatModeTopoff(),
            maxEND : this.getMaxEND(),
            maxPpO2 : this.getMaxPpO2(),
            minPpO2 : this.getMinPpO2(),
            narcoticEffectFactorO2ToN2 : this.getNacroticEffectFactorO2ToN2(),
            narcoticEffectFactorHeToN2 : this.getNacroticEffectFactorHeToN2()
        }
    }
    setStateJSON(jsonState = {}){
        if(jsonState.blendingMode !== undefined) this.setBlendingMode(jsonState.blendingMode);
        if(jsonState.realGas !== undefined) this.setRealGasModel(jsonState.realGas);
        if(jsonState.languageUI !== undefined) this.setLanguageUI(jsonState.languageUI);
        if(jsonState.showDetails !== undefined) this.setShowDetails(jsonState.showDetails);
        if(jsonState.displaySliders !== undefined) this.setDisplaySliders(jsonState.displaySliders);
        if(jsonState.metricSystem !== undefined) this.setMetricSystem(jsonState.metricSystem);
        if(jsonState.temperatureK !== undefined) this.setTemperatureK(jsonState.temperatureK);
        if(jsonState.currencyLabel !== undefined) this.setCurrencyLabel(jsonState.currencyLabel);
        if(jsonState.priceHe !== undefined) this.setPriceHe(jsonState.priceHe);
        if(jsonState.priceO2 !== undefined) this.setPriceO2(jsonState.priceO2);
        if(jsonState.priceN2 !== undefined) this.setPriceN2(jsonState.priceN2);
        if(jsonState.priceTopoff !== undefined) this.setPriceTopoff(jsonState.priceTopoff);
        if(jsonState.priceFlatModeTopoff !== undefined) this.setPriceFlatModeTopoff(jsonState.priceFlatModeTopoff);
        if(jsonState.maxEND !== undefined) this.setMaxEND(jsonState.maxEND);
        if(jsonState.maxPpO2 !== undefined) this.setMaxPpO2(jsonState.maxPpO2);
        if(jsonState.minPpO2 !== undefined) this.setMinPpO2(jsonState.minPpO2);
        if(jsonState.narcoticEffectFactorO2ToN2 !== undefined) this.setNacroticEffectFactorO2ToN2(jsonState.narcoticEffectFactorO2ToN2);
        if(jsonState.narcoticEffectFactorHeToN2 !== undefined) this.setNacroticEffectFactorHeToN2(jsonState.narcoticEffectFactorHeToN2);
        return this;
    }
    getLanguageUI() { return this.languageUI }
    getMaxEND() { return this.maxEND }
    getNarcPPOfMaxEND(){
        const kNarcO2N = this.getNacroticEffectFactorO2ToN2();
        const nacrFraction = 0.79 + 0.21 * kNarcO2N;
        const barsOnSurface = 1;
        return nacrFraction * (this.getMaxEND() / 10.0 + barsOnSurface);
    }
    getCurrencyLabel() { return this.currencyLabel }
    getMaxPpO2() { return this.maxPpO2 }
    getMinPpO2() { return this.minPpO2 }
    getNacroticEffectFactorO2ToN2() { return this.narcoticEffectFactorO2ToN2 }
    getNacroticEffectFactorHeToN2() { return this.narcoticEffectFactorHeToN2 }
    
    setNacroticEffectFactorHeToN2(newValue) {
        let oldValue = this.narcoticEffectFactorHeToN2;
        this.narcoticEffectFactorHeToN2 = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_He_TO_N2,
            oldValue, newValue);
        return this;
    }
    setLanguageUI(newValue) {
        let oldValue = this.languageUI;
        this.languageUI = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_LANGUAGE_UI,
            oldValue, newValue);
        i18next.changeLanguage(newValue);    
        return this;
    }
    setNacroticEffectFactorO2ToN2(newValue) {
        let oldValue = this.narcoticEffectFactorO2ToN2;
        this.narcoticEffectFactorO2ToN2 = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_O2_TO_N2,
            oldValue, newValue);
        return this;
    }
    setMaxPpO2(newValue) {
        let oldValue = this.maxPpO2;
        this.maxPpO2 = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_MAX_PPO2,
            oldValue, newValue);
        return this;
    }
    setCurrencyLabel(newValue) {
        let oldValue = this.currencyLabel;
        this.currencyLabel = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_CURRENCY_LABEL,
            oldValue, newValue);
        return this;
    }
    setMinPpO2(newValue) {
        let oldValue = this.minPpO2;
        this.minPpO2 = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_MIN_PPO2,
            oldValue, newValue);
        return this;
    }
    setMaxEND(newValue) {
        let oldValue = this.maxEND;
        this.maxEND = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_MAX_END,
            oldValue, newValue);
        return this;
    }

    getBlendingMode() { return this.blendingMode }
    isPriceFlatModeTopoff() { return this.priceFlatModeTopoff }
    getPriceN2() { return this.priceN2 }
    getPriceO2() { return this.priceO2 }
    getPriceHe() { return this.priceHe }
	getPriceTopoff() { return this.priceTopoff }
	getAutoTopoffFiO2Min() { return this.autoTopoffFiO2Min }
	getAutoTopoffFiO2Max() { return this.autoTopoffFiO2Max }

    isRealGasModel() { return this.realGas }
    isMetricSystem() { return this.metricSystem }
    isShowDetails() { return this.showDetails }
    isDisplaySliders() { return this.displaySliders }
    getTemperatureK() { return this.temperatureK }
    setTemperatureK(temperatureK) {
        let oldValue = this.temperatureK;
        this.temperatureK = temperatureK;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_TEMPERATURE,
            oldValue, temperatureK);
        return this;
    }
    setBlendingMode(newValue) {
        let oldValue = this.blendingMode;
        this.blendingMode = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_BLENDING_MODE,
            oldValue, newValue);
        return this;
    }
    setPriceO2(newValue) {
        let oldValue = this.priceO2;
        this.priceO2 = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_PRICE_O2,
            oldValue, newValue);
        return this;
    }
    setPriceN2(newValue) {
        let oldValue = this.priceN2;
        this.priceN2 = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_PRICE_N2,
            oldValue, newValue);
        return this;
    }
    setPriceHe(newValue) {
        let oldValue = this.priceHe;
        this.priceHe = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_PRICE_HE,
            oldValue, newValue);
        return this;
    }
    setPriceTopoff(newValue) {
        let oldValue = this.priceTopoff;
        this.priceTopoff = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_PRICE_TopOff,
            oldValue, newValue);
        return this;
    }
    setRealGasModel(realGas) {
        let oldValue = this.realGas;
        this.realGas = realGas;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_REAL_GAS_MODEL,
            oldValue, this.realGas);
        return this;
    }
    setMetricSystem(newValue = true) {
        let oldValue = this.metricSystem;
        this.metricSystem = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_UNITS, oldValue, newValue);
        return this;
    }
    setPriceFlatModeTopoff(newValue = true) {
        let oldValue = this.priceFlatModeTopoff;
        this.priceFlatModeTopoff = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_TopOff_flat_price, oldValue, newValue);
        return this;
    }
    setDisplaySliders(newValue = true) {
        let oldValue = this.displaySliders;
        this.displaySliders = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_DISPLAY_SLIDERS, oldValue, newValue);
        return this;
    }
    setShowDetails(newValue = true) {
            let oldValue = this.showDetails;
            this.showDetails = newValue;
            this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_SHOW_DETAILS, oldValue, newValue);
            return this;
    }
        /**
         * @returns the temperatur in celsius
         */
    getTemperatureC() {
            return this.temperatureK - PhysicsCore.KELVIN_DELTA_CELSIUS;
        }
        /**
         * @param temperature in Celsius
         */
    setTemperatureC(temperatureC) {
        this.setTemperatureK(temperatureC + PhysicsCore.KELVIN_DELTA_CELSIUS);
        return this;
    }

}

export let WetNotes = {
    formatGasComponent: function(componentValueInPercent) { return componentValueInPercent.toFixed(1) },
    formatMasse: function(masseInKilogramm) { return masseInKilogramm.toFixed(3) },
    formatPrice: function(price) { return price.toFixed(4) },
    formatDepth: function(depth) { return depth.toFixed(1) },
    formatPressure: function(pressureBar) { return pressureBar.toFixed(1) },
    formatTemperatur: function(temperatur) { return temperatur.toFixed(1) },
    formatVolume: function(volume) { return volume.toFixed(1) },
    formatPercent: function(percent) { return percent.toFixed(2) },
    formatAmount: function(amount) { return amount.toFixed(2) },
    SETTINGS: new WetNotesSettings(),
    MEASSURES: MEASSURES,

    ensurePercentValue: function(percents) {
        if (percents == null) return 0;
        if (percents < 0) return 0;
        if (percents > 100) return 100;
        return percents;
    },
    getPriceUnitsFactor: function(){
            const unitsFactor = (WetNotes.SETTINGS.isMetricSystem())?1.0:PhysicsCore.BAR_IN_ONE_ATA;
            return unitsFactor;
		 },
    barliterValueToView: function(tankModel) {
        const valueToAdjust = WetNotes.SETTINGS.isMetricSystem()?tankModel.getBarLiter():tankModel.getAtaLiter();
        return WetNotes.MEASSURES.LiterCuft.getAdjustedOfMetric(valueToAdjust);
    },
    barliterLabelToView: function(){
        const a = WetNotes.SETTINGS.isMetricSystem()?i18next.t("barliter"):i18next.t('atacuft');
        return a;
    },
    priceBarliterToView: function(priceBarliter){
        const unitsFactor = WetNotes.getPriceUnitsFactor();
    return  unitsFactor * WetNotes.MEASSURES.LiterCuft.getMetricOfCurrent(priceBarliter);
	}
    

}

export class UIUtils {
    static getGRBAColorString(color, opacity) {
        return "rgba( " + ((color & 0xFF0000) >> 16) + ", " + ((color & 0x00FF00) >> 8) + ", " + (color & 0x0000FF) + ", " + opacity + " )";
    };
}

export class GasElement {

    // http://de.wikipedia.org/wiki/Molares_Volumen
    //normal conditions 22.413996 @ 0C und 100000 Bar 22.710981
    constructor(molareMasse = 0, vm = 22.413996) {
            this.molareMasse = molareMasse;
            this.vm = vm;
        }
        /**
         * @return the molareMasse
         */
    getMolareMasse() {
            return this.molareMasse;
        }
        /** Vm normal conditions (1 - ATA, 0C)
         * @return the molVolumen
         */
    getVm() {
            return this.vm;
        }
        /**Vm bar referenced (1 - Bar 100 000 PA, 0C)
         * @return the molVolumen
         */
    getVmBarReferenced() {
        return this.vm * PhysicsCore.BAR_IN_ONE_ATA;
    }
}

export let Element = {
    O2: new GasElement(0.0319988, 22.392),
    N2: new GasElement(0.0280134, 22.402),
    HE: new GasElement(0.004002602, 22.426),
    // http://de.wikipedia.org/wiki/Molares_Volumen
    //normal conditions 22.413996 @ 0C und 100000 Bar 22.710981
    IdealGas: new GasElement(0, 22.413996)
}



const HUNDERT_PERCENT = 100.0;

export class GasModel extends WetModel {
    /**
     * Universele Gas Konstante
     * http://en.wikibooks.org/wiki/General_Chemistry/Gases
     */
    static get R() { return 8.314472; }
    static get PROPERTY_O2() { return "o2"; }
    static get PROPERTY_HE() { return "he"; }
    static get PROPERTY_PRESSURE() { return "pressure"; }
    static get OXYGEN_DENSITY_AT_1_ATA() { return 1.429; }
    static get HELIUM_DENSITY_AT_1_ATA() { return 0.179; }
    static get NITROGEN_DENSITY_AT_1_ATA() { return 1.2506; }
    static  compare( a, b ) {
    if ( a.getO2() < b.getO2() ){
      return -1;
    }
    if ( a.getO2() > b.getO2() ){
      return 1;
    }
    if ( a.getHe() < b.getHe() ){
        return -1;
      }
      if ( a.getHe() > b.getHe() ){
        return 1;
      }
    return 0;
   }
    constructor(o2Percent = 21, hePercent = 0, pressureBar = 200) {
        super();
        this.o2 = o2Percent;
        this.he = hePercent;
        this.pressureBar = pressureBar;
        this.name = "noname";
    }
    
    getDensityAt1Ata(){
        const density =  this.getFiO2() * GasModel.OXYGEN_DENSITY_AT_1_ATA +
        this.getFiHe() * GasModel.HELIUM_DENSITY_AT_1_ATA +
        this.getFiN2() * GasModel.NITROGEN_DENSITY_AT_1_ATA ;
        return density;
    }
    getStateJSON(){
        return {
            o2 : this.getO2(),
            he : this.getHe(),
            pressureBar : this.getPressure_Bar(),
            name : this.getName()
        }
    }
    setStateJSON(jsonState = {}){
        if(jsonState.o2 !== undefined) this.setO2(jsonState.o2);
        if(jsonState.he !== undefined) this.setHe(jsonState.he);
        if(jsonState.pressureBar !== undefined) this.setPressure_Bar(jsonState.pressureBar);
        if(jsonState.name !== undefined) this.setName(jsonState.name);
        return this;
    }
    getGasName(){
        if(this.getO2() === 100) return i18next.t('Oxygen');
        if(this.getHe() === 100) return i18next.t('Helium');
        if(this.getN2() === 100) return i18next.t('Nitrogen');
        if(this.getO2() === 21 && this.getN2() === 79) return i18next.t('Air');
        const fixedValueO2 = Number.isInteger(this.getO2())?0:2;
        const fixedValueHe = Number.isInteger(this.getHe())?0:2;
        if(this.getHe() === 0) return  i18next.t('EAN') + ' ' + this.getO2().toFixed(fixedValueO2);
        const mixString = this.getO2().toFixed(fixedValueO2) + '/' + this.getHe().toFixed(fixedValueHe);
        if(this.getN2() === 0) return  i18next.t('Heliox') + ' ' + mixString;
        return i18next.t('TX') + ' ' + mixString;
    }
    get n2() {
        return HUNDERT_PERCENT - this.he - this.o2;
    }
    setName(newValue) {
        let oldValue = this.name;
        this.name = newValue;
        this.propertyChangeSupport.firePropertyChange(WetNotesSettings.PROPERTY_NAME, oldValue, newValue);
        return this;
    }
    getName() {
        return this.name;
		}
	/** sets fraction of O2
	 * @param fiO2 the fiO2 to set
	 */
	setFiO2(fiO2){
		this.setO2(fiO2 * 100.0);
	}

	/** sets fraction of He
	 * @param fiHe the fiHe to set
	 */
	setFiHe(fiHe){
		this.setHe(fiHe * 100.0);
	}
    setHe(hePercent) {
        let oldValue = this.he;
        this.he = Number.parseFloat(hePercent);
        this.propertyChangeSupport.firePropertyChange(GasModel.PROPERTY_HE, oldValue, this.he);
        if (this.o2 + this.he > HUNDERT_PERCENT) this.setO2(HUNDERT_PERCENT - hePercent);
        return this;
    }
    getPressure_Bar() {
        return this.pressureBar;
    }
    setO2(o2Percent) {
        let oldValue = this.o2;
        this.o2 = Number.parseFloat(o2Percent);
        this.propertyChangeSupport.firePropertyChange(GasModel.PROPERTY_O2, oldValue, this.o2);
        if (this.o2 + this.he > HUNDERT_PERCENT) this.setHe(HUNDERT_PERCENT - o2Percent);
        return this;
    }
    getO2() {
        return this.o2;
    }
    getHe() {
        return this.he;
    }
    getN2() {
            return 100.0 - this.getO2() - this.getHe();
        }
        /**
         * provides ZFactor for the GAS (T = 15C +-) we ignore T of Settings !
         * @return zFactor for GAS with pressure (getPressure())
         */
    getZ(ad_Pressure_bars = this.getPressure_Bar()) {
        let z = 1;
        if (WetNotes.SETTINGS.isRealGasModel()) {
            z = this.getZ4RealGas(ad_Pressure_bars);
        }
        return z;
    }

    /**
     * calculates ZFactor(Real Gas Model) for Gas
     * @return
     */
    getZ4RealGas(ad_Pressure_bars) {
        let zFactor = this.getZ4RealGasO2(ad_Pressure_bars) * this.getFiO2() +
            this.getZ4RealGasN2(ad_Pressure_bars) * this.getFiN2() +
            this.getZ4RealGasHe(ad_Pressure_bars) * this.getFiHe();
        return zFactor;
    }

    /**
     * for Helium is simple (valid for temp. 0- 30C)
     * @returns zFactor of He
     */
    getZ4RealGasHe(ad_Pressure_bars) {
            let z = 0.0005 * ad_Pressure_bars + 1;
            return z;
        }
        /**
         * calculates z factor for Nitrogen
         * @returns z- factor of N2
         */
    getZ4RealGasN2(ad_Pressure_bars) {
            let z = 1 + 0.2 * ad_Pressure_bars / 375;
            return z;
        }
        /**
         * z factor of Oxygen
         * @param ad_Pressure_bars pressure in Bars
         * @returns z factor of O2
         */
    getZ4RealGasO2(ad_Pressure_bars) {
        let z;
        let pBars = ad_Pressure_bars;
        if (pBars < 200) {
            z = 1.0 - 0.1 * pBars / 200.0;
        } else {
            z = 0.75 + 0.3 * pBars / 400.0;
        }
        return z;
    }

    toString() {
            return  i18next.t(this.getName()) + ": " + this.getMixString() + " @ " + this.getPressure_Bar() + " " + i18next.t('bar');
        }
        /**
         *
         * @return text of gas mix from model like "21/35/44"
         */
    getMixString() {
            let str = WetNotes.formatGasComponent(this.getO2()) + " / " +
                WetNotes.formatGasComponent(this.getHe()) + " / " +
                WetNotes.formatGasComponent(this.getN2());
            return str;
        }
        /**
         * fraction of Oxygen
         * @return the fraction of O2
         */
    getFiO2() {
            return this.o2 / HUNDERT_PERCENT;
        }
        /**
         * fraction of Helium
         * @return the fraction of he
         */
    getFiHe() {
            return this.he / HUNDERT_PERCENT;
        }
        /**
         * fraction of Nitrogen
         * @return fraction of N2
         */
    getFiN2() {
            return this.n2 / HUNDERT_PERCENT;
        }
        /**
         * @return Pressure in Pa
         */
    getPressure_Pa() {
            return PhysicsCore.Pa2Bar * this.getPressure_Bar();
        }
        /**
         * sets Pressure in Pa
         */
    setPressure_Pa(pressure_Pa) {
        this.setPressure_Bar(pressure_Pa / PhysicsCore.Pa2Bar);
        return this;
    }
    setPressure_Bar(pressure_Bar) {
        let oldValue = this.pressureBar;
        this.pressureBar = pressure_Bar;
        this.propertyChangeSupport.firePropertyChange(GasModel.PROPERTY_PRESSURE, oldValue, this.pressureBar);
        return this;
    }
    applyGasModel(gasModel) {
        this.applyGasFraction(gasModel);
        this.setPressure_Bar(gasModel.getPressure_Bar());
        return this;
    }
    applyGasFraction(gasModel) {
            this.setO2(gasModel.getO2());
            this.setHe(gasModel.getHe());
            return this;
        }
        /**
         * provides Molare Masse in KG
         * @return Molare Masse in KG
         */
    getMolareMass_kg_per_mol() {
        return this.getFiO2() * Element.O2.getMolareMasse() +
            this.getFiN2() * Element.N2.getMolareMasse() +
            this.getFiHe() * Element.HE.getMolareMasse();
    }
    eval_a() {
        if (WetNotes.SETTINGS.isRealGasModel()) {
            let tank_gas = [
                new PartialGas(this.getFiO2(), GasCoefficient.OXYGEN),
                new PartialGas(this.getFiHe(), GasCoefficient.HELIUM),
                new PartialGas(this.getFiN2(), GasCoefficient.NITROGEN)
            ];
            return RealGas.eval_a(tank_gas);
        } else {
            return 0.0;
        }
    }

    eval_b() {
            if (WetNotes.SETTINGS.isRealGasModel()) {
                let tank_gas = [
                    new PartialGas(this.getFiO2(), GasCoefficient.OXYGEN),
                    new PartialGas(this.getFiHe(), GasCoefficient.HELIUM),
                    new PartialGas(this.getFiN2(), GasCoefficient.NITROGEN)
                ];
                return RealGas.eval_b(tank_gas);
            } else {
                return 0.0;
            }
        }
        /**
         * gets Bar Volume of Gas
         *
         * @return double Mole Volumen in dm**3 (Liter**3)
         */
    getVmBarReferenced_dm3() {
            return RealGas.V_m3(PhysicsCore.Pa2Bar, 1.0,
                WetNotes.SETTINGS.getTemperatureK(), this.eval_a(),
                this.eval_b()) * 1000.0;

        }
        /**
         * gets Ata Volume of Gas
         * @return double Mole Volumen in dm**3 (Liter**3)
         */
    getVmAtaReferenced_dm3() {
        return RealGas.V_m3(PhysicsCore.PaOfATA, 1.0,
            WetNotes.SETTINGS.getTemperatureK(), this.eval_a(),
            this.eval_b()) * 1000.0;

    }
}
export  class BlenderGasModel extends GasModel {

	static get PROPERTY_GASAMOUNT() { return "blenderGasModelGasAmount"; }
    static get PROPERTY_USED_BY_BLENDING() { return "blenderGasModelUsedByBlending"; }
    
    constructor(name = "noname", o2Percent = 21, hePercent = 0, pressureBar = 200) {
        super(o2Percent, hePercent, pressureBar);
        this.name = name;
        this.gasAmount = 0;
        this.usedByBlending = false;
    }
    getStateJSON(){
        const jsonState = super.getStateJSON();
        jsonState.usedByBlending = this.usedByBlending;
        jsonState.gasAmount = this.getGasAmount_mol();
        return jsonState;
    }
    setStateJSON(jsonState = {}) {
        super.setStateJSON(jsonState);
        if(jsonState.usedByBlending !== undefined) this.usedByBlending = jsonState.usedByBlending;
        if(jsonState.gasAmount !== undefined) this.setGasAmount_mol(jsonState.gasAmount);
        return this;
    }
	clone(){
        const cln = new BlenderGasModel(this.name, this.getO2(), this.getHe(), this.getPressure_Bar());
        cln.gasAmount = this.gasAmount;
        return cln;
    }
	getGasAmount_mol() {
		return this.gasAmount;
	}
	/**
	 * @param gasAmount_mol the gasAmount to set
	 */
	setGasAmount_mol(gasAmount_mol) {
		const oldValue=this.gasAmount;
		this.gasAmount = gasAmount_mol;
		this.propertyChangeSupport.firePropertyChange(BlenderGasModel.PROPERTY_GASAMOUNT, oldValue, this.gasAmount);
	}
	
	getBarLiter(){
		return this.gasAmount * this.getVmBarReferenced_dm3();// VDW Umstellung // * WetNotes.SETTINGS.getTemperatureK() / SettingsModel.kelvin2Celsius;
	}
	getAtaLiter(){
		return this.gasAmount * this.getVmAtaReferenced_dm3();// VDW Umstellung // * WetNotes.SETTINGS.getTemperatureK() / SettingsModel.kelvin2Celsius;
	}
	isUsedByBlending() {
		return this.usedByBlending;
	}
	setUsedByBlending(usedByBlending) {
		const oldValue=this.usedByBlending;
		this.usedByBlending = usedByBlending;
		this.propertyChangeSupport.firePropertyChange(BlenderGasModel.PROPERTY_USED_BY_BLENDING, oldValue, this.usedByBlending);
	}
}

export class TankModel extends GasModel {
    static get PROPERTY_VOLUME() { return "volume"; }
    static get PROPERTY_AMOUNT() { return "prop_amount"; }
    static get PROPERTY_MASSE() { return "prop_masse"; }
    static get zRelevantOwnProperties() {
        return [GasModel.PROPERTY_HE, GasModel.PROPERTY_O2, GasModel.PROPERTY_PRESSURE, TankModel.PROPERTY_VOLUME];
    }
    static get zRelevantSettingsProperties() {
        return [WetNotesSettings.PROPERTY_TEMPERATURE, WetNotesSettings.PROPERTY_REAL_GAS_MODEL];
    }
    static get PROPERTY_NOMINAL_WORKING_PRESSURE() { return "prop_nominal_working_pressure"; }
    static get STANDARD_NOMINAL_WORKING_PRESSURE_3000PSI_BARS() { return 3000.0 / PhysicsCore.PSI_IN_ONE_BAR };
    static get MIN_ALLOWED_WORKING_PRESSURE_BARS() { return 2670.0 / PhysicsCore.PSI_IN_ONE_BAR };
    static get MAX_ALLOWED_WORKING_PRESSURE_BARS() { return 4350.0 / PhysicsCore.PSI_IN_ONE_BAR };


    constructor(name = "noname", o2 = 21, he = 0, pressureBar = 200, volume_dm3 = 24) {
        super(o2, he, pressureBar);
        this.name = name;
        this.volume_dm3 = volume_dm3;
        this.amount_mol = 0;
        this.mass_kg = 0;
        this.nominal_working_pressure_bar = TankModel.STANDARD_NOMINAL_WORKING_PRESSURE_3000PSI_BARS;
        this.installListeners(true);
        this.resetAmount();
        this.resetMass();
    }

    getStateJSON(){
        const jsonState = super.getStateJSON();
        jsonState.volume_dm3 = this.getVolume_dm3();
        jsonState.amount_mol = this.getAmount_mol();
        jsonState.mass_kg = this.getMasse();
        jsonState.nominal_working_pressure_bar = this.getNominalWorkingPressureBar();
        return jsonState;
    }
    
    setStateJSON(jsonState = {}) {
        super.setStateJSON(jsonState);
        if(jsonState.mass_kg !== undefined) this.setMasse(jsonState.mass_kg);
        if(jsonState.amount_mol !== undefined) this.setAmount_mol(jsonState.amount_mol);
        if(jsonState.volume_dm3 !== undefined) this.setVolume_dm3(jsonState.volume_dm3);
        if(jsonState.nominal_working_pressure_bar !== undefined) this.setNominalWorkingPressureBar(jsonState.nominal_working_pressure_bar);
        return this;
    }
    static addGasAmount(tankModel, addingGasModel, addingGasAmount_mol){
        const tankModelResult = tankModel.clone();
        return tankModelResult.addGas(addingGasModel, addingGasAmount_mol);
    }
    addGas(addingGasModel, addingGasAmount_mol) {
            let tankAmountHe = this.getAmount_mol() * this.getFiHe();
            let tankAmountO2 = this.getAmount_mol() * this.getFiO2();
            let addingGasAmountHe = addingGasAmount_mol * addingGasModel.getFiHe();
            let addingGasAmountO2 = addingGasAmount_mol * addingGasModel.getFiO2();

            let cummulatedAmount_mol = this.getAmount_mol() + addingGasAmount_mol;

            // calculate new fractions
            let newFiO2 = 0,
                newFiHe = 0;
            if (cummulatedAmount_mol > 0) {
                newFiO2 = (addingGasAmountO2 + tankAmountO2) / cummulatedAmount_mol;
                newFiHe = (addingGasAmountHe + tankAmountHe) / cummulatedAmount_mol;
            }
            this.setFiO2(newFiO2);
            this.setFiHe(newFiHe);
            // calculate new P
            // double newP=computeP4Amount(tank, cummulatedAmount_mol);
            // Umstellung auf VDW
            let newP_Pa = TankModel.computePressure_Pa(this, cummulatedAmount_mol);
            this.setPressure_Pa(newP_Pa);
            return this;
        }
        /**
         * computes P (Pa) for tankModel and target gasAmount
         *
         * @param arg_tank - tank with set fractions of O2 and He
         * @param gasAmountInTank - amount of gas in tank in (mole)
         * @returns Pressure in Pa in tank with gasAmountInTank of gas
         */
    static computePressure_Pa(arg_tank, gasAmountInTank, temperatureK = TankModel.getT_K()) {
        let p = 0;
        if (gasAmountInTank <= 0) return p;

        p = RealGas.p_Pa(arg_tank.getVolume_dm3() / 1000.0, gasAmountInTank,
            temperatureK, arg_tank.eval_a(), arg_tank.eval_b());

        return p;
    }
   
    applyTankModel(tankModel) {
        this.applyGasModel(tankModel);
        this.setVolume_dm3(tankModel.getVolume_dm3());
        this.setNominalWorkingPressureBar(tankModel.getNominalWorkingPressureBar());
        return this;
    }
    dropGas(amountOfDroppingGas) {
        let amountInTank = this.getAmount_mol();
        amountInTank = amountInTank - amountOfDroppingGas;
        if (amountInTank < 0) amountInTank = 0;
        let newP_Pa = TankModel.computePressure_Pa(this, amountInTank);
        this.setPressure_Pa(newP_Pa);
        return this;
    }
    clone() {
        let cloned = new TankModel(this.getName(), this.o2, this.he, this.pressureBar, this.volume_dm3);
        cloned.setNominalWorkingPressureBar(this.nominal_working_pressure_bar);
        return cloned;
    }
    propertyChange(propertyChangeEvent) {
        //console.debug("property changed: " + propertyChangeEvent + "source: " + propertyChangeEvent.propertyName);
        if (this.isRelevant4AmountOrMasse(propertyChangeEvent)) {
            this.resetAmount().resetMass();
            //console.debug("recalculating amount of gas");
        }
    }
    isRelevant4AmountOrMasse(evt) {
        //console.log("source: " + evt.source);
        return (evt.source === this && TankModel.zRelevantOwnProperties.includes(evt.propertyName)) ||
            (evt.source === WetNotes.SETTINGS && TankModel.zRelevantSettingsProperties.includes(evt.propertyName));
    }
    installListeners(install) {
            if (install) {
                // check the changes of pressure, volume, fractions of elements to hold amount of gas actual
                this.addPropertyChangeListener(this);
                // add listener to settings to follow temperature and ideal/real gas model
                WetNotes.SETTINGS.addPropertyChangeListener(this);
            } else {
                // check the changes of pressure, volume, fractions of elements to hold amount of gas actual
                this.removePropertyChangeListener(this);
                // add listener to settings to follow temperature and ideal/real gas model
                WetNotes.SETTINGS.removePropertyChangeListener(this);
            }
        }
        /**
         * gets BarLiter in the tank in liter by 1 Bar
         * @returns BarLiter in the tank in liter by 1 Bar
         */
    getBarLiter() {
            return this.getAmount_mol() * this.getVmBarReferenced_dm3();
        }
        /**
         * gets AtaLiter in the tank in liter by 1 Bar
         * @returns AtaLiter in the tank in liter by 1 Bar
         */
    getAtaLiter() {
        return this.getAmount_mol() * this.getVmAtaReferenced_dm3();
    }

    getNominalWorkingPressureBar() { return this.nominal_working_pressure_bar }
    setNominalWorkingPressureBar(newValue) {
        let oldValue = this.nominal_working_pressure_bar;
        this.nominal_working_pressure_bar = newValue;
        this.propertyChangeSupport.firePropertyChange(TankModel.PROPERTY_NOMINAL_WORKING_PRESSURE, oldValue, newValue);
        return this;
    }

    /**
     * calculates and sets gas amount in the tank
     */
    resetAmount() {
        // P*V=z*n*R*T ---> z=P*V /(z*R*T)
        let ld_amount = this.calcAmount();
        this.setAmount_mol(ld_amount);
        return this;
    }
    setAmount_mol(amount_mol) {
            let oldValue = this.amount_mol;
            this.amount_mol = amount_mol;
            this.propertyChangeSupport.firePropertyChange(TankModel.PROPERTY_AMOUNT, oldValue, this.amount_mol);
            return this;
        }
        /**  VDW calculation
         */
    calcAmount() {
        return RealGas.n_mol(this.pressureBar * PhysicsCore.Pa2Bar,
            this.volume_dm3 / 1000.0, TankModel.getT_K(),
            this.eval_a(), this.eval_b());
    }
    getMasse() { return this.mass_kg; }
    setMasse(mass_kg) {
            let oldValue = this.mass_kg;
            this.mass_kg = mass_kg;
            this.propertyChangeSupport.firePropertyChange(TankModel.PROPERTY_MASSE, oldValue, this.mass_kg);
            return this;
        }
        /**
         * calculates and sets masse of in the tank
         */
    resetMass() {
            // P*V=z*n*R*T ---> z=P*V /(z*R*T)
            this.setMasse(this.getAmount_mol() * this.getMolareMass_kg_per_mol());
            return this;
        }
        /**
         * Amount of gas in the tank in mol
         * @returns Amount of gas in the tank in mol
         */
    getAmount_mol() {
            return this.amount_mol;
        }
        /**
         * gets Volume of tank in m**3
         * @returns the volume
         */
    getVolume_m3() {
            return this.volume_dm3 / 1000;
        }
        /**
         * Temperature in K
         * The Temperature is set by SettingsModel
         * @returns Temperature in K
         */
    static getT_K() {
            return WetNotes.SETTINGS.getTemperatureK();
        }
        /**
         * gets Volume of tank in dm**3 (liter)
         * @return the volume
         */
    getVolume_dm3() {
        return this.volume_dm3;
    }
    setVolume_dm3(volume_dm3) {
        let oldValue = this.volume_dm3;
        this.volume_dm3 = volume_dm3;
        this.propertyChangeSupport.firePropertyChange(TankModel.PROPERTY_VOLUME, oldValue, this.volume_dm3);
        return this;
    }
    toString() {
        return super.toString() + `, ${WetNotes.formatVolume(this.getVolume_dm3())} ${i18next.t("liter")}, ${this.getBarLiter().toFixed(1)} ${i18next.t("barliter")} ${this.getAmount_mol().toFixed(2)} ${i18next.t("mole")} ${WetNotes.formatMasse(this.getMasse())} ${i18next.t("kg")}.`;
    }
}

export class GasBlender extends WetModel{
  static get PROPERTY_CALCULATED() { return "prop_calculated" };
        
	static get DEFAULT_TOLERANCE_VALUE_N() { return 0.01; }
	static get BLENING_RELEVANT_PROPERTIES() { return [
		GasModel.PROPERTY_O2, GasModel.PROPERTY_HE, GasModel.PROPERTY_PRESSURE, TankModel.PROPERTY_VOLUME, TankModel.PROPERTY_NOMINAL_WORKING_PRESSURE ]; }
	static get BLENING_RELEVANT_SETTINGS() { return [
		WetNotesSettings.PROPERTY_TEMPERATURE, WetNotesSettings.PROPERTY_BLENDING_MODE, WetNotesSettings.PROPERTY_REAL_GAS_MODEL ]; }

  constructor(){
		super();
  	    this.tankOrigin = new TankModel('Scuba tank', 21, 35, 70, 24);
		this.tankTarget = new TankModel('Desired gas mix', 21, 35, 200, 24);
  	     
        this.tankOriginDrop = new TankModel('Droped gas', 21, 35, 70, 24);
        
        this.topoffGas = new BlenderGasModel('Topoff gas', 21, 0, 200);
		this.autoEAN = new BlenderGasModel('Auto EAN(21% - 45%)', 32, 0, 200);
		this.oxygen = new BlenderGasModel('Oxygen', 100, 0, 200);
		this.helium = new BlenderGasModel('Helium', 0, 100, 200);
		this.dropGas = new BlenderGasModel('Drop gas model', 21, 35, 70);

		this.deltaTolerance = GasBlender.DEFAULT_TOLERANCE_VALUE_N;
		this.bleningFound=false;
		this.installListeners();
		this.blend();
    }
    getStateJSON(){
        const jsonState = {};
        jsonState.tankOrigin = this.tankOrigin.getStateJSON();
        jsonState.tankTarget = this.tankTarget.getStateJSON();
        jsonState.topoffGas = this.topoffGas.getStateJSON();
        return jsonState;
    }
    setStateJSON(jsonState = {}){
        this.uninstallListeners();
        if(jsonState.tankOrigin !== undefined) this.tankOrigin.setStateJSON(jsonState.tankOrigin);
        if(jsonState.tankTarget !== undefined) this.tankTarget.setStateJSON(jsonState.tankTarget);
        if(jsonState.topoffGas !== undefined) this.topoffGas.setStateJSON(jsonState.topoffGas);
        this.installListeners();
        this.blend();
        return this;
    }
	installListeners(){
		this.tankOrigin.addPropertyChangeListener(this);
		this.tankTarget.addPropertyChangeListener(this);
		this.topoffGas.addPropertyChangeListener(this, [GasModel.PROPERTY_HE, GasModel.PROPERTY_O2]);
		WetNotes.SETTINGS.addPropertyChangeListener(this);
	}
	uninstallListeners(){
		this.tankOrigin.removePropertyChangeListener(this);
		this.tankTarget.removePropertyChangeListener(this);
		this.topoffGas.removePropertyChangeListener(this, [GasModel.PROPERTY_HE, GasModel.PROPERTY_O2]);
		WetNotes.SETTINGS.removePropertyChangeListener(this);
	}
	isRelevant4Blending(evt) {
		if( ( evt.source === this.tankOrigin || evt.source === this.tankTarget ) 
				&& GasBlender.BLENING_RELEVANT_PROPERTIES.includes(evt.propertyName)) return true;

		if( evt.source === this.topoffGas 
			&& evt.propertyName !== GasModel.PROPERTY_PRESSURE
			&& WetNotes.SETTINGS.getBlendingMode !== BlendingMode.He_AutoEAN ) return true;

		if( evt.source === WetNotes.SETTINGS && GasBlender.BLENING_RELEVANT_SETTINGS.includes(evt.propertyName)) return true;
		return false;
	}
	propertyChange(propertyChangeEvent) {
		if( this.tankOrigin === propertyChangeEvent.source 
			&& propertyChangeEvent.propertyName === TankModel.PROPERTY_VOLUME ){
			this.tankTarget.setVolume_dm3(this.tankOrigin.getVolume_dm3());
		}
		if( this.tankTarget === propertyChangeEvent.source 
			&& propertyChangeEvent.propertyName === TankModel.PROPERTY_VOLUME ){
			this.tankOrigin.setVolume_dm3(this.tankTarget.getVolume_dm3());
		}
		if(this.isRelevant4Blending(propertyChangeEvent)){
			this.blend();
		}
	}
	isBlendingFound() {
		return this.bleningFound;
	}
	setBlendingFound(blendingFound) {
		//const oldValue=this.bleningFound;
		this.bleningFound = blendingFound;
		//this.propertyChangeSupport.firePropertyChange(GasBlender.PROPERTY_BLENDINGFOUND, oldValue, this.bleningFound);
	}
	blend(){
		//console.log('calculate blending process');
		let originP = this.tankOrigin.getPressure_Bar();
		if(originP > this.tankTarget.getPressure_Bar()){
			originP = this.tankTarget.getPressure_Bar();
		}
		const stepDownRough = 1.0;
		const stepDown=0.01;
		let foundMix = false;
		//let counter = 0;
		let counterRough = 0;
		
		
		while(originP >= 0 && !foundMix){
			foundMix = this.findBlendCombination(originP);
			if(foundMix) break;
			counterRough++;
			if(originP > stepDownRough){ 
					originP=originP - stepDownRough; 
			}else{
				if(originP !== 0){
					originP = 0;
				}else{ 
					break;
				}
			}
		}
		if(counterRough > 0){
			foundMix = false;
			originP = originP + stepDownRough;
			while(originP >= 0 && !foundMix){
		//		counter++;
				foundMix = this.findBlendCombination(originP);
				if(foundMix) break;
				if(originP > stepDown){ 
						originP=originP - stepDown; 
				}else{
					if(originP !== 0){
						originP = 0;
					}else{ 
						break;
					}
				}
			}
		}
		//console.debug("Counter rough/fine: " + counterRough +  " / " + counter);
		//console.log("OriginP: " + originP);

		this.setBlendingFound(foundMix);
		this.fireCalculated();
	}

	findBlendCombination(startPressure){
			if(WetNotes.SETTINGS.getBlendingMode() === BlendingMode.He_AutoEAN){
				return this.findBlendCombinationAutoEAN(startPressure);
			}else{
				return this.findBlendCombinationNormalMode(startPressure);
			}
	}
	resetBelendingTanks(){
		this.tankOriginDrop.applyTankModel(this.tankOrigin);
		this.dropGas.applyGasModel(this.tankOrigin);
		
	}
	findBlendCombinationAutoEAN(startPressure){
		this.resetBelendingTanks();
		this.tankOriginDrop.setPressure_Bar(startPressure);
		this.dropGas.setPressure_Bar(startPressure);
		// init values {
		const amountOrigin = this.tankOriginDrop.getAmount_mol();
		const originFiO2 = this.tankOrigin.getFiO2();
		const targetFiO2 = this.tankTarget.getFiO2();

		const originFiHe = this.tankOrigin.getFiHe();
		const targetFiHe = this.tankTarget.getFiHe();

		const originFiN2= this.tankOrigin.getFiN2();
		const targetFiN2 = this.tankTarget.getFiN2();

		const amountTarget = this.tankTarget.getAmount_mol();
		//init values }
		
		// amounts of Nitrogen in mole
		const targetAmountN2 = amountTarget * targetFiN2;
		const originAmountN2 = amountOrigin * originFiN2;
		// needed amount of N2 in topOff in mole
		const neededAmountOfN2fromTopOff = targetAmountN2 - originAmountN2;
		if (neededAmountOfN2fromTopOff < 0 && Math.abs(neededAmountOfN2fromTopOff) > this.deltaTolerance) return false;

		// Oxygen
		const targetAmountO2 = amountTarget * targetFiO2;
		const originAmountO2 = amountOrigin * originFiO2;
		// needed amount of Oxygen in topOff in mole
		const neededAmountOfO2fromTopOff = targetAmountO2 - originAmountO2;
		if (neededAmountOfO2fromTopOff < 0 && Math.abs(neededAmountOfO2fromTopOff) > this.deltaTolerance) return false;

		const autoEANTotalAmount = neededAmountOfO2fromTopOff + neededAmountOfN2fromTopOff;
		const fiO2AutoEAN = neededAmountOfO2fromTopOff / autoEANTotalAmount;

		if(fiO2AutoEAN < WetNotes.SETTINGS.getAutoTopoffFiO2Min() ||
		   fiO2AutoEAN > WetNotes.SETTINGS.getAutoTopoffFiO2Max()){
			return false; // O2 out of range
		}

		// check He element
		const originAmountHe = amountOrigin * originFiHe;
		const targetAmountHe = amountTarget * targetFiHe;

		const deltaHe = targetAmountHe - originAmountHe;
		if(deltaHe < 0 && Math.abs(deltaHe) > this.deltaTolerance) return false;

		this.autoEAN.setGasAmount_mol(autoEANTotalAmount);
		this.autoEAN.setFiO2(fiO2AutoEAN);
		this.autoEAN.setFiHe(0.0);

		this.oxygen.setGasAmount_mol(0);
		this.helium.setGasAmount_mol(deltaHe);
		this.dropGas.setGasAmount_mol(amountOrigin);
		// console.debug('Amount dropTo: mol/bar Liter ' + this.dropGas.getGasAmount_mol() + ' / ' + this.dropGas.getBarLiter());
		// console.debug("Amount He: mol/bar Liter " + this.helium.getGasAmount_mol() + "/ " + this.helium.getBarLiter());
		// console.debug("Amount O2: mol/bar Liter " + this.oxygen.getGasAmount_mol() + "/ " + this.oxygen.getBarLiter());
		// console.debug("Amount autoEAN: mol/bar Liter "+this.autoEAN.getGasAmount_mol() + "/ " + this.autoEAN.getBarLiter());
		console.debug("\n\n");
		return true;
	}
	findBlendCombinationNormalMode(startPressure){
		this.resetBelendingTanks();
		this.tankOriginDrop.setPressure_Bar(startPressure);
		this.dropGas.setPressure_Bar(startPressure);
		//init variables {
		const amountOrigin=this.tankOriginDrop.getAmount_mol();

		const originFiO2= this.tankOrigin.getFiO2();
		const targetFiO2= this.tankTarget.getFiO2();

		const topoffFiN2= this.topoffGas.getFiN2();
		if(topoffFiN2 < 0){
			console.error("has to be unreacheable. check it .....");
			return false;
		}
		const originFiHe = this.tankOrigin.getFiHe();
		const targetFiHe = this.tankTarget.getFiHe();
		const originFiN2= this.tankOrigin.getFiN2();
		const targetFiN2 = this.tankTarget.getFiN2();

		const topOffFiHe = this.topoffGas.getFiHe();
		const topOffFiO2 = this.topoffGas.getFiO2();
		const amountTarget = this.tankTarget.getAmount_mol();
		//init variables }

		// amounts of N2 in mole
		const targetAmountN2 = amountTarget * targetFiN2;
		const originAmountN2 = amountOrigin * originFiN2;

		// needed amount of N2 in topOff in mole
		const neededAmountOfN2fromTopOff = targetAmountN2 - originAmountN2;

		// amount of topoff gas to reach needed amount of nitrogen in mix
		const amountOfTopOFF = (topoffFiN2 !== 0)?(neededAmountOfN2fromTopOff / topoffFiN2):0;

		if( amountOfTopOFF === 0 && neededAmountOfN2fromTopOff > 0 ) return false;

		if( neededAmountOfN2fromTopOff < 0 ) return false;

		if(Math.abs(neededAmountOfN2fromTopOff + originAmountN2 - targetAmountN2) > this.deltaTolerance) return false;

		// check He element
		const originAmountHe = amountOrigin * originFiHe;
		const targetAmountHe = amountTarget * targetFiHe;
		const amountHeTopOff = amountOfTopOFF * topOffFiHe;

		const deltaHe = targetAmountHe - amountHeTopOff - originAmountHe;
		if(deltaHe < 0 && Math.abs(deltaHe) > this.deltaTolerance) return false;

		// check O2
		const originAmountO2 = amountOrigin * originFiO2;
		const targetAmountO2 = amountTarget * targetFiO2;
		const amountO2TopOff = amountOfTopOFF * topOffFiO2;

		const deltaO2 = targetAmountO2 - amountO2TopOff - originAmountO2;
		if(deltaO2 < 0 && Math.abs(deltaO2) > this.deltaTolerance) return false;

		this.topoffGas.setGasAmount_mol(amountOfTopOFF);
		this.oxygen.setGasAmount_mol(deltaO2);
		this.helium.setGasAmount_mol(deltaHe);
		this.dropGas.setGasAmount_mol(amountOrigin);
		
		// DEBUG
		// console.debug('Amount dropTo: mol/bar Liter ' + this.dropGas.getGasAmount_mol() + ' / ' + this.dropGas.getBarLiter());
		// console.debug("Amount He: mol/bar Liter " + this.helium.getGasAmount_mol() + "/ " + this.helium.getBarLiter());
		// console.debug("Amount O2: mol/bar Liter " + this.oxygen.getGasAmount_mol() + "/ " + this.oxygen.getBarLiter());
		// console.debug("Amount topOFF: mol/bar Liter "+this.topoffGas.getGasAmount_mol() + "/ " + this.topoffGas.getBarLiter());
		// console.debug("\n\n");

		return true;
	}
	fireCalculated(){
		this.propertyChangeSupport.firePropertyChange(GasBlender.PROPERTY_CALCULATED, false, true);
	}

	
}
