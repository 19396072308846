import React, {useState} from 'react'
import { uuidv4 } from './WTNCommons';
import { PhysicsCore } from './wetnotes/PhysicsCore';
import { WetNotes } from './wetnotes/wetnotes';
import i18next from 'i18next';

const unknownFunction = () => {
	console.warn("undefined function")
}
// return 3000.0 / PhysicsCore.PSI_IN_ONE_BAR
const DEFAULT_CYLINDER_PRESSURE_PSI = [ 2400, 2640, 3000, 3442, 3600 ] 

const getDisplayValue = (tankModel) => { 
	return Math.round(tankModel.getNominalWorkingPressureBar() * PhysicsCore.PSI_IN_ONE_BAR)
}

export default function PickUpNominalWorkingPressureBar(props) {
	const { setNominalWorkingPressureBar = unknownFunction, tankModel } = props
	const [ id ] = useState("sotv_" + uuidv4().replace(/-/g, ''))
	if( WetNotes.SETTINGS.isMetricSystem() ) return null
	return(
		<div className="dropdown p-0 d-inline">
			<button className="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id={id} 
				data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<small>@{getDisplayValue(tankModel)} {i18next.t('psi')}</small>
			</button>
			<div className="dropdown-menu" aria-labelledby={id} 
				>
					{DEFAULT_CYLINDER_PRESSURE_PSI.map((default_cylinder_pressure_psi, index) => (
						<button key={index} className="dropdown-item" 
							type="button" 
							onClick={(e) => {
								e.stopPropagation()
								const newValue = 1.0 * default_cylinder_pressure_psi / PhysicsCore.PSI_IN_ONE_BAR
								setNominalWorkingPressureBar(newValue)
							}}
						>{default_cylinder_pressure_psi} {i18next.t('psi')}</button>
					))}
      </div>
    </div>
	)
}