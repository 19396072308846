/* 
 * Results of bleder calculation
 *
 * Oleg Gurevich, 2019
 */
import React from 'react';
import  {WetNotes, WetNotesSettings, BlendingMode, GasBlender}  from '../wetnotes/wetnotes.js'

import { COLOR_WETNOTES, DisplayPressure, DisplayAmount, DisplayMass, TankBarliterInfo, pressureToDisplay, amountVolumeToDisplay, amountToDisplay, massToDisplay } from '../WTNCommons'
import i18next from 'i18next';
import MdSad from 'react-ionicons/lib/MdSad'
import MdArrowRoundForward from 'react-ionicons/lib/MdArrowRoundForward'
import { IsochoreDialogToggler } from '../wetnotes/IsochoreDialogToggler';
import { StartAtTemperaturInfo } from "../StartAtTemperaturInfo";
import { toast } from 'react-toastify';
import MdCopy from 'react-ionicons/lib/MdCopy'


function StepDropGas(props){
  const gasBlender = props.gasBlender;
  const deltaPressure = gasBlender.tankOrigin.getPressure_Bar() - gasBlender.tankOriginDrop.getPressure_Bar();
  const dropedPressure = gasBlender.tankOriginDrop.getPressure_Bar();
    return (
      <div className="h6">
        <span>{i18next.t('Drop')} </span><DisplayPressure pressure={deltaPressure}></DisplayPressure> <MdArrowRoundForward>
          </MdArrowRoundForward> {i18next.t('Until')}: <DisplayPressure pressure={dropedPressure}></DisplayPressure> {i18next.t('in tank.')} &nbsp;
        <IsochoreDialogToggler tankModel = {gasBlender.tankOriginDrop.clone()}></IsochoreDialogToggler>
      </div>
    )
  }
  function StepAddGas(props){
    const addingGas = props.addingGas;
    const tankSenke = props.tankSenke;
    const tankAdded = tankSenke.clone().addGas(addingGas, addingGas.getGasAmount_mol());
    const deltaPressure = tankAdded.getPressure_Bar() - tankSenke.getPressure_Bar();
    return (
      <div>
        <div className="h6">
         {i18next.t('Add')} {i18next.t(addingGas.getGasName())} <DisplayPressure pressure={deltaPressure}></DisplayPressure>&nbsp;
          <MdArrowRoundForward></MdArrowRoundForward> {i18next.t('Up to')}: <DisplayPressure pressure={tankAdded.getPressure_Bar()}></DisplayPressure> {i18next.t('in tank.')} &nbsp;
          <IsochoreDialogToggler tankModel = {tankAdded}></IsochoreDialogToggler>
        </div>
        {WetNotes.SETTINGS.isShowDetails() &&
        <div className="alert alert-info">
            <CheckAddingGas addingGas = {addingGas}></CheckAddingGas>
            <CheckBlendingTank tankModel = {tankAdded}></CheckBlendingTank>
        </div>
        }
          
      </div>
    )
  }


function getJsonTableRow(){
  return {
    action : "",
    deltaPressure : "",
    tankPressure : "",
    tankMix : "",
    tankAmountVolume : "",
    tankAmount : "",
    tankMass : "",
    addAmountVolume : "",
    addAmount : "",
    addMass : ""
  }
}  
function getAddingGasSequence(gasBlender){
  const addingGasSequence = [];
  switch(WetNotes.SETTINGS.getBlendingMode()){
    case BlendingMode.O2_He_TopOff:
      addingGasSequence.push(gasBlender.oxygen);
      addingGasSequence.push(gasBlender.helium);
      addingGasSequence.push(gasBlender.topoffGas);
      break;
    case BlendingMode.He_O2_TopOff:
      addingGasSequence.push(gasBlender.helium);
      addingGasSequence.push(gasBlender.oxygen);
      addingGasSequence.push(gasBlender.topoffGas);
      break;
    case BlendingMode.O2_TopOff_He:
      addingGasSequence.push(gasBlender.oxygen);
      addingGasSequence.push(gasBlender.topoffGas);
      addingGasSequence.push(gasBlender.helium);
      break;
    case BlendingMode.He_AutoEAN:
      addingGasSequence.push(gasBlender.helium);
      addingGasSequence.push(gasBlender.autoEAN);
      break;
    default:
      // still be an empty array
      break;
  }
  return addingGasSequence;
}
/**
 * 
 * @param {*} gasBlender
 * @returns array of json elements with data for table  
 *  
 */
function buildDataModelOfResult(gasBlender){
  const toleranceDelta = 0.08;
  const showDropGas = gasBlender.tankOrigin.getAmount_mol() !== gasBlender.dropGas.getGasAmount_mol();
  const addingGasSequence = getAddingGasSequence(gasBlender);
  
  const dataModelSteps = [];
  let tankSenke = gasBlender.tankOriginDrop.clone();

  if(showDropGas){
    dataModelSteps.push(buildDropRow(gasBlender));
  }
  addingGasSequence.map((addingGas) => { 
    if(addingGas.getGasAmount_mol() > toleranceDelta){
      dataModelSteps.push(buildAddGasRow(addingGas, tankSenke.clone()));
      tankSenke.addGas(addingGas, addingGas.getGasAmount_mol());  
    }
    return tankSenke;
  });

  return dataModelSteps;
}

function buildDropRow(gasBlender){
  const dataRow = getJsonTableRow();
  dataRow.action = i18next.t('Drop');
  
  const deltaPressure = gasBlender.tankOriginDrop.getPressure_Bar() - gasBlender.tankOrigin.getPressure_Bar();
  dataRow.deltaPressure = pressureToDisplay(deltaPressure);
  dataRow.tankModel = gasBlender.tankOriginDrop.clone();
  const dropedPressure = gasBlender.tankOriginDrop.getPressure_Bar();
  dataRow.tankPressure = pressureToDisplay(dropedPressure);
  dataRow.tankMix = gasBlender.tankOriginDrop.getMixString();
  dataRow.tankAmountVolume = amountVolumeToDisplay(gasBlender.tankOriginDrop);
  dataRow.tankAmount = amountToDisplay(gasBlender.tankOriginDrop.getAmount_mol());
  dataRow.tankMass = massToDisplay(gasBlender.tankOriginDrop.getMasse());

  return dataRow;
}

function buildAddGasRow(addingGas, tankSenke){
  const dataRow = getJsonTableRow();
 
  dataRow.action = i18next.t('Add') + " " + addingGas.getGasName();
  const tankAdded = tankSenke.clone().addGas(addingGas, addingGas.getGasAmount_mol());
  dataRow.tankModel = tankAdded.clone();

  const deltaPressure = tankAdded.getPressure_Bar() - tankSenke.getPressure_Bar();
  dataRow.deltaPressure = pressureToDisplay(deltaPressure);
  
  dataRow.tankPressure = pressureToDisplay(tankAdded.getPressure_Bar());
  dataRow.tankMix = tankAdded.getMixString();
  dataRow.tankAmountVolume = amountVolumeToDisplay(tankAdded);
  dataRow.tankAmount = amountToDisplay(tankAdded.getAmount_mol());
  dataRow.tankMass = massToDisplay(tankAdded.getMasse());

  dataRow.addAmountVolume = amountVolumeToDisplay(addingGas);
  dataRow.addAmount = amountToDisplay(addingGas.getGasAmount_mol());
  dataRow.addMass = massToDisplay(tankAdded.getMasse() - tankSenke.getMasse());

  return dataRow;
}
  
function BlendingSteps(props){
    const toleranceDelta = 0.08;
    const gasBlender = props.gasBlender;
    const showDropGas = gasBlender.tankOrigin.getAmount_mol() !== gasBlender.dropGas.getGasAmount_mol();
    
    let tankSenke = gasBlender.tankOriginDrop.clone();
    let steps = [];

    const addingGasSequence = getAddingGasSequence(gasBlender);
    addingGasSequence.map((addingGas) => { 
      if(addingGas.getGasAmount_mol() > toleranceDelta){
        steps.push(<StepAddGas addingGas = {addingGas} tankSenke = {tankSenke.clone()}></StepAddGas>);
        tankSenke.addGas(addingGas, addingGas.getGasAmount_mol());  
      }
      return tankSenke;
    });
    
    return (
      <ul>
        {showDropGas &&
          <li key="dropGas">
            <StepDropGas gasBlender={gasBlender}></StepDropGas>
            {WetNotes.SETTINGS.isShowDetails() &&
            <div className="alert alert-info">
              <CheckBlendingTank tankModel = {gasBlender.tankOriginDrop}></CheckBlendingTank>
            </div>
            }
          </li>
        }
        {
          steps.map((item, index) => (<li key={'addGas'+index}>{item}</li>))
        }
      </ul>
    )
  }
  class BlenderPriceCalculation extends React.Component{
    constructor(props){
      super(props);
      this.gasBlender = props.gasBlender;
      this.relevantSettingsEvents = [WetNotesSettings.PROPERTY_BLENDING_MODE, WetNotesSettings.PROPERTY_UNITS,
        WetNotesSettings.PROPERTY_CURRENCY_LABEL,
        WetNotesSettings.PROPERTY_PRICE_HE, WetNotesSettings.PROPERTY_PRICE_O2, WetNotesSettings.PROPERTY_PRICE_TopOff, WetNotesSettings.PROPERTY_TopOff_flat_price ];
    }
    componentDidMount() {
      WetNotes.SETTINGS.addPropertyChangeListener(this, this.relevantSettingsEvents);
      this.gasBlender.addPropertyChangeListener(this, [GasBlender.PROPERTY_CALCULATED]);
    }
    componentWillUnmount() {
      WetNotes.SETTINGS.removePropertyChangeListener(this, this.relevantSettingsEvents);
      this.gasBlender.removePropertyChangeListener(this, [GasBlender.PROPERTY_CALCULATED]);
    }
    propertyChange(propertyChangeEvent){
      this.setState({
        lastChangeEvent : propertyChangeEvent
      }); 
    }
    render(){
      const gasBlender = this.gasBlender;
      let showPreisCalculation = WetNotes.SETTINGS.getPriceHe() > 0 || WetNotes.SETTINGS.getPriceO2() > 0 || WetNotes.SETTINGS.getPriceTopoff() > 0;
      if(!showPreisCalculation || !gasBlender.isBlendingFound()) return null;
  
      let priceTotal = 0;
      const priceInfos = [];
      const priceO2 = WetNotes.SETTINGS.getPriceO2();
      const priceHe = WetNotes.SETTINGS.getPriceHe();
      const priceTopoff = WetNotes.SETTINGS.getPriceTopoff();
      const topoff = (WetNotes.SETTINGS.getBlendingMode === BlendingMode.He_AutoEAN)?gasBlender.autoEAN:gasBlender.topoffGas;
  
      if(priceO2 > 0 && gasBlender.oxygen.getGasAmount_mol() > 0){
        const costO2 = priceO2 * gasBlender.oxygen.getBarLiter();
        priceTotal+=costO2;
        priceInfos.push({ 
          position: i18next.t(gasBlender.oxygen.getName()),
          amount: WetNotes.barliterValueToView(gasBlender.oxygen).toFixed(2),
          amountUnits: WetNotes.barliterLabelToView(),
          price : WetNotes.priceBarliterToView(priceO2).toFixed(4),
          costs: costO2.toFixed(4)
        })
      }
      if(priceHe > 0 && gasBlender.helium.getGasAmount_mol() > 0){
        const costHe = priceHe * gasBlender.helium.getBarLiter();
        priceTotal+=costHe;
        priceInfos.push({ 
          position: i18next.t(gasBlender.helium.getName()),
          amount: WetNotes.barliterValueToView(gasBlender.helium).toFixed(2),
          amountUnits: WetNotes.barliterLabelToView(),
          price : WetNotes.priceBarliterToView(priceHe).toFixed(4),
          costs: costHe.toFixed(4)
        })
      }
      if(priceTopoff > 0 && topoff.getGasAmount_mol() > 0){
        if(WetNotes.SETTINGS.isPriceFlatModeTopoff()){
          priceTotal+=priceTopoff;
          priceInfos.push({ 
            position: i18next.t(topoff.getName()),
            amount: 1,
            amountUnits: i18next.t('fixed price'),
            price : priceTopoff.toFixed(2),
            costs: priceTopoff.toFixed(2)
          })
        }else{
          const costTopoff = priceTopoff * topoff.getBarLiter();
          priceTotal+=costTopoff;
          priceInfos.push({ 
            position: i18next.t(topoff.getName()),
            amount: WetNotes.barliterValueToView(topoff).toFixed(2),
            amountUnits: WetNotes.barliterLabelToView(),
            price : WetNotes.priceBarliterToView(priceTopoff).toFixed(4),
            costs: costTopoff.toFixed(4)
          })
        }
      }
      
      return (
        <div>
            <h5>{i18next.t('Costs')}</h5>
            { 
              priceInfos.map((item, index) => (<div key={index} className="row"><div className="col-3">{item.position}: </div><div className="col-9"> {item.price} * {item.amount} {item.amountUnits} = {item.costs} {WetNotes.SETTINGS.getCurrencyLabel()}</div></div>))
            }
            <div className="h5">{i18next.t('Total')}: {priceTotal.toFixed(2)} {WetNotes.SETTINGS.getCurrencyLabel()}</div>
        </div>
      )
  }
}
const copyText = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.info(i18next.t('copied.to.clipboard'), {
      position: "top-right",
      autoClose: 3600,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  } catch (err) {
    console.error('Fehler beim Kopieren:', err);
  }
}

function BlendingNotFound(props){
    return (
      <div className = "card bg-warning">
        <div className = "card-body">
          <h5 className = "card-title">{i18next.t('Blending impossible')} <MdSad color={ COLOR_WETNOTES} fontSize="2em"></MdSad></h5>
          <div className = "card-text">{i18next.t('mix.impossible')}</div>
        </div>
      </div>
    )
}
  
export class BlenderResults extends React.Component{
    constructor(props){
      super(props);
      this.gasBlender = props.gasBlender;
      this.relevantSettingsEvents = [WetNotesSettings.PROPERTY_BLENDING_MODE, WetNotesSettings.PROPERTY_SHOW_DETAILS, 
        WetNotesSettings.PROPERTY_UNITS];

    }
    toString(){
      return `Metric scuba tank info:
      ${this.gasBlender.tankOrigin.toString()}
      ${this.gasBlender.tankTarget.toString()}

${startAtTemperaturInfoAsText()}

${blendingStepsAsText(this.gasBlender)}

${getCostsAsString(this.gasBlender)}`;
    }
    render(){
      const dataModelOfResults = 
          this.gasBlender.isBlendingFound()?buildDataModelOfResult(this.gasBlender):null;
      return (
          <div className={"bg-white p-3 rounded-lg border mb-4"}>
          <h5>{i18next.t('Blender results')}</h5>
          <h6><StartAtTemperaturInfo/></h6>
          <div className="row d-lg-block d-none">
            <div className = "col">
              { !this.gasBlender.isBlendingFound() &&
                <BlendingNotFound></BlendingNotFound>
              }
              { this.gasBlender.isBlendingFound() &&
                <TableResults dataModel = {dataModelOfResults}></TableResults>
              }
            </div>
          </div>
          <div className="row">
              <div className="col">
                <div className="d-lg-none">
                { !this.gasBlender.isBlendingFound() &&
                  <BlendingNotFound></BlendingNotFound>
                }
                { this.gasBlender.isBlendingFound() &&
                  <BlendingSteps gasBlender = {this.gasBlender}></BlendingSteps>
                }
                </div>
              </div>
          </div>
          <div className="row">
              <div className="col">
                <BlenderPriceCalculation gasBlender = {this.gasBlender}></BlenderPriceCalculation>
              </div>
          </div>
          { this.gasBlender.isBlendingFound() &&
              <button type="button" className="btn btn-light" onClick = { (e) => copyText(this.toString()) }>
              <MdCopy color={ COLOR_WETNOTES} fontSize="1.5em"></MdCopy>
              {i18next.t("copy.record.to.clipboard")}
              </button>
          }
          </div>
      )
    }
    componentDidMount() {
      WetNotes.SETTINGS.addPropertyChangeListener(this, this.relevantSettingsEvents);
      this.gasBlender.addPropertyChangeListener(this, [GasBlender.PROPERTY_CALCULATED]);
    }
    componentWillUnmount() {
      WetNotes.SETTINGS.removePropertyChangeListener(this, this.relevantSettingsEvents);
      this.gasBlender.removePropertyChangeListener(this, [GasBlender.PROPERTY_CALCULATED]);
    }
    propertyChange(propertyChangeEvent){
      this.setState({
        lastChangeEvent : propertyChangeEvent
      }); 
    }
  }
  function ResultTableRow(props){
    const showDetails = WetNotes.SETTINGS.isShowDetails();
    const dataModelTableRow = props.dataModelTableRow;
    const noWrapStyle = { "whiteSpace" : "nowrap"};
    return(
      <tr>
        <th scope="row">{props.stepNumber}</th>
        <th scope="row">{dataModelTableRow.action}</th>
        <td>{dataModelTableRow.deltaPressure}</td>
        <th scope="row" className="border-left">
        {dataModelTableRow.tankPressure} 
        </th>
        <th>
        { dataModelTableRow.tankModel !== undefined &&
          <IsochoreDialogToggler tankModel = {dataModelTableRow.tankModel}></IsochoreDialogToggler>
        }
        </th>
        <th scope="row" style={noWrapStyle}>{dataModelTableRow.tankMix}</th>
        { showDetails &&
         <td>{dataModelTableRow.tankAmountVolume}</td>
        }
        { showDetails &&
         <td>{dataModelTableRow.tankAmount}</td>
        }
        { showDetails &&
         <td>{dataModelTableRow.tankMass}</td>
        }
        <td className="border-left">{dataModelTableRow.addAmountVolume}</td>
        { showDetails &&
         <td>{dataModelTableRow.addAmount}</td>
        }
        { showDetails &&
         <td>{dataModelTableRow.addMass}</td>
        }
      </tr>
    )
  }
  function ResultTableHead(props){
    const showDetails = WetNotes.SETTINGS.isShowDetails();
    const bar =  " (" + WetNotes.MEASSURES.Pressure.getLabel() + ")";
    const kg =  " (" + WetNotes.MEASSURES.Masse.getLabel() + ")";
    const barliter = " (" + WetNotes.barliterLabelToView() + ")";
    return (
      <thead>
        <tr>
          <th scope="col" className="align-top">#</th>
          <th scope="col" className="align-top">{i18next.t('Action')}</th>
          <th scope="col" className="align-top">{i18next.t('Add') + bar}</th>
          <th scope="col" className="align-top border-left">{i18next.t('Tank pressure') + bar}</th>
          <th scope="col" className="align-top">{i18next.t('warming')}</th>
          <th scope="col" className="align-top">{i18next.t('Check mix')}</th>
          { showDetails &&
            <th scope="col" className="align-top">{i18next.t('Amount') + barliter}</th>
          }
          { showDetails &&
            <th scope="col" className="align-top">{i18next.t('Amount (mol)')}</th>
          }
          { showDetails &&
            <th scope="col" className="align-top">{i18next.t('Mass') + kg}</th>
          }
          <th scope="col" className="align-top border-left">{i18next.t('Added amount') + barliter}</th>
          { showDetails &&
            <th scope="col" className="align-top">{i18next.t('Added amount (mol)')}</th>
          }
          { showDetails &&
            <th className="align-top">{i18next.t('Added Mass') + kg}</th>
          }
        </tr>
      </thead>

    )
  }
  export function TableResults(props){
    const dataModel = props.dataModel;
    return (
      <div>
        <table className="table table-striped table-sm table-responsive-xl">
          <ResultTableHead></ResultTableHead>
          <tbody>
            {
              dataModel.map((item, index) => (<ResultTableRow dataModelTableRow={item} key={index} stepNumber = { index + 1 }></ResultTableRow>))
            }
          </tbody>
          
        </table>
      </div>
    )
  }

  const getCostsAsString = (gasBlender) => {
    let showPreisCalculation = WetNotes.SETTINGS.getPriceHe() > 0 || WetNotes.SETTINGS.getPriceO2() > 0 || WetNotes.SETTINGS.getPriceTopoff() > 0;
    if(!showPreisCalculation || !gasBlender.isBlendingFound()) return null;

    let priceTotal = 0;
    const priceInfos = [];
    const priceO2 = WetNotes.SETTINGS.getPriceO2();
    const priceHe = WetNotes.SETTINGS.getPriceHe();
    const priceTopoff = WetNotes.SETTINGS.getPriceTopoff();
    const topoff = (WetNotes.SETTINGS.getBlendingMode === BlendingMode.He_AutoEAN)?gasBlender.autoEAN:gasBlender.topoffGas;

    if(priceO2 > 0 && gasBlender.oxygen.getGasAmount_mol() > 0){
      const costO2 = priceO2 * gasBlender.oxygen.getBarLiter();
      priceTotal+=costO2;
      priceInfos.push({ 
        position: i18next.t(gasBlender.oxygen.getName()),
        amount: WetNotes.barliterValueToView(gasBlender.oxygen).toFixed(2),
        amountUnits: WetNotes.barliterLabelToView(),
        price : WetNotes.priceBarliterToView(priceO2).toFixed(4),
        costs: costO2.toFixed(4)
      })
    }
    if(priceHe > 0 && gasBlender.helium.getGasAmount_mol() > 0){
      const costHe = priceHe * gasBlender.helium.getBarLiter();
      priceTotal+=costHe;
      priceInfos.push({ 
        position: i18next.t(gasBlender.helium.getName()),
        amount: WetNotes.barliterValueToView(gasBlender.helium).toFixed(2),
        amountUnits: WetNotes.barliterLabelToView(),
        price : WetNotes.priceBarliterToView(priceHe).toFixed(4),
        costs: costHe.toFixed(4)
      })
    }
    if(priceTopoff > 0 && topoff.getGasAmount_mol() > 0){
      if(WetNotes.SETTINGS.isPriceFlatModeTopoff()){
        priceTotal+=priceTopoff;
        priceInfos.push({ 
          position: i18next.t(topoff.getName()),
          amount: 1,
          amountUnits: i18next.t('fixed price'),
          price : priceTopoff.toFixed(2),
          costs: priceTopoff.toFixed(2)
        })
      }else{
        const costTopoff = priceTopoff * topoff.getBarLiter();
        priceTotal+=costTopoff;
        priceInfos.push({ 
          position: i18next.t(topoff.getName()),
          amount: WetNotes.barliterValueToView(topoff).toFixed(2),
          amountUnits: WetNotes.barliterLabelToView(),
          price : WetNotes.priceBarliterToView(priceTopoff).toFixed(4),
          costs: costTopoff.toFixed(4)
        })
      }
    }
    const result = [];
    result.push(i18next.t('Costs'));
    priceInfos.forEach((item, index) => result.push(`\t${item.position}: ${item.price} * ${item.amount} ${item.amountUnits} = ${item.costs} ${WetNotes.SETTINGS.getCurrencyLabel()}`));
    result.push(`${i18next.t('Total')}: ${priceTotal.toFixed(2)} ${WetNotes.SETTINGS.getCurrencyLabel()}`);
    return result.join("\n");
  }
  const startAtTemperaturInfoAsText = () => {
    const viewValueOfTemperatur = WetNotes.formatTemperatur(WetNotes.MEASSURES.Temperatur.getAdjustedOfMetric(WetNotes.SETTINGS.getTemperatureC()));
    return `${i18next.t('start.at.temperature')} ${viewValueOfTemperatur} ${WetNotes.MEASSURES.Temperatur.getLabel()}\u2070`;
  }
  
  const displayPressureAsText = (pressure) => {
    const formatedValue = pressureToDisplay(pressure);
    return `${formatedValue} ${WetNotes.MEASSURES.Pressure.getLabel()}`;
  }
  function CheckAddingGas(props){
    const addingGas = props.addingGas;
    return (
      <div>
        {i18next.t('Add')}: {addingGas.getName()} ({addingGas.getMixString()}) 
        ,&nbsp;<TankBarliterInfo tankModel = {addingGas}></TankBarliterInfo>
        ,&nbsp;<DisplayAmount amount = {addingGas.getGasAmount_mol()}></DisplayAmount>
      </div>
    )
  }
  function CheckBlendingTank(props){
    const tankModel = props.tankModel;
    return (
      <div>
        {i18next.t('Check scuba tank')}
        : <b>({tankModel.getMixString()})
        ,&nbsp;<DisplayPressure pressure = {tankModel.getPressure_Bar()}></DisplayPressure></b>
        ,&nbsp;<TankBarliterInfo tankModel = {tankModel}></TankBarliterInfo>
        ,&nbsp;<DisplayMass mass = {tankModel.getMasse()}></DisplayMass>
        ,&nbsp;<DisplayAmount amount = {tankModel.getAmount_mol()}></DisplayAmount>
      </div>
    )
  }
  const tankBarLiterInfoAsText = (tankModel) => {
    return `${WetNotes.barliterValueToView(tankModel).toFixed(1)} ${WetNotes.barliterLabelToView()}`;
  }
  const displayMassAsText = (metricValue) => {
    const displayValue = WetNotes.MEASSURES.Masse.getAdjustedOfMetric(metricValue);
    const formatedValue = WetNotes.formatMasse(displayValue);
    return `${formatedValue} ${WetNotes.MEASSURES.Masse.getLabel()}`;
  }
  const displayAmountAsText = (amount) => {
    const formatedValue = amountToDisplay(amount);
    return `${formatedValue} ${i18next.t('mole')}`;
  }

  const checkAddingGasAsText = (addingGas) => {
    return `${i18next.t('Add')}: ${addingGas.getName()} (${addingGas.getMixString()}) ${tankBarLiterInfoAsText(addingGas)} ${displayAmountAsText(addingGas.getGasAmount_mol())}`;
  }

  const checkBlendingTankAsText = (tankModel) => {
    return `${i18next.t('Check scuba tank')}: (${tankModel.getMixString()}) ${displayPressureAsText(tankModel.getPressure_Bar())}, ${tankBarLiterInfoAsText(tankModel)}, ${displayMassAsText(tankModel.getMasse())}, ${displayAmountAsText(tankModel.getAmount_mol())}`;
  }

  const stepAddGasAsText = (addingGas, tankSenke) => {
    const tankAdded = tankSenke.clone().addGas(addingGas, addingGas.getGasAmount_mol());
    const deltaPressure = tankAdded.getPressure_Bar() - tankSenke.getPressure_Bar();
    let result = `${i18next.t('Add')} ${i18next.t(addingGas.getGasName())} ${displayPressureAsText(deltaPressure)} -> ${i18next.t('Up to')}: ${displayPressureAsText(tankAdded.getPressure_Bar())} ${i18next.t('in tank.')}`;
    if(WetNotes.SETTINGS.isShowDetails()){
      result +=`\n\t${checkAddingGasAsText(addingGas)}`;
      result +=`\n\t${checkBlendingTankAsText(tankAdded)}`;
      result +=`\n`;
    }
    return result;
  }
  const  stepDropGasAsText = (gasBlender) => {
    const deltaPressure = gasBlender.tankOrigin.getPressure_Bar() - gasBlender.tankOriginDrop.getPressure_Bar();
    const dropedPressure = gasBlender.tankOriginDrop.getPressure_Bar();
    return `${i18next.t('Drop')} ${displayPressureAsText(deltaPressure)} -> ${i18next.t('Until')}: ${displayPressureAsText(dropedPressure)} ${i18next.t('in tank.')}`;
  }
  const blendingStepsAsText = (gasBlender) => {
    const toleranceDelta = 0.08;
    const showDropGas = gasBlender.tankOrigin.getAmount_mol() !== gasBlender.dropGas.getGasAmount_mol();
    let tankSenke = gasBlender.tankOriginDrop.clone();
    let steps = [];

    const addingGasSequence = getAddingGasSequence(gasBlender);
    addingGasSequence.map((addingGas) => { 
      if(addingGas.getGasAmount_mol() > toleranceDelta){
        steps.push(stepAddGasAsText(addingGas, tankSenke.clone()));
        tankSenke.addGas(addingGas, addingGas.getGasAmount_mol());  
      }
      return tankSenke;
    });
    const result = [];
    if(showDropGas) result.push(stepDropGasAsText(gasBlender));
    if(WetNotes.SETTINGS.isShowDetails()){
      result.push(`\t${checkBlendingTankAsText(gasBlender.tankOriginDrop)}`);
      result.push(``);
    }
    result.push(...steps);
    return result.join('\n');
  }