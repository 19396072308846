/* 
 * Wetnotes gas blender toolkit
 * CCR Diluent MOD/END
 *
 * Oleg Gurevich, 2019
 */
import React, { Component } from 'react';
import i18next from 'i18next'
import  {WetNotes, WetNotesSettings, GasModel }  from '../wetnotes/wetnotes.js'
import { GasComponent, COLOR_WETNOTES } from '../WTNCommons'
import { MODSettings } from '../mod/MODSettings.jsx';
import MdSettings from 'react-ionicons/lib/MdSettings'
import MdClose from 'react-ionicons/lib/MdClose'
import {Alert} from "reactstrap"
import IosWarning from 'react-ionicons/lib/IosWarning'

const ccrDiluentEndModRelevantSettings = [WetNotesSettings.PROPERTY_MAX_END, WetNotesSettings.PROPERTY_MAX_PPO2, 
    WetNotesSettings.PROPERTY_MIN_PPO2, WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_He_TO_N2,
		WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_O2_TO_N2,  WetNotesSettings.PROPERTY_SHOW_DETAILS]
const DEFAULT_PPO2_SET_POINT = 0.7;

export class CcrDiluentEndMod extends Component {
  constructor(props){
    super(props);
    this.id = props.id !== undefined?props.id:'defaultCcrDiluentEndMod';
		this.gasDiluent = new GasModel(32, 0).setName('Diluent');
		this.ppo2Setpoint = DEFAULT_PPO2_SET_POINT;
    this.restoreStateOfCcrDiluentEndMod();
		this.state = { 
			toggleSettingsOn : false,
			ppo2Setpoint : this.ppo2Setpoint
		};
  }
  restoreStateOfCcrDiluentEndMod(){
    const loadedState = localStorage.getItem(this.id);
		if(loadedState === null) return;
		const parsedState = JSON.parse(loadedState);
		this.gasDiluent.setStateJSON(parsedState.gasDiluent);
		this.ppo2Setpoint = parsedState.ppo2Setpoint;
  }
  componentDidMount() {
    // WetNotes.SETTINGS.addPropertyChangeListener(this, gasMODRelevantSettings);
    this.gasDiluent.addPropertyChangeListener(this);
  }
  componentWillUnmount() {
    this.gasDiluent.removePropertyChangeListener(this);
    // WetNotes.SETTINGS.removePropertyChangeListener(this, gasMODRelevantSettings);
  }
  propertyChange(propertyChangeEvent){
    // if(propertyChangeEvent.getSource() === WetNotes.SETTINGS){
    //   this.setState({
    //     lastChangeEvent : propertyChangeEvent
    //   }); 
    // }
    if(propertyChangeEvent.getSource() === this.gasDiluent){
      this.saveStateOfCcrDiluentEndMod();
    }
  }
  saveStateOfCcrDiluentEndMod(){
		const persistingState = {
			gasDiluent : this.gasDiluent.getStateJSON(),
			ppo2Setpoint : this.ppo2Setpoint
		};
    localStorage.setItem(this.id, JSON.stringify(persistingState));
    console.log(JSON.stringify(persistingState));
  }
  setPpo2Setpoint(newPpo2Setpoint){
    this.ppo2Setpoint = newPpo2Setpoint;
    this.setState({ppo2Setpoint : newPpo2Setpoint});
    this.saveStateOfCcrDiluentEndMod();
  }
  render() {
    const rowToggledOn="row";
    const rowToggledOff="row d-none d-lg-flex";
      return (
            <div className = "container p-0 mt-2 text-left">
            <h4>{i18next.t('ccr.diluent.end.title')}</h4>
            <div className = "row">
              <div className = "col-lg-5">
                <div className={this.state.toggleSettingsOn?rowToggledOff:rowToggledOn}>
                  <div className = "col">
                    <GasComponent gasModel = {this.gasDiluent} showPressure = {false}>
                      <div className="h5 mt-2">{i18next.t('ccr.settings')}</div>
                      <CcrPpo2SetpointControl 
                        ppo2Setpoint = {this.ppo2Setpoint}
                        id="ccrPPO2SetpointCtrl" 
                        onSetPpo2Setpoint = 
                        { (newPpO2SetPoint ) => this.setPpo2Setpoint(newPpO2SetPoint)}>
                      </CcrPpo2SetpointControl>
										</GasComponent>
                  </div>
                </div>
                <div className={this.state.toggleSettingsOn?rowToggledOn:rowToggledOff}>
                  <div className = "col">
                    <MODSettings></MODSettings>
                  </div>
                </div>
              </div>
              <div className = "col-lg-7">
              <div className={this.state.toggleSettingsOn?rowToggledOff:rowToggledOn}>
                <div className = "col">
									<ModEndResults gasDiluent = {this.gasDiluent} 
									  getPpo2Setpoint = { () => { return Number.parseFloat(this.ppo2Setpoint)}} ></ModEndResults>
                </div>
                </div>
              </div>
            </div>
            
            <div className="wtn-settings-toggler d-inline-block d-lg-none" onClick={e => this.setState({toggleSettingsOn: !this.state.toggleSettingsOn})}>
                {this.state.toggleSettingsOn?
                  <MdClose fontSize="3em" color={COLOR_WETNOTES}></MdClose>:<MdSettings fontSize="3em" color={COLOR_WETNOTES}></MdSettings>
                }
            </div>
            </div>
    )
  }
}
function CcrPpo2SetpointControl(props){
  const options = [];
 for (let ppO2 = 0.5; ppO2 < 1.5; ppO2+= 0.1){
   options.push(<option value={Number.parseFloat(ppO2.toFixed(1))}>{ppO2.toFixed(1)}</option>)
 }
 return (
   <div className="form-group row">
   
     <label className="col-sm-5 col-form-label text-sm-right" htmlFor={props.id}>
      {i18next.t('ccr.ppo2.setpoint')}
     </label>
     <div className="col-sm-7">
       <select id={props.id} className="form-control" value={props.ppo2Setpoint} 
           onChange={(event) => props.onSetPpo2Setpoint(event.target.value)}>
           { options }
       </select>
       
     </div>
   </div>
 )
}

function DiluentEND(props){
  const correspondsENDMeters = props.correspondsENDMeters;
  const correspondsEND = correspondsENDMeters === undefined?i18next.t('diluent.not.narcotic'):
      WetNotes.formatDepth(WetNotes.MEASSURES.Depth.getAdjustedOfMetric(correspondsENDMeters));
  const maxEND = WetNotes.MEASSURES.Depth.getAdjustedOfMetric(WetNotes.SETTINGS.getMaxEND());
  const maxENDView = WetNotes.formatDepth(maxEND);      
  const depthLabel = WetNotes.MEASSURES.Depth.getLabel();
  return(
    <div>
      <h5>{i18next.t('diluent.end')}</h5>
      <div className="alert alert-warning">{i18next.t('Maximum END')}: {maxENDView} {depthLabel} {i18next.t('corresponds to depth')}: <span className="h5">{correspondsEND}</span> 
      &nbsp;{correspondsENDMeters === undefined?'':depthLabel}</div>
    </div>
  )
}
function DiluentMOD(props){
  const minOD = props.minOD;
  const maxOD = props.maxOD;
  const minODView = WetNotes.MEASSURES.Depth.getAdjustedOfMetric(minOD);
  const maxODView = WetNotes.MEASSURES.Depth.getAdjustedOfMetric(maxOD);
  const depthLabel = WetNotes.MEASSURES.Depth.getLabel();
return(
  <div>
    <div className="h5">{i18next.t('diluent.od')}</div>
    <div className = "row text-center">
      { minOD > 0 &&
      <div className = "col">
        <Alert color="danger">{i18next.t('Minimum')}: <span className="h5">{WetNotes.formatDepth(minODView)}</span> {depthLabel}</Alert>
      </div>
      }
      <div className = "col">
      <Alert color="danger">{i18next.t('Maximum')}: <span className="h5">{WetNotes.formatDepth(maxODView)}</span> {depthLabel}</Alert>
      </div>
    </div>
  </div>
)
}
function DiluentNotBreathableAlert(props){
  return(
    <Alert color="danger">
      <IosWarning fontSize="60px" color="red" beat={true}  ></IosWarning>
      {i18next.t("diluent.not.breatheable.danger")}
    </Alert>
  )
}
class ModEndResults extends Component{
  constructor(props){
    super(props);
    this.gasDiluent = props.gasDiluent;
    this.getPpo2Setpoint = props.getPpo2Setpoint;
  }
  componentDidMount() {
    WetNotes.SETTINGS.addPropertyChangeListener(this, ccrDiluentEndModRelevantSettings);
    this.gasDiluent.addPropertyChangeListener(this);
  }
  componentWillUnmount() {
    this.gasDiluent.removePropertyChangeListener(this);
    WetNotes.SETTINGS.removePropertyChangeListener(this, ccrDiluentEndModRelevantSettings);
  }
  propertyChange(propertyChangeEvent){
       this.setState({
         lastChangeEvent : propertyChangeEvent
       }); 
  }
  render(){
    const diluentBreathable = this.getDiluentO2() > 0;
    const showENDColumn = WetNotes.SETTINGS.getNacroticEffectFactorO2ToN2() > 0 || 
      undefined !== this.getDiluentDepthMeters4End(WetNotes.SETTINGS.getMaxEND());
    return(
      <div className={"bg-white p-3 rounded-lg border mb-4"}>
     { diluentBreathable &&
        <DiluentMOD minOD = {this.getMinOperativeDepthOfDiluent()} maxOD = {this.getMaxOperativeDepthOfDiluent()}></DiluentMOD>
     }
     { diluentBreathable &&
        <DiluentEND correspondsENDMeters = {this.getDiluentDepthMeters4End(WetNotes.SETTINGS.getMaxEND())} ></DiluentEND>
     }
     { !diluentBreathable &&
        <DiluentNotBreathableAlert></DiluentNotBreathableAlert>
     }
 
      <h5>{i18next.t('ccr.result.mix.table')}</h5>
      <div className="h6">
        {i18next.t("diluent")}: {this.gasDiluent.getGasName()}&nbsp;
        <span className="text-success">
        {i18next.t("ccr.ppo2.setpoint")}: {this.getPpo2Setpoint().toFixed(2)}
        </span>
      </div>
      <table className = "table table-responsive-sm table-sm table-striped">
        {this.renderTableHead(showENDColumn)}
        {this.renderTableBody(showENDColumn)}
      </table>
      </div>
    )
  }
  renderTableBody(showENDColumn){
    const resultTableData = this.createResultTableData();
    const ppo2Setpoint = this.getPpo2Setpoint();
    return (
        <tbody>
          {
              resultTableData.map((item) => (<ResultTableRow rowData = {item} 
                showN2Column = {this.gasDiluent.getN2() > 0}
                showHeColumn = {this.gasDiluent.getHe() > 0}
                ppo2Setpoint = {ppo2Setpoint}
                showENDColumn = {showENDColumn}></ResultTableRow>))
          }
        </tbody>
    )

  }
  renderTableHead(showENDColumn){
    const depthLabel = WetNotes.MEASSURES.Depth.getLabel();
    const densityLabel = WetNotes.MEASSURES.DensityGramLiter.getLabel();
    return (
      <thead>
          <tr>
            <th scope="col">{i18next.t('Depth')} ({depthLabel})</th>
            <th scope="col">{i18next.t('ccr.mix')} </th>
          {showENDColumn &&
            <th scope="col">{i18next.t('END')} ({depthLabel})</th>
          }
          <th scope="col">{i18next.t('gas.density')} ({densityLabel})</th>
          <th scope="col">{i18next.t('O2 p.p.')}</th>
          <th scope="col">{i18next.t('Narcotic p.p.')}</th>
          { this.gasDiluent.getHe() > 0 &&
          <th scope="col">{i18next.t('He p.p.')}</th>
          }
          { this.gasDiluent.getN2() > 0 &&
          <th scope="col">{i18next.t('N2 p.p.')}</th>
          }
          </tr>
        </thead>
    )
  }
  /** 
	 * @return min operative depth in meters
	 */
	getMinOperativeDepthOfDiluent(){
    const diluentO2 = this.getDiluentO2();
    if(diluentO2 === 0) return null;
		const mod = WetNotes.SETTINGS.getMinPpO2() * 100.0 / diluentO2 * 10 - 10;
		if(mod < 0) return 0;
		return mod;
	}
	/** 
	 * @return max operative depth in meters
	 */
	getMaxOperativeDepthOfDiluent(){
    const diluentO2 = this.getDiluentO2();
    if(diluentO2 === 0) return null;
		const mod = WetNotes.SETTINGS.getMaxPpO2() * 100.0 / diluentO2 * 10 - 10;
		if(mod < 0) return 0;
		return mod;
	}
	/** 
	 * @return END in meters
	 */
	getEND(gasModel, depthMeters){
    const kNarcO2N = WetNotes.SETTINGS.getNacroticEffectFactorO2ToN2();
		const kNarcHe2N = WetNotes.SETTINGS.getNacroticEffectFactorHeToN2();
		let end = ((((100.0 - gasModel.getO2() - gasModel.getHe())  + gasModel.getHe() * kNarcHe2N + gasModel.getO2()* kNarcO2N) )
				/ (79.0 + 21.0 * kNarcO2N) * (10.0 + depthMeters) - 10);
		if(end < 0) return undefined;
		return end;
	}
  getDiluentDepthMeters4End(endMeters){
    return this.getDepthMeters4End(this.gasDiluent, endMeters);
  }
	getDepthMeters4End(gasModel, endMeters){
    const kNarcO2N = WetNotes.SETTINGS.getNacroticEffectFactorO2ToN2();
		const kNarcHe2N = WetNotes.SETTINGS.getNacroticEffectFactorHeToN2();
    const gasNarcPercent = (((100.0 - gasModel.getO2() - gasModel.getHe())  + gasModel.getHe() * kNarcHe2N + 
    gasModel.getO2()* kNarcO2N) );
		if(gasNarcPercent === 0) return undefined;
		const depthMeters4End = (endMeters + 10) * (79.0 + 21.0 * kNarcO2N) / gasNarcPercent - 10.0;
		return depthMeters4End;
	}

	getDiluentO2() {
		return this.gasDiluent.getO2();
	}
  getDensity(gasModel, depthMeters){
    const density = gasModel.getDensityAt1Ata() * (depthMeters / 10.0 + 1);
    return density;
  }
	getDiluentHe() {
		return this.gasDiluent.getHe();
	}
	getNarcPP(gasModel, d) {
		const kNarcO2N = WetNotes.SETTINGS.getNacroticEffectFactorO2ToN2();
		const kNarcHe2N = WetNotes.SETTINGS.getNacroticEffectFactorHeToN2();
    const pp = (gasModel.getN2() + gasModel.getHe() * kNarcHe2N + gasModel.getO2() * kNarcO2N) *
       ( d / 10.0 + 1) / 100;
    return Math.abs(pp);   
	}
	getPPO2(gasModel, d) {
		const pp = (gasModel.getO2() * (d / 10.0 + 1)) / 100.0;
    return Math.abs(pp);
	}
	getPPN2(gasModel, d) {
    const pp = (gasModel.getN2() * (d / 10.0 + 1)) / 100.0;
    return Math.abs(pp);
	}
	getPPHe(gasModel, d) {
    const pp = (gasModel.getHe() * (d / 10.0 + 1)) / 100.0;
    //console.log(d + "   " + pp);
    return Math.abs(pp);
  }
  estimateMaxDepth(){
    if(this.gasDiluent.getO2() > 0) return this.getMaxOperativeDepthOfDiluent() + 6;
    const diluentENDDepth = this.getDiluentDepthMeters4End(WetNotes.SETTINGS.getMaxEND());
    if(diluentENDDepth !== undefined) return 12.0 + diluentENDDepth;
    return 226;
  }
  getFiForPP(pp, depthMeters){
    return pp / (depthMeters / 10.0 + 1)
  }
  getCcrMix(ppo2Setpoint, depthMeters){
    const calculatedFiO2 = this.getFiForPP(ppo2Setpoint, depthMeters);
    const targetFiO2 = calculatedFiO2 > 1?1.0:calculatedFiO2;
    if(targetFiO2 <= this.gasDiluent.getFiO2()){
      // don't add oxygen
      const ccrGasModel = new GasModel(20, 10).applyGasModel(this.gasDiluent);
      return ccrGasModel;
    };
    const x = (1 - targetFiO2) / (1 - this.gasDiluent.getFiO2());
    const ccrGasModel = new GasModel( targetFiO2 * 100, x * this.gasDiluent.getHe()).setName("ccr gas mix");
    //console.log(ccrGasModel.getGasName());
    return ccrGasModel;
  }

  createResultTableData() {
		const resultTableData = [];
				
		let depthStep = 2;
    const startDepth = 0;
    const ppo2Setpoint = this.getPpo2Setpoint();

		if(!WetNotes.SETTINGS.isMetricSystem()){
      // redefine start depth and step value
      // use 10 feets as step on imperial system
			depthStep = 10.0 / WetNotes.MEASSURES.Depth.getK2Imperial();
    }
    const maxDepth = this.estimateMaxDepth();
    
    if((maxDepth) / depthStep > 96){
      depthStep = depthStep * 3;
    }else
    if((maxDepth) / depthStep > 42){
      depthStep = depthStep * 2;
    }

		for(let d = startDepth; d < maxDepth; d = d + depthStep){
      // row's data
      const resultTableRow = {};
      resultTableRow.depth = d;
      resultTableRow.ccrMix = this.getCcrMix(ppo2Setpoint, d);
			resultTableRow.end = this.getEND(resultTableRow.ccrMix, d);
			resultTableRow.narcPP = this.getNarcPP(resultTableRow.ccrMix, d);
			resultTableRow.ppO2 = this.getPPO2(resultTableRow.ccrMix, d);
			resultTableRow.ppN2 = this.getPPN2(resultTableRow.ccrMix, d);
			resultTableRow.ppHe = this.getPPHe(resultTableRow.ccrMix, d);
			resultTableRow.density = this.getDensity(resultTableRow.ccrMix, d);
			resultTableData.push(resultTableRow);
		}
		// row's data
    const resultTableRow = {};
    const d = maxDepth;
    resultTableRow.depth = d;
    resultTableRow.ccrMix = this.getCcrMix(ppo2Setpoint, d);
    resultTableRow.end = this.getEND(resultTableRow.ccrMix, d);
    resultTableRow.narcPP = this.getNarcPP(resultTableRow.ccrMix, d);
    resultTableRow.ppO2 = this.getPPO2(resultTableRow.ccrMix, d);
    resultTableRow.ppN2 = this.getPPN2(resultTableRow.ccrMix, d);
    resultTableRow.ppHe = this.getPPHe(resultTableRow.ccrMix, d);
    resultTableRow.density = this.getDensity(resultTableRow.ccrMix, d);
    resultTableData.push(resultTableRow);
    		return resultTableData;
	}
}
function TdGasDensity(props){
  return (
    <td>
      {WetNotes.MEASSURES.DensityGramLiter.getAdjustedOfMetric(props.density).toFixed(3)}
    </td>
  )
}
function ResultTableRow(props){
  const showENDColumn = props.showENDColumn;
  const showHeColumn = props.showHeColumn;
  const showN2Column = props.showN2Column;
  const rowData = props.rowData;
  const maxEND = WetNotes.SETTINGS.getMaxEND();
  const endDataDefined = rowData.end !== undefined;
  const endExceeded = showENDColumn && rowData.end >= maxEND;
  const ppO2Exceeded = rowData.ppO2 < WetNotes.SETTINGS.getMinPpO2() || 
  rowData.ppO2 - WetNotes.SETTINGS.getMaxPpO2() >= 0.005;
  const setPointOk = Math.abs(rowData.ppO2 - props.ppo2Setpoint) < 0.005;

  let trClassName = "";
  if(endExceeded) trClassName="table-warning";
  if(ppO2Exceeded) trClassName="table-danger";
  return (
    <tr className = {trClassName}>
      <td>{WetNotes.formatDepth(WetNotes.MEASSURES.Depth.getAdjustedOfMetric(rowData.depth))}</td>
      <td>{rowData.ccrMix.getGasName()}</td>
      {showENDColumn && endDataDefined &&
      <td className = {endExceeded?"text-danger font-weight-bold":""} >{WetNotes.formatDepth(WetNotes.MEASSURES.Depth.getAdjustedOfMetric(rowData.end))}</td>
      }
       {showENDColumn && !endDataDefined &&
      <td>-</td>
      }
      <TdGasDensity density = {rowData.density}></TdGasDensity>
      <td className = {ppO2Exceeded?"text-danger font-weight-bold":setPointOk?"text-success font-weight-bold":""} >{WetNotes.formatPercent(rowData.ppO2)}</td>
      <td>{WetNotes.formatPercent(rowData.narcPP)}</td>
      {showHeColumn &&
      <td>{WetNotes.formatPercent(rowData.ppHe)}</td>
      }
      {showN2Column  &&
      <td>{WetNotes.formatPercent(rowData.ppN2)}</td>
      }     
    </tr>     
  )
}
