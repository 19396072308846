import React from 'react';
import { WetModel, WetNotes, WetNotesSettings } from "./wetnotes";
import { firefoxWorkaroundDelyinMsToIgnoreOnblure, KEY_ENTER, KEY_ESCAPE, UnitsLiterPerMinute } from '../WTNCommons';
import i18next from 'i18next'

export const RMV_MAX_dm3 = 40.0;
export const RMV_MIN_dm3 = 4.0;
export const O2_CONSUMPTION_MIN = 0.4;
export const O2_CONSUMPTION_MAX = 6;
	
export class DiverModel extends WetModel{
	static get PROPERTY_RMV() { return "prop_rmv" };
	static get PROPERTY_O2_CONSUMPTION() { return "prop_o2Consumption" };
	constructor(){
		super();
		this.rmv_dm3 = 15;
		this.o2Consumption_dm3 = 1.0;
		this.installListeners();
	}
	propertyChange(propertyChangeEvent){
		if(propertyChangeEvent.getSource() === this){
			if( this.getO2Consumption_dm3() > this.getRmv_dm3() ){
				if(propertyChangeEvent.getPropertyName() === DiverModel.PROPERTY_O2_CONSUMPTION){
					this.setRmv_dm3(this.getO2Consumption_dm3());
				}
				if(propertyChangeEvent.getPropertyName() === DiverModel.PROPERTY_RMV){
					this.setO2Consumption_dm3(this.getRmv_dm3());
				}
			}
		} 
	}
	installListeners() {
		this.addPropertyChangeListener(this);
	}
	getRmv_dm3() { return this.rmv_dm3 }
	getO2Consumption_dm3() { return this.o2Consumption_dm3 }
	setStateJSON(jsonState = {}){
		if(jsonState.rmv !== undefined) this.setRmv_dm3(jsonState.rmv);
		if(jsonState.o2Consumption !== undefined) this.setO2Consumption_dm3(jsonState.o2Consumption);
		return this;
	}
	getStateJSON(){
		return {
			rmv : this.getRmv_dm3(),
			o2Consumption : this.getO2Consumption_dm3()
		}
	}
	setRmv_dm3(newValue) {
		let oldValue = this.rmv_dm3;
		this.rmv_dm3 = newValue;
		this.propertyChangeSupport.firePropertyChange(DiverModel.PROPERTY_RMV, oldValue, newValue);
		return this;
	}
	setO2Consumption_dm3(newValue) {
		let oldValue = this.o2Consumption_dm3;
		this.o2Consumption_dm3 = newValue;
		this.propertyChangeSupport.firePropertyChange(DiverModel.PROPERTY_O2_CONSUMPTION, oldValue, newValue);
		return this;
	}
	/** KValue = RMV / O2Consumption */
	getKValue(){
		return this.rmv_dm3 / this.o2Consumption_dm3;
	}
}

export class O2ConsumptionComponent extends React.Component{
	constructor(props){
		super(props);
		this.diverModel = props.diverModel;
		this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
		this.state = {
			editMode: false, 
			editValue: WetNotes.formatVolume(this.getModelValue4View())
		};
	}
	propertyChange(propertyChangeEvent){
		 this.setState({
			lastChangeEvent : propertyChangeEvent, 
			editValue: WetNotes.formatVolume(this.getModelValue4View())
		}); 
	}

	componentDidMount() {
		this.diverModel.addPropertyChangeListener(this, [DiverModel.PROPERTY_O2_CONSUMPTION]);
		WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	
	componentWillUnmount() {
		this.diverModel.removePropertyChangeListener(this, [DiverModel.PROPERTY_O2_CONSUMPTION]);
		WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	handleOnFocus(e){
		e.target.select();
	}
	startEditMode(){
		this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
	}
	handleOnBlur(e){
		// workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
		if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
		// end of workaround

		this.applyEditValue(e);
		this.editCompleted();
	}
	applyEditValue(e){
		if (this.isEditorValueValid()){
			const numberValue = Number.parseFloat(this.state.editValue);
			let newVolumeLiter = WetNotes.MEASSURES.Volume.getMetricOfCurrent(numberValue);
			if(newVolumeLiter > O2_CONSUMPTION_MAX) newVolumeLiter = O2_CONSUMPTION_MAX;
			if(newVolumeLiter < O2_CONSUMPTION_MIN) newVolumeLiter = O2_CONSUMPTION_MIN;
			this.diverModel.setO2Consumption_dm3(newVolumeLiter);
		}
	}
	editCompleted(){
		this.setState({editMode : false, editValue: WetNotes.formatVolume(this.getModelValue4View())});
	}
	handleKeyUp(e){
		switch(e.keyCode){
			case KEY_ENTER:
				this.applyEditValue(e);
				this.editCompleted();
				break;
			case KEY_ESCAPE:
					this.editCompleted();
				break;
			default:
		}
	}
	handleChange(e){
		this.setState({ editValue:  e.target.value});
	}
	handleRangeSet(e){
		const newVolumeLiter = WetNotes.MEASSURES.Volume.getMetricOfCurrent(Number.parseFloat(e.target.value));
		this.diverModel.setO2Consumption_dm3(newVolumeLiter);
	}
	isEditorValueValid(){
		const editValue = this.state.editValue
		if(this.state.editValue === "") return false;
		const numberValue = Number.parseFloat(editValue);
		if(numberValue < 0) return false;
		return true;
	}
	getModelValue4View(){
		return WetNotes.MEASSURES.Volume.getAdjustedOfMetric(this.diverModel.getO2Consumption_dm3());
	}
	render(){
		const classNameEditableValueViewMode = this.readOnly?"readonlyValue":"editableValue";
		const viewModelValue = this.getModelValue4View();
		const maxValue = WetNotes.MEASSURES.Volume.getAdjustedOfMetric(O2_CONSUMPTION_MAX);
		const minValue = WetNotes.MEASSURES.Volume.getAdjustedOfMetric(O2_CONSUMPTION_MIN);
		const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
		return (
			<div className={this.props.className}>
				{(this.readOnly || !this.state.editMode) &&
					<div className={classNameEditableValueViewMode + " h5"} onClick={ (e)=> this.startEditMode() }>{WetNotes.formatVolume(viewModelValue)}</div>
				}
				{(!this.readOnly && this.state.editMode) &&
					<div>
					<input className={"form-control"+ classNameAddOn}  required={true} type="number" autoFocus
								onFocus={(e) => this.handleOnFocus(e)}
								onChange={(e) => this.handleChange(e) } 
								onKeyUp={(e) => this.handleKeyUp(e)}
								onBlur={(e) => this.handleOnBlur(e)}
								value={this.state.editValue}>
							</input>
					</div>
				}
				{!this.readOnly && WetNotes.SETTINGS.isDisplaySliders() &&
					<div className="mt-2 mb-2">
						<input type="range" min={ minValue } max={ maxValue } step="0.1" 
							value={viewModelValue} onChange={(e) => this.handleRangeSet(e)} className="form-control-range"/>
					</div>
				}
			</div>
		)
	}
}
export class RmvComponent extends React.Component{
	constructor(props){
		super(props);
		this.diverModel = props.diverModel;
		this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
		this.state = {
			editMode: false, 
			editValue: WetNotes.formatVolume(this.getModelValue4View())
		};
	}
	propertyChange(propertyChangeEvent){
		 this.setState({
			lastChangeEvent : propertyChangeEvent, 
			editValue: WetNotes.formatVolume(this.getModelValue4View())
		}); 
	}

	componentDidMount() {
		this.diverModel.addPropertyChangeListener(this, [DiverModel.PROPERTY_RMV]);
		WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	
	componentWillUnmount() {
		this.diverModel.removePropertyChangeListener(this, [DiverModel.PROPERTY_RMV]);
		WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	handleOnFocus(e){
		e.target.select();
	}
	startEditMode(){
		this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
	}
	handleOnBlur(e){
		// workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
		if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
		// end of workaround

		this.applyEditValue(e);
		this.editCompleted();
	}
	applyEditValue(e){
		if (this.isEditorValueValid()){
			const numberValue = Number.parseFloat(this.state.editValue);
			let newVolumeLiter = WetNotes.MEASSURES.Volume.getMetricOfCurrent(numberValue);
			if(newVolumeLiter > RMV_MAX_dm3) newVolumeLiter = RMV_MAX_dm3;
			if(newVolumeLiter < RMV_MIN_dm3) newVolumeLiter = RMV_MIN_dm3;
			this.diverModel.setRmv_dm3(newVolumeLiter);
		}
	}
	editCompleted(){
		this.setState({editMode : false, editValue: WetNotes.formatVolume(this.getModelValue4View())});
	}
	handleKeyUp(e){
		switch(e.keyCode){
			case KEY_ENTER:
				this.applyEditValue(e);
				this.editCompleted();
				break;
			case KEY_ESCAPE:
					this.editCompleted();
				break;
			default:
		}
	}
	handleChange(e){
		this.setState({ editValue:  e.target.value});
	}
	handleRangeSet(e){
		const newVolumeLiter = WetNotes.MEASSURES.Volume.getMetricOfCurrent(Number.parseFloat(e.target.value));
		this.diverModel.setRmv_dm3(newVolumeLiter);
	}
	isEditorValueValid(){
		const editValue = this.state.editValue
		if(this.state.editValue === "") return false;
		const numberValue = Number.parseFloat(editValue);
		if(numberValue < 0) return false;
		return true;
	}
	getModelValue4View(){
		return WetNotes.MEASSURES.Volume.getAdjustedOfMetric(this.diverModel.getRmv_dm3());
	}
	render(){
		const classNameEditableValueViewMode = this.readOnly?"readonlyValue":"editableValue";
		const viewModelValue = this.getModelValue4View();
		const maxValue = WetNotes.MEASSURES.Volume.getAdjustedOfMetric(RMV_MAX_dm3);
		const minValue = WetNotes.MEASSURES.Volume.getAdjustedOfMetric(RMV_MIN_dm3);
		const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
		return (
			<div className={this.props.className}>
				{(this.readOnly || !this.state.editMode) &&
					<div className={ classNameEditableValueViewMode +" h5"} onClick={ (e)=> this.startEditMode() }>{WetNotes.formatVolume(viewModelValue)}</div>
				}
				{(!this.readOnly && this.state.editMode) &&
					<div>
					<input className={"form-control "+ classNameAddOn}  required={true} type="number" autoFocus
								onFocus={(e) => this.handleOnFocus(e)}
								onChange={(e) => this.handleChange(e) } 
								onKeyUp={(e) => this.handleKeyUp(e)}
								onBlur={(e) => this.handleOnBlur(e)}
								value={this.state.editValue}>
							</input>
					</div>
				}
				{!this.readOnly && WetNotes.SETTINGS.isDisplaySliders() &&
					<div className="mt-2 mb-2">
						<input type="range" min={ minValue } max={ maxValue } step="1" 
							value={viewModelValue} onChange={(e) => this.handleRangeSet(e)} className="form-control-range"/>
					</div>
				}
			</div>
		)
	}
}
export function RmvLabel(props){
  const labelText = i18next.t('diver.rmv');
  return (
        <label className="control-label">{labelText} (<UnitsLiterPerMinute></UnitsLiterPerMinute>)</label>
  )
}
export function O2ConsumptionLabel(props){
  const labelText = i18next.t('diver.o2consumption')
  return (
        <label className="control-label">{labelText} (<UnitsLiterPerMinute></UnitsLiterPerMinute>)</label>
  )
}