/* 
 * Diclaimer Component
 *
 * Oleg Gurevich, 2019
 */
import React from 'react';
import { COLOR_WETNOTES, CSS_CLASSNAME_PANEL } from './WTNCommons'
import MdClose from 'react-ionicons/lib/MdClose'
import { PrivacyPolicyContentEn } from './PrivacyPolicyContentEn';
import { PrivacyPolicyContentDe } from './PrivacyPolicyContentDe';
import { PrivacyPolicyContentRu } from './PrivacyPolicyContentRu';
import { WetNotes } from './wetnotes/wetnotes';


/**
 * @param provide function props.toClose can be used
 */
export function PrivacyPolicy(props){
  return (
    <div className = "mt-4 " >
      <div className = "row justify-content-center" >
        <div className = "col col-md-8" >
          <div className={CSS_CLASSNAME_PANEL}>
          <PrivacyPolicyContent toClose = {props.toClose}></PrivacyPolicyContent>
        </div>
      </div>
      </div>
    </div>
  )
}
/**
 * @param provide function props.toClose can be used
 */
function PrivacyPolicyContent(props){
  const toClose = props.toClose === undefined ? '':props.toClose;
  return (
    <div className="position-relative">
    <div className="wtn-dimiss-absolute-disclaimer d-inline-block " onClick = {() => toClose()}>
          <MdClose fontSize="2em" color={COLOR_WETNOTES}></MdClose>
    </div>
     { WetNotes.SETTINGS.getLanguageUI() === 'en' &&
      <PrivacyPolicyContentEn></PrivacyPolicyContentEn>
     }
     { WetNotes.SETTINGS.getLanguageUI() === 'de' &&
      <PrivacyPolicyContentDe></PrivacyPolicyContentDe>
     }
     { WetNotes.SETTINGS.getLanguageUI() === 'ru' &&
      <PrivacyPolicyContentRu></PrivacyPolicyContentRu>
     }


    </div>
  )
}