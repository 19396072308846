import React from 'react';

import i18next from 'i18next';
import { RmvLabel, O2ConsumptionLabel, RmvComponent, O2ConsumptionComponent } from './Diver';
import { WetNotes, WetNotesSettings } from './wetnotes';

export class DiverComponent extends React.Component{
  constructor(props){
    super(props);
    
    this.pscrModel = props.pscrModel;
    this.nameI18n = (undefined === props.nameI18n)?"noname":props.nameI18n;
    
		this.volumeReadOnly = (undefined === props.volumeReadOnly)?false:props.volumeReadOnly;
		this.dumpratioReadOnly = (undefined === props.dumpratioReadOnly)?false:props.dumpratioReadOnly;
		this.dumpratioOfInjectedReadOnly = (undefined === props.dumpratioOfInjectedReadOnly)?false:props.dumpratioOfInjectedReadOnly;
    
    this.state = { showInputFields: false };
    
  }
 	propertyChange(propertyChangeEvent){
    this.setState({lastChangeEvent : propertyChangeEvent}); 
  }

  componentDidMount() {
    this.pscrModel.diverModel.addPropertyChangeListener(this);
		WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS]);
  }

  componentWillUnmount() {
    this.pscrModel.diverModel.removePropertyChangeListener(this);
		WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS]);
  }
    
  render() {
    return (
      <div className={this.props.className}>
        <h5>{i18next.t(this.nameI18n)}</h5>
        <div>
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-10">
              <div className="row">
                <div className="col-4">
                  <RmvLabel></RmvLabel>
                </div>
                <div className="col-4">
                  <O2ConsumptionLabel></O2ConsumptionLabel>
                </div>
                <div className="col-4">
                  <KValueLabel></KValueLabel>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <RmvComponent diverModel = {this.pscrModel.diverModel}></RmvComponent>
                </div>
                <div className="col-4">
                  <O2ConsumptionComponent diverModel = {this.pscrModel.diverModel}></O2ConsumptionComponent>
                </div>
                <div className="col-4">
                  <KValue diverModel = {this.pscrModel.diverModel} className="readonlyValue h5"></KValue>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.children}
      </div>

    )
  }
}
function KValueLabel(props){
  const labelText = i18next.t('diver.k.value');
  return (
        <span>{labelText}</span>
  )
}
function KValue(props){
  const diverModel = props.diverModel;
  const kValue = diverModel.getRmv_dm3() / diverModel.getO2Consumption_dm3();
  return (
		<div className = {props.className}>{WetNotes.formatPercent(kValue)}</div>
  )
}