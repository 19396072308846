/* 
 * Components for blender results displaying
 *
 * Oleg Gurevich, 2019
 */

import React from 'react';

import { OptionUnits, OptionLanguageUI, OptionPriceO2, OptionFlatPriceTopoff, OptionPriceTopoff, OptionPriceHe, OptionCurrencyLabel, OptionDisplaySliders } from './SharedOptions'

import i18next from 'i18next'

import MdSettings from 'react-ionicons/lib/MdSettings'
import { COLOR_WETNOTES, WTNPanel } from './WTNCommons.jsx';
import { StandardGasConfigurator } from './components/StandardGasEditor.jsx';
import { OptionMaxPpO2 } from './pscr/PSCRSettings';
import { OptionMinPpO2 } from './mod/MODSettings';
import { TankVolumesConfigurator } from './components/TankVolumesConfigurator';


export function GlobalSettingsScreen(props){
	const divStyle = {  minWidth: '22em' };
  return (
		<div className = "container p-0 mt-2 text-left">
    <h5><MdSettings fontSize="2em" color={COLOR_WETNOTES}></MdSettings> {i18next.t('common.settings')}</h5>
		<WTNPanel style = {divStyle}>
			<div className="row">
				<div className="col-lg-6 col-xl">
					<StandardGasConfigurator></StandardGasConfigurator>
				</div>	
				<div className="col-lg-6 col-xl">
					<TankVolumesConfigurator/>
				</div>	
        <div className="col-lg-6 col-xl">
					<h5>{i18next.t('common')}</h5>
          <OptionUnits></OptionUnits>
          <OptionLanguageUI></OptionLanguageUI>
          <OptionDisplaySliders></OptionDisplaySliders>
					<h5>{i18next.t('Partial pressure of Oxygen')} </h5>
          <OptionMaxPpO2></OptionMaxPpO2>
          <OptionMinPpO2></OptionMinPpO2>
					<h5>{i18next.t('Price calculation')}</h5>
					<OptionCurrencyLabel></OptionCurrencyLabel>
					<OptionPriceHe></OptionPriceHe>
					<OptionPriceO2></OptionPriceO2>
					<OptionFlatPriceTopoff></OptionFlatPriceTopoff>
					<OptionPriceTopoff></OptionPriceTopoff>
        </div>
      </div>
		</WTNPanel>					
		</div>
  )
}


