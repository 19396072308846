/* 
 * Diclaimer Component
 *
 * Oleg Gurevich, 2019
 */
import React from 'react';

import { COLOR_WETNOTES } from './WTNCommons'
import MdClose from 'react-ionicons/lib/MdClose'
import i18next from 'i18next'

/**
 * @param provide function props.toClose can be used
 */
export function Disclaimer(props){
  return (
    <div className = "container" >
          <DisclaimerContent toClose={props.toClose}></DisclaimerContent>
    </div>
  )
}
/**
 * @param provide function props.toClose can be used
 */
function DisclaimerContent(props){
  const toClose = props.toClose === undefined ? '':props.toClose;
  return (
    <div className="position-relative jumbotron">
      <div className="wtn-dimiss-absolute-disclaimer d-inline-block " onClick = {() => toClose()}>
            <MdClose fontSize="2em" color={COLOR_WETNOTES}></MdClose>
      </div>
      <p className="lead"><strong>{i18next.t('disclaimer')}</strong></p>
      <p className="lead">{i18next.t('disclaimer.welcome')}</p>
      <p className="lead">{i18next.t('disclaimer.intro')}</p>
      <p className="lead">{i18next.t('disclaimer.no.warranty')}</p>
   
      <p className="lead">{i18next.t('disclaimer.contact')}: blender-toolkit@t-provider.de</p>
      <p className="lead">{i18next.t('disclaimer.safe.dives')}</p>
      <button type="button" onClick = {() => toClose()} className="btn btn-secondary btn-lg btn-block">Ok</button>
    </div>
  )
}