import React from 'react';
import i18next from 'i18next';
import standardGasSet from '../wetnotes/StandardGasSet';
import { GasComponent } from '../WTNCommons';
import { GasModel } from '../wetnotes/wetnotes';

export class StandardGasConfigurator extends React.Component{
  constructor(props){
    super(props);
		this.state = { showRestoreDefaults: true };
		this.addingGadModel = new GasModel(21, 0).setName('add.standard.gas');
  }

	propertyChange(propertyChangeEvent){
    this.setState({lastChangeEvent : propertyChangeEvent}); 
  }

  componentDidMount() {
		standardGasSet.addPropertyChangeListener(this);
  }

  componentWillUnmount() {
		standardGasSet.removePropertyChangeListener(this);
  }
	
	render() {
    return (
      <div className={this.props.className}>
        <h5>{i18next.t('standard.gasset.configure')}</h5>
        <ListOfStandardGas></ListOfStandardGas>
				<GasComponent showPressure={false} gasModel = {this.addingGadModel}>
					<button onClick={ () => standardGasSet.addGas(this.addingGadModel)}	
							className="btn btn-secondary">{i18next.t('Add')}</button> <button onClick={ () => standardGasSet.resetGasSet()}	
							className="ml-2 btn btn-light">{i18next.t('restore.standard')}</button>
				</GasComponent>
        
      </div>

    )
  }
}
function ListOfStandardGas(props){
	return(
		<table className = "table table-sm table-striped border-bottom">
			<tbody>
				{
						standardGasSet.getGasSet().map((item) => (<RowOfStandardGas gasModel = {item}></RowOfStandardGas>))
				}
			</tbody>
		</table>
	)
}
function RowOfStandardGas(props){
	const gasModel = props.gasModel;
	return (
		<tr>
			<td>{gasModel.getGasName()}</td>
				<td className="text-right">
					<button type="button" 
							onClick = { () => standardGasSet.removeGas(gasModel)}
							className="btn btn-sm btn-outline-danger">{i18next.t('Delete')}</button>
				</td>
		</tr>
	)
}

