
import { WetModel, TankModel, WetNotesSettings, WetNotes, GasModel } from "../wetnotes/wetnotes";

const topoffRelevantSettings = [WetNotesSettings.PROPERTY_REAL_GAS_MODEL, WetNotesSettings.PROPERTY_TEMPERATURE]

export class TopoffModel extends WetModel{
	static get PROPERTY_DESIRED_PRESSURE() { return "prop_desired_pressure_topoff" };
	static get PROPERTY_TOPOFF_COMPLETED() { return "prop_topoff_calculation_completed" };
	static get PRECISION_VALUE() { return 0.04 };
	constructor(){
		super();
		this.storageTank = new TankModel('A) Storage tank', 100, 0, 300, 50);
		this.sinkTank = new TankModel('topoff.sink.start', 21, 0, 0, 10.8);
		this.desiredPressureHolder = new GasModel(21, 0, 80).setName('С) Desired pressure');

		this.remnantStorageTank = new TankModel('Storage remnant (A)', 32, 0, 300, 50);
		this.resultSinkTank = new TankModel('topoff.sink.result', 21, 0, 69, 24);

		this.availableFillCount = 0;
		this.installListeners();
		this.calculateTopoff(); 
	}
	
	installListeners(){
		WetNotes.SETTINGS.addPropertyChangeListener(this, topoffRelevantSettings);
		this.storageTank.addPropertyChangeListener(this);
		this.sinkTank.addPropertyChangeListener(this);
		this.desiredPressureHolder.addPropertyChangeListener(this, [GasModel.PROPERTY_PRESSURE]);
	}
	propertyChange(propertyChangeEvent){
    this.calculateTopoff();
	}
	fireCalculated(){
		this.propertyChangeSupport.firePropertyChange(TopoffModel.PROPERTY_TOPOFF_COMPLETED, false, true);
	}
	setStateJSON(jsonState = {}){
			if(jsonState.storageTank !== undefined) this.storageTank.setStateJSON(jsonState.storageTank);
			if(jsonState.sinkTank !== undefined) this.sinkTank.setStateJSON(jsonState.sinkTank);
			if(jsonState.desiredPressureHolder !== undefined) this.desiredPressureHolder.setStateJSON(jsonState.desiredPressureHolder);
	}
	getStateJSON(){
		return {
			storageTank : this.storageTank.getStateJSON(),
			sinkTank : this.sinkTank.getStateJSON(),
			desiredPressureHolder : this.desiredPressureHolder.getStateJSON()
		}
	}
	getAvailableFillCount() {
		return this.availableFillCount;
	}
	setAvailableFillCount(availableFillCount = 0) {
		this.availableFillCount = availableFillCount;
	}
	getFillUntilPressureBar() {
		return this.desiredPressureHolder.getPressure_Bar();
	}
	initResults() {
		this.remnantStorageTank.applyTankModel(this.storageTank);
		this.resultSinkTank.applyTankModel(this.sinkTank);
		this.setAvailableFillCount(0);
	}
	calculateTopoff(){
		this.initResults();
		if(!this.checkTopoffable()) return;
		this.topoff();
	}
	checkTopoffable() {
		if(this.getFillUntilPressureBar() < this.sinkTank.getPressure_Bar()) return false;
		if(this.storageTank.getPressure_Bar() < this.sinkTank.getPressure_Bar()) return false;
		return true;
	}
	topoff(){
		if(this.sinkTank.getVolume_dm3() === 0){
			this.fireCalculated();
			return;
		}
		const fillUntilPressureBar = this.getFillUntilPressureBar();
		const addingGasAmount4TopoffUntilPressure = this.getAddingGasAmount4TopoffUntilPressure(fillUntilPressureBar);
		const storageGasAmount = this.remnantStorageTank.getAmount_mol();

		const storageTankWithLowestPressure = this.remnantStorageTank.clone();
		storageTankWithLowestPressure.setPressure_Bar(fillUntilPressureBar);
		const gasAmountInSourceTankWithLowestPressure = storageTankWithLowestPressure.getAmount_mol();
		if(storageGasAmount > addingGasAmount4TopoffUntilPressure + gasAmountInSourceTankWithLowestPressure){

			const availableFillCount = Math.floor((storageGasAmount - gasAmountInSourceTankWithLowestPressure) / addingGasAmount4TopoffUntilPressure);
			this.setAvailableFillCount(availableFillCount);

			this.remnantStorageTank.dropGas(addingGasAmount4TopoffUntilPressure);
			this.resultSinkTank.addGas(this.remnantStorageTank, addingGasAmount4TopoffUntilPressure);

			this.fireCalculated();
			return;
		}
		this.equalizePressure();

	}
	getAddingGasAmount4TopoffUntilPressure(targetPressure){
		let addAmount = 0;
		let addAmountStep = 20;
		const precisionsValue = TopoffModel.PRECISION_VALUE;

		let tm = this.sinkTank.clone();
		const supplyGas = this.storageTank;

		let calculatedValueP = tm.getPressure_Bar();
		let direction = 1;
		let newDelta = targetPressure - calculatedValueP;
		let previosDelta = newDelta;
		let addNextPointer = 0;
		while(Math.abs(newDelta) > precisionsValue){
			addNextPointer = addAmount + addAmountStep * direction;
			if(addNextPointer < 0) addNextPointer=0;
			try{
				tm = TankModel.addGasAmount(this.sinkTank, supplyGas, addNextPointer);
			}catch( e ){
				addAmountStep = addAmountStep / 2;
				continue;
			}
			calculatedValueP = tm.getPressure_Bar();
			newDelta = targetPressure - calculatedValueP;
			if(Math.abs(newDelta) < Math.abs(previosDelta)){
				// der Punkt ist näher
				addAmount = addNextPointer;
				if(Math.sign(newDelta) !== Math.sign(previosDelta)){
					direction = direction * -1;
					addAmountStep = addAmountStep / 2;
				}
				previosDelta = newDelta;
			}else{
				// der Punkt ist weiter vom Ziel entfernt
				if(Math.sign(newDelta) !== Math.sign(previosDelta)){
					addAmountStep = addAmountStep / 2;
				}else{
					// change direction
					direction = direction * -1;
				}
			}
		}
		return addNextPointer;
	}
	equalizePressure() {
		let overflowAmount = 0;
		let overflowAmountStep = 20;
		let precisionsValue = TopoffModel.PRECISION_VALUE;

		const pressureInStorage  = this.remnantStorageTank.getPressure_Bar();
		let direction = 1;
		const pressureInSink = this.resultSinkTank.getPressure_Bar();
		let newDelta = pressureInStorage - pressureInSink;

		let previosDelta = newDelta;
		let overflowNextPointer = 0;

		const nvRemnantStorageTank = this.storageTank.clone();
		const nvResultSinkTank = this.sinkTank.clone();

		while(Math.abs(newDelta) > precisionsValue){
			overflowNextPointer = overflowAmount + overflowAmountStep * direction;
			if(overflowNextPointer < 0) overflowNextPointer=0;
			try{
				nvRemnantStorageTank.applyTankModel(this.storageTank);
				nvResultSinkTank.applyTankModel(this.sinkTank);
				nvRemnantStorageTank.dropGas(overflowNextPointer);
				nvResultSinkTank.addGas(this.storageTank, overflowNextPointer);
			}catch(e){
				console.warn(e);
				overflowAmountStep = overflowAmountStep / 2;
				continue;
			}
			newDelta = nvRemnantStorageTank.getPressure_Bar() - nvResultSinkTank.getPressure_Bar();
			if(Math.abs(newDelta) < Math.abs(previosDelta)){
				// der Punkt ist näher
				overflowAmount = overflowNextPointer;
				if(Math.sign(newDelta) !== Math.sign(previosDelta)){
					direction = direction * -1;
					overflowAmountStep = overflowAmountStep / 2;
				}
				previosDelta = newDelta;
			}else{
				// der Punkt ist weiter vom Ziel entfernt
				if(Math.sign(newDelta) !== Math.sign(previosDelta)){
					overflowAmountStep = overflowAmountStep / 2;
				}else{
					// change direction
					direction=direction * -1;
				}
			}
		}
		this.remnantStorageTank.applyTankModel(nvRemnantStorageTank);
		this.resultSinkTank.applyTankModel(nvResultSinkTank);
		this.fireCalculated();
	}

}