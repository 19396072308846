import React from 'react';
import i18next from 'i18next';
import setOFTankVolumes from '../wetnotes/SetOfTankVolumes'
import { TankModel, WetNotes, WetNotesSettings } from '../wetnotes/wetnotes';
import { CSS_CLASSNAME_PANEL, TankVolumeValue } from '../WTNCommons';
import PickUpNominalWorkingPressureBar from '../PickUpNominalWorkingPressureBar';

export class TankVolumesConfigurator extends React.Component{
  constructor(props){
    super(props);
		this.state = { showRestoreDefaults: true };
		this.addingTankModel = new TankModel(21, 0).setName('add.volume.tank');
  }

	propertyChange(propertyChangeEvent){
    this.setState({lastChangeEvent : propertyChangeEvent}); 
  }

  componentDidMount() {
		setOFTankVolumes.addPropertyChangeListener(this);
		this.addingTankModel.addPropertyChangeListener(this);
		WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS]);
  }
	
  componentWillUnmount() {
		setOFTankVolumes.removePropertyChangeListener(this);
		this.addingTankModel.removePropertyChangeListener(this);
		WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS]);
  }
	
	render() {
		
  	const volumeCapacity = WetNotes.SETTINGS.isMetricSystem()?i18next.t('Volume'):i18next.t('capacity');
  	const labelTextVolume = volumeCapacity + " (" + WetNotes.MEASSURES.LiterCuft.getLabel() + ")";
    return (
      <div className={this.props.className}>
        <h5>{i18next.t('tank.volume.set.configure')}</h5>
        <ListOfTankVolumes tankModel = {this.addingTankModel}/>
				<div className="mb-2">{labelTextVolume} 
				<PickUpNominalWorkingPressureBar tankModel={ this.addingTankModel } 
            setNominalWorkingPressureBar={(newValue) => {
              this.addingTankModel.setNominalWorkingPressureBar(newValue)
            }
              }/>
				</div>
				<AddVolumeComponent tankModel = {this.addingTankModel}>
					<div className="h5">{i18next.t('add.new.tank.volume')}</div>
					<div className="h5">
						<TankVolumeValue tankModel = {this.addingTankModel}/>
					</div>
					<button onClick={ () => setOFTankVolumes.addTankVolume_dm3(this.addingTankModel.getVolume_dm3())}	
							className="btn btn-secondary">{i18next.t('Add')}</button> <button 
							onClick={ () => setOFTankVolumes.resetDefault()}	
							className="ml-2 btn btn-light">{i18next.t('restore.standard')}</button>
				</AddVolumeComponent>
      </div>

    )
  }
}
function AddVolumeComponent(props){
	return (
		<div className={CSS_CLASSNAME_PANEL}>
			{props.children}
		</div>
	)
}
function ListOfTankVolumes({tankModel}){
	return(
		<table className = "table table-sm table-striped border-bottom">
			<tbody>
				{
						setOFTankVolumes.getSetOfTankVolumes_dm3().map((item, index) => (
						<RowOfTankVolume volume = {item} tankModel = {tankModel} key={index}/>
						))
				}
			</tbody>
		</table>
	)
}
function RowOfTankVolume({volume, tankModel}){
	return (
		<tr>
			<td>
			{WetNotes.formatVolume(WetNotes.MEASSURES.model2ViewVolumeOrCapacity(
							tankModel,
							volume))}
			</td>
				<td className="text-right">
					<button type="button" 
							onClick = { () => {
								if(setOFTankVolumes.removeTankVolume_dm3(volume))	tankModel.setVolume_dm3(volume);
								}
							}
							className="btn btn-sm btn-outline-danger">{i18next.t('Delete')}</button>
				</td>
		</tr>
	)
}

