
import { WetModel, TankModel, WetNotesSettings, WetNotes, GasModel } from "../wetnotes/wetnotes";

const deltaGasRelevantSettings = [WetNotesSettings.PROPERTY_REAL_GAS_MODEL, WetNotesSettings.PROPERTY_TEMPERATURE];
const gasRelevantProperties = [GasModel.PROPERTY_PRESSURE, GasModel.PROPERTY_HE, GasModel.PROPERTY_O2, 
	TankModel.PROPERTY_VOLUME, TankModel.PROPERTY_NOMINAL_WORKING_PRESSURE ];

export class DeltaGasModel extends WetModel{
	static get PROPERTY_DESIRED_PRESSURE() { return "prop_desired_pressure_topoff" };
	static get PROPERTY_DELTAGAS_CALCULATED() { return "prop_deltagas_calculation_completed" };

	static get OXYGEN() { return new GasModel(100, 0, 0) };
	static get HELIUM() { return new GasModel(0, 100, 0) };
	static get NITROGEN() { return new GasModel(0, 0, 0) };
	
	constructor(){
		super();
		this.tankIs = new TankModel('Scuba tank', 21, 35, 68, 24);
		this.tankDesired = new TankModel('Desired gas', 21, 35, 200, 24);
		this.deltaGas = this.tankIs.clone();
		this.deltaGas.setName('Setup blending mixture');
		this.initResults();
		this.installListeners();
		this.calculateDeltaGas(); 
	}
	installListeners(){
		this.tankIs.addPropertyChangeListener(this, gasRelevantProperties);
		this.tankDesired.addPropertyChangeListener(this, gasRelevantProperties);
		WetNotes.SETTINGS.addPropertyChangeListener(this, deltaGasRelevantSettings);
	}
	setStateJSON(jsonState = {}){
		if(jsonState.tankIs !== undefined) this.tankIs.setStateJSON(jsonState.tankIs);
		if(jsonState.tankDesired !== undefined) this.tankDesired.setStateJSON(jsonState.tankDesired);
	}
	getStateJSON(){
		return {
			tankIs : this.tankIs.getStateJSON(),
			tankDesired : this.tankDesired.getStateJSON()
		}
	}
	initResults() {
		this.tankIsCalculated = this.tankIs.clone();
		this.deltaGas.applyTankModel(this.tankIs);
		this.deltaGas.setPressure_Pa(0);
	}
	calculateDeltaGas(){
		this.initResults();
		if(this.tankIs.getVolume_dm3() === 0){
			this.fireCalculated();
			return;
		}
		this.precheckGasAmountAndDropIfNeeded();
		this.calcDelta();
	}
	fireCalculated(){
		this.propertyChangeSupport.firePropertyChange(DeltaGasModel.PROPERTY_DELTAGAS_CALCULATED, false, true);
	}
	propertyChange(propertyChangeEvent) {
		let doCalculate = true;
		const source = propertyChangeEvent.getSource();
		if(source === this.tankIs){
			if(TankModel.PROPERTY_VOLUME  === propertyChangeEvent.getPropertyName()){
				this.tankDesired.setVolume_dm3(this.tankIs.getVolume_dm3());
			}
		}
		if(doCalculate) this.calculateDeltaGas();
	}
	precheckGasAmountAndDropIfNeeded() {
		if( this.tankIsCalculated.getPressure_Pa() > this.tankDesired.getPressure_Pa()){
			this.tankIsCalculated.setPressure_Pa(this.tankDesired.getPressure_Pa());
		}
		// compare O2 amount
		const o2AmountDesired = this.tankDesired.getAmount_mol() * this.tankDesired.getFiO2();
		const o2AmountTankIsCalculated = this.tankIsCalculated.getAmount_mol() * this.tankIsCalculated.getFiO2();
		const o2AmountToDrop = o2AmountTankIsCalculated - o2AmountDesired;
		// compare He amount
		const heAmountDesired = this.tankDesired.getAmount_mol() * this.tankDesired.getFiHe();
		const heAmountTankIsCalculated = this.tankIsCalculated.getAmount_mol() * this.tankIsCalculated.getFiHe();
		const heAmountToDrop = heAmountTankIsCalculated - heAmountDesired;
		// compare N2 amount
		const n2AmountDesired = this.tankDesired.getAmount_mol() * this.tankDesired.getFiN2();
		const n2AmountTankIsCalculated = this.tankIsCalculated.getAmount_mol() * this.tankIsCalculated.getFiN2();
		const n2AmountToDrop = n2AmountTankIsCalculated - n2AmountDesired;

		let dropAmount = 0;
		let o2Drop = 0, n2Drop = 0, heDrop = 0;
		if(o2AmountToDrop > 0){
			o2Drop = o2AmountToDrop / this.tankIsCalculated.getFiO2();
		}
		if(heAmountToDrop > 0){
			heDrop = heAmountToDrop / this.tankIsCalculated.getFiHe();
		}
		if(n2AmountToDrop > 0){
			n2Drop = n2AmountToDrop / this.tankIsCalculated.getFiN2();
		}
		if(o2Drop > dropAmount) dropAmount = o2Drop;
		if(n2Drop > dropAmount) dropAmount = n2Drop;
		if(heDrop > dropAmount) dropAmount = heDrop;
		if(dropAmount > 0) this.tankIsCalculated.dropGas(dropAmount);
	}
	calcDelta() {
		const amountOrigin = this.tankIsCalculated.getAmount_mol();
		const amountDesired = this.tankDesired.getAmount_mol();
		
		const deltaO2 = amountDesired * this.tankDesired.getFiO2() - amountOrigin * this.tankIsCalculated.getFiO2();
		if(deltaO2 > 0) this.deltaGas.addGas(DeltaGasModel.OXYGEN, deltaO2);

		const deltaHe = amountDesired * this.tankDesired.getFiHe() - amountOrigin * this.tankIsCalculated.getFiHe();
		if(deltaHe > 0) this.deltaGas.addGas(DeltaGasModel.HELIUM, deltaHe);

		const deltaN2 = amountDesired * this.tankDesired.getFiN2() - amountOrigin * this.tankIsCalculated.getFiN2();
		if(deltaN2 > 0) this.deltaGas.addGas(DeltaGasModel.NITROGEN, deltaN2);

		this.fireCalculated();
	}
}