import React from 'react'
import i18next from 'i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
import MdCopy from 'react-ionicons/lib/MdCopy'
import { COLOR_WETNOTES } from '../WTNCommons'

// const getLocationWithoutParameters = () => {
// 	const currentUrl = new URL(window.location.href);
// 	return currentUrl.origin + currentUrl.pathname;
// }
// const linkToApp = getLocationWithoutParameters();
const linkToApp = "https://blender-toolkit.t-provider.de"

const copyText = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.info(i18next.t('copied.to.clipboard'), {
      position: "top-right",
      autoClose: 3600,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  } catch (err) {
    console.error('error during copy:', err);
  }
}
  
  
const QRCodeLinkToAppDialog = (props) => {
	const { isOpen, onClose } = props
	return(
		<>
		<Modal centered={true} isOpen={isOpen} toggle={ () => onClose() }>
      <ModalHeader className="bg-info text-white" toggle={ () => onClose()}>
        {i18next.t('link.to.app')}
      </ModalHeader>
      <ModalBody className="backgroundColor">
        {/* <div className="lead text-center">{efn}</div> */}
        <div className="text-center h5 editableValue" onClick={() => copyText(linkToApp)} >
        {linkToApp} <MdCopy color={ COLOR_WETNOTES} fontSize="1.5em"></MdCopy>
        </div>
        <div className="text-center" >
            <QRCode className="m-4" value={linkToApp}/>
          
          <div className="lead">{i18next.t('use.cam.to.open')}</div>
          <div className="lead">{i18next.t('add.to.home.screen')}</div>
        </div>
      </ModalBody>
      <ModalFooter  className="backgroundColor">
        <button type="button" className="btn btn-light btn-block" onClick = { (e) => onClose() }>{i18next.t('close')}</button>
      </ModalFooter>
	  </Modal>
	</>
	)
}

export default QRCodeLinkToAppDialog;