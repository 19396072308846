import { WetNotes, WetNotesSettings } from "../wetnotes/wetnotes";
import i18next from 'i18next'
import React from 'react';
import { UnitsLiterCui, firefoxWorkaroundDelyinMsToIgnoreOnblure, KEY_ENTER, KEY_ESCAPE } from "../WTNCommons";
import { PSCRModel } from "./PSCRModel";

// const topoffRelevantSettings = [WetNotesSettings.PROPERTY_MAX_PPO2]

const VOLUME_DM3_MIN = 4;
const VOLUME_DM3_MAX = 10;
const VOLUME_RATIO_MIN = 5;
const VOLUME_RATIO_MAX = 15;
const DUMP_PERCENT_OF_INJECTED_GAS_MIN = 0;
const DUMP_PERCENT_OF_INJECTED_GAS_MAX = 15;

export class VolumeComponent extends React.Component{
	constructor(props){
		super(props);
		this.pscrModel = props.pscrModel;
		this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
		this.state = {
			editMode: false, 
			editValue: WetNotes.formatVolume(this.getModelValue4View())
		};
	}
	propertyChange(propertyChangeEvent){
		 this.setState({
			lastChangeEvent : propertyChangeEvent, 
			editValue: WetNotes.formatVolume(this.getModelValue4View())
		}); 
	}

	componentDidMount() {
		this.pscrModel.addPropertyChangeListener(this, [PSCRModel.PROPERTY_VOLUME]);
		WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	
	componentWillUnmount() {
		this.pscrModel.removePropertyChangeListener(this, [PSCRModel.PROPERTY_VOLUME]);
		WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	handleOnFocus(e){
		e.target.select();
	}
	startEditMode(){
		this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
	}
	handleOnBlur(e){
		// workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
		if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
		// end of workaround

		this.applyEditValue(e);
		this.editCompleted();
	}
	applyEditValue(e){
		if (this.isEditorValueValid()){
			const numberValue = Number.parseFloat(this.state.editValue);
			let newVolume = WetNotes.MEASSURES.Volume.getMetricOfCurrent(numberValue);
			if(newVolume > VOLUME_DM3_MAX) newVolume = VOLUME_DM3_MAX;
			if(newVolume < VOLUME_DM3_MIN) newVolume = VOLUME_DM3_MIN;
			this.pscrModel.setVolume_dm3(newVolume);
		}
	}
	editCompleted(){
		this.setState({editMode : false, editValue: WetNotes.formatVolume(this.getModelValue4View())});
	}
	handleKeyUp(e){
		switch(e.keyCode){
			case KEY_ENTER:
				this.applyEditValue(e);
				this.editCompleted();
				break;
			case KEY_ESCAPE:
					this.editCompleted();
				break;
			default:
		}
	}
	handleChange(e){
		this.setState({ editValue:  e.target.value});
	}
	handleRangeSet(e){
		const newValue = WetNotes.MEASSURES.Volume.getMetricOfCurrent(Number.parseFloat(e.target.value));
		this.pscrModel.setVolume_dm3(newValue);
	}
	isEditorValueValid(){
		const editValue = this.state.editValue;
		if(this.state.editValue === "") return false;
		const numberValue = Number.parseFloat(editValue);
		if(numberValue < 0) return false;
		return true;
	}
	getModelValue4View(){
		return WetNotes.MEASSURES.Volume.getAdjustedOfMetric(this.pscrModel.getVolume_dm3());
	}
	render(){
		const classNameEditableValueViewMode = this.readOnly?"readonlyValue":"editableValue";
		const viewModelValue = this.getModelValue4View();
		const maxValue = WetNotes.MEASSURES.Volume.getAdjustedOfMetric(VOLUME_DM3_MAX);
		const minValue = WetNotes.MEASSURES.Volume.getAdjustedOfMetric(VOLUME_DM3_MIN);
		const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
		return (
			<div className={this.props.className}>
				{(this.readOnly || !this.state.editMode) &&
					<div className = {classNameEditableValueViewMode} onClick={ (e)=> this.startEditMode() }>{WetNotes.formatVolume(viewModelValue)}</div>
				}
				{(!this.readOnly && this.state.editMode) &&
					<div>
					<input className={"form-control "+ classNameAddOn}  required={true} type="number" autoFocus
								onFocus={(e) => this.handleOnFocus(e)}
								onChange={(e) => this.handleChange(e) } 
								onKeyUp={(e) => this.handleKeyUp(e)}
								onBlur={(e) => this.handleOnBlur(e)}
								value={this.state.editValue}>
							</input>
					</div>
				}
				{!this.readOnly && WetNotes.SETTINGS.isDisplaySliders() &&
					<div className="mt-2 mb-2">
						<input type="range" min={ minValue } max={ maxValue } step="0.1" 
							value={viewModelValue} onChange={(e) => this.handleRangeSet(e)} className="form-control-range"/>
					</div>
				}
			</div>
		)
	}
}
export function VolumeLabel(props){
  const labelText = i18next.t('pscr.volume');
  return (
        <label className="control-label">{labelText} (<UnitsLiterCui></UnitsLiterCui>)</label>
  )
}
export class VolumeRatioComponent extends React.Component{
	constructor(props){
		super(props);
		this.pscrModel = props.pscrModel;
		this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
		this.state = {
			editMode: false, 
			editValue: WetNotes.formatVolume(this.getModelValue4View())
		};
	}
	propertyChange(propertyChangeEvent){
		 this.setState({
			lastChangeEvent : propertyChangeEvent, 
			editValue: WetNotes.formatPercent(this.getModelValue4View())
		}); 
	}

	componentDidMount() {
		this.pscrModel.addPropertyChangeListener(this, [PSCRModel.PROPERTY_DUMP_RATIO]);
		WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	
	componentWillUnmount() {
		this.pscrModel.removePropertyChangeListener(this, [PSCRModel.PROPERTY_DUMP_RATIO]);
		WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	handleOnFocus(e){
		e.target.select();
	}
	startEditMode(){
		this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
	}
	handleOnBlur(e){
		// workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
		if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
		// end of workaround

		this.applyEditValue(e);
		this.editCompleted();
	}
	applyEditValue(e){
		if (this.isEditorValueValid()){
			const numberValue = Number.parseFloat(this.state.editValue);
			// let newVolume = WetNotes.MEASSURES.Volume.getMetricOfCurrent(numberValue);
			let newValue = numberValue;
			if(newValue > VOLUME_RATIO_MAX) newValue = VOLUME_RATIO_MAX;
			if(newValue < VOLUME_RATIO_MIN) newValue = VOLUME_RATIO_MIN;
			this.pscrModel.setVolumeRatio(newValue);
		}
	}
	editCompleted(){
		this.setState({editMode : false, editValue: WetNotes.formatPercent(this.getModelValue4View())});
	}
	handleKeyUp(e){
		switch(e.keyCode){
			case KEY_ENTER:
				this.applyEditValue(e);
				this.editCompleted();
				break;
			case KEY_ESCAPE:
					this.editCompleted();
				break;
			default:
		}
	}
	handleChange(e){
		this.setState({ editValue:  e.target.value});
	}
	handleRangeSet(e){
		const newValue = Number.parseFloat(e.target.value);
		this.pscrModel.setVolumeRatio(newValue);
	}
	isEditorValueValid(){
		const editValue = this.state.editValue;
		if(this.state.editValue === "") return false;
		const numberValue = Number.parseFloat(editValue);
		if(numberValue < 0) return false;
		return true;
	}
	getModelValue4View(){
		return this.pscrModel.getVolumeRatio();
	}
	render(){
		const classNameEditableValueViewMode = this.readOnly?"readonlyValue":"editableValue";
		const viewModelValue = this.getModelValue4View();
		const maxValue = VOLUME_RATIO_MAX;
		const minValue = VOLUME_RATIO_MIN;
		const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
		return (
			<div className={this.props.className}>
				{(this.readOnly || !this.state.editMode) &&
					<div className = {classNameEditableValueViewMode} onClick={ (e)=> this.startEditMode() }>{WetNotes.formatPercent(viewModelValue)}</div>
				}
				{(!this.readOnly && this.state.editMode) &&
					<div>
					<input className={"form-control "+ classNameAddOn}  required={true} type="number" autoFocus
								onFocus={(e) => this.handleOnFocus(e)}
								onChange={(e) => this.handleChange(e) } 
								onKeyUp={(e) => this.handleKeyUp(e)}
								onBlur={(e) => this.handleOnBlur(e)}
								value={this.state.editValue}>
							</input>
					</div>
				}
				{!this.readOnly && WetNotes.SETTINGS.isDisplaySliders() &&
					<div className="mt-2 mb-2">
						<input type="range" min={ minValue } max={ maxValue } step="0.1" 
							value={viewModelValue} onChange={(e) => this.handleRangeSet(e)} className="form-control-range"/>
					</div>
				}
			</div>
		)
	}
}
export function VolumeRatioLabel(props){
  const labelText = i18next.t('pscr.volumeratio');
  return (
        <label className="control-label">{labelText}  (1:x)</label>
  )
}
export class DumpPercentOfInjectedGasComponent extends React.Component{
	constructor(props){
		super(props);
		this.pscrModel = props.pscrModel;
		this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
		this.state = {
			editMode: false, 
			editValue: WetNotes.formatPercent(this.getModelValue4View())
		};
	}
	propertyChange(propertyChangeEvent){
		 this.setState({
			lastChangeEvent : propertyChangeEvent, 
			editValue: WetNotes.formatPercent(this.getModelValue4View())
		}); 
	}

	componentDidMount() {
		this.pscrModel.addPropertyChangeListener(this, [PSCRModel.PROPERTY_DUMP_RATIO_OF_INJECTED_GAS]);
		WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	
	componentWillUnmount() {
		this.pscrModel.removePropertyChangeListener(this, [PSCRModel.PROPERTY_DUMP_RATIO_OF_INJECTED_GAS]);
		WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	handleOnFocus(e){
		e.target.select();
	}
	startEditMode(){
		this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
	}
	handleOnBlur(e){
		// workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
		if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
		// end of workaround

		this.applyEditValue(e);
		this.editCompleted();
	}
	applyEditValue(e){
		if (this.isEditorValueValid()){
			const numberValue = Number.parseFloat(this.state.editValue);
			let newValue = numberValue;
			if(newValue > DUMP_PERCENT_OF_INJECTED_GAS_MAX) newValue = DUMP_PERCENT_OF_INJECTED_GAS_MAX;
			if(newValue < DUMP_PERCENT_OF_INJECTED_GAS_MIN) newValue = DUMP_PERCENT_OF_INJECTED_GAS_MIN;
			this.pscrModel.setDumpPercentOfInjectedGas(newValue);
		}
	}
	editCompleted(){
		this.setState({editMode : false, editValue: WetNotes.formatPercent(this.getModelValue4View())});
	}
	handleKeyUp(e){
		switch(e.keyCode){
			case KEY_ENTER:
				this.applyEditValue(e);
				this.editCompleted();
				break;
			case KEY_ESCAPE:
					this.editCompleted();
				break;
			default:
		}
	}
	handleChange(e){
		this.setState({ editValue:  e.target.value});
	}
	handleRangeSet(e){
		// const newValue = WetNotes.MEASSURES.Volume.getMetricOfCurrent(Number.parseFloat(e.target.value));
		const newValue = Number.parseFloat(e.target.value);
		this.pscrModel.setDumpPercentOfInjectedGas(newValue);
	}
	isEditorValueValid(){
		const editValue = this.state.editValue;
		if(this.state.editValue === "") return false;
		const numberValue = Number.parseFloat(editValue);
		if(numberValue < 0) return false;
		return true;
	}
	getModelValue4View(){
		return this.pscrModel.getDumpPercentOfInjectedGas();
	}
	render(){
		const classNameEditableValueViewMode = this.readOnly?"readonlyValue":"editableValue";
		const viewModelValue = this.getModelValue4View();
		// const maxValue = WetNotes.MEASSURES.Volume.getAdjustedOfMetric(DUMP_RATIO_MAX);
		// const minValue = WetNotes.MEASSURES.Volume.getAdjustedOfMetric(DUMP_RATIO_MIN);
		const maxValue = DUMP_PERCENT_OF_INJECTED_GAS_MAX;
		const minValue = DUMP_PERCENT_OF_INJECTED_GAS_MIN;
		const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
		return (
			<div className={this.props.className}>
				{(this.readOnly || !this.state.editMode) &&
					<div className = {classNameEditableValueViewMode} onClick={ (e)=> this.startEditMode() }>{WetNotes.formatPercent(viewModelValue)}</div>
				}
				{(!this.readOnly && this.state.editMode) &&
					<div>
					<input className={"form-control "+ classNameAddOn}  required={true} type="number" autoFocus
								onFocus={(e) => this.handleOnFocus(e)}
								onChange={(e) => this.handleChange(e) } 
								onKeyUp={(e) => this.handleKeyUp(e)}
								onBlur={(e) => this.handleOnBlur(e)}
								value={this.state.editValue}>
							</input>
					</div>
				}
				{!this.readOnly && WetNotes.SETTINGS.isDisplaySliders() &&
					<div className="mt-2 mb-2">
						<input type="range" min={ minValue } max={ maxValue } step="1" 
							value={viewModelValue} onChange={(e) => this.handleRangeSet(e)} className="form-control-range"/>
					</div>
				}
			</div>
		)
	}
}
export function DumpPercentOfInjectedGasLabel(props){
  const labelText = i18next.t('pscr.dumpratio.of.injected');
  return (
        <label className="control-label">{labelText}  (%)</label>
  )
}

