import i18next from 'i18next'
import React from 'react';

import { WetNotes, WetNotesSettings, TankModel } from '../wetnotes/wetnotes';
import IosThermometerOutline from 'react-ionicons/lib/IosThermometerOutline'
import { firefoxWorkaroundDelyinMsToIgnoreOnblure, KEY_ENTER, KEY_ESCAPE, UnitsTemperature, GasComponent, pressureToDisplay } from '../WTNCommons';
import { FormRowGroup } from '../SharedOptions';
import { PhysicsCore } from './PhysicsCore';

const relevantTankProperties = [TankModel.PROPERTY_AMOUNT, TankModel.PROPERTY_HE, TankModel.PROPERTY_O2];
const relevantSettings = [ WetNotesSettings.PROPERTY_SHOW_DETAILS, WetNotesSettings.PROPERTY_UNITS,
	WetNotesSettings.PROPERTY_TEMPERATURE ];

const TEMPERATURE_C_MIN = WetNotes.SETTINGS.temperatureK_min - PhysicsCore.KELVIN_DELTA_CELSIUS;
const TEMPERATURE_C_MAX = WetNotes.SETTINGS.temperatureK_max - PhysicsCore.KELVIN_DELTA_CELSIUS;

export class IsochoreComponent extends React.Component {
	constructor(props){
		super(props);
		this.tankModel = props.tankModel;
		this.tankModel.setName("Scuba tank");
		this.id = props.id !== undefined?props.id:'default_isochore_component';
		this.state = this.loadState();
	}
	componentDidMount() {
		this.tankModel.addPropertyChangeListener(this, relevantTankProperties);
		WetNotes.SETTINGS.addPropertyChangeListener(this, relevantSettings);
	}
	componentWillUnmount() {
		this.tankModel.removePropertyChangeListener(this, relevantTankProperties);
		WetNotes.SETTINGS.removePropertyChangeListener(this, relevantSettings);
		this.saveState();
	}
	propertyChange(propertyChangeEvent){
    this.setState({lastChangeEvent : propertyChangeEvent}); 
	}
	loadState(){
		const loadedState = localStorage.getItem(this.id);
		if(loadedState === null) return { temperatureK : WetNotes.SETTINGS.getTemperatureK()};
		return JSON.parse(loadedState);
	}
	setTemperatureK(temperatureK){
		this.setState({temperatureK: temperatureK});
		this.saveState();
	}
	saveState(){
		const persistentState = { temperatureK : this.state.temperatureK };
		localStorage.setItem(this.id, JSON.stringify(persistentState));
	}
	isHeated(){
		return this.state.temperatureK >= WetNotes.SETTINGS.getTemperatureK();
	}
	render() {
		const pressurePa=TankModel.computePressure_Pa(this.tankModel, this.tankModel.getAmount_mol(), this.state.temperatureK);
		const pressure = pressurePa / PhysicsCore.Pa2Bar;
		const iconsColor = this.isHeated()?"red":"#007bff";
		// WetNotes.MEASSURES.Pressure.getAdjustedOfMetric(this.gasModel.getPressure_Bar())
		return (
			<div>
				<h5>{i18next.t('isochore.reference.data')} </h5>
				<GasComponent tankModel={this.tankModel} 
						volumeReadOnly={true} pressureReadOnly={true} slimFit fractionReadOnly={true}>
					</GasComponent>
				<FormRowGroup>
					<TemperatureLabel></TemperatureLabel>
					<TemperatureControl temperatureK={WetNotes.SETTINGS.getTemperatureK()} 
						readOnly = {true} onSetTemperatureK={ (temperatureK ) => this.setTemperatureK(temperatureK)}>
					</TemperatureControl>
				</FormRowGroup>

				<h5>{i18next.t('isochore.actual.value')}</h5>
				<FormRowGroup>
					<TemperatureLabel iconColor={iconsColor}></TemperatureLabel>
					<TemperatureControl temperatureK={this.state.temperatureK} 
						onSetTemperatureK={ (temperatureK ) => this.setTemperatureK(temperatureK)}>
					</TemperatureControl>
				</FormRowGroup>
				<ActualPressure heated={this.isHeated()} pressure={pressure}></ActualPressure>
			</div>
		)
	}
}
function ActualPressure(props){
	const pressure = pressureToDisplay(props.pressure);
	const classNameAddOn = props.heated?"text-danger":"text-primary";
	return(
		<FormRowGroup>
			<label className="control-label">{i18next.t('Pressure') + " (" + WetNotes.MEASSURES.Pressure.getLabel() + ")"}</label>
			<div className={"form-control font-weight-bold " + classNameAddOn}>{pressure}</div>
		</FormRowGroup>
	)
}
function TemperatureLabel(props){
	return (
		<label className="control-label"><IosThermometerOutline color={props.iconColor}></IosThermometerOutline> {i18next.t('Temperature')} (<UnitsTemperature></UnitsTemperature>)</label>
	)
}
class TemperatureControl extends React.Component{
	constructor(props){
		super(props);
		this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
		this.temperatureK = props.temperatureK;
		this.state = {
			editMode: false, 
			editValue: WetNotes.formatTemperatur(this.getModelValue4View())
		};
	}
	componentDidMount() {
		WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	
	componentWillUnmount() {
	  WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	setTemperatureK(temperatureK){
		this.temperatureK = temperatureK;
		this.props.onSetTemperatureK(this.temperatureK);
	}
	setTemperatureC(temperatureC){
		this.setTemperatureK(temperatureC + PhysicsCore.KELVIN_DELTA_CELSIUS);
	}

	propertyChange(propertyChangeEvent){
		 this.setState({
			lastChangeEvent : propertyChangeEvent, 
			editValue: WetNotes.formatTemperatur(this.getModelValue4View())
		}); 
	}
	handleOnFocus(e){
		e.target.select();
	}
	startEditMode(){
		this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
	}
	handleOnBlur(e){
		// workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
		if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
		// end of workaround
		this.applyEditValue(e);
		this.editCompleted();
	}
	applyEditValue(e){
		if (this.isEditorValueValid()){
			const numberValue = Number.parseFloat(this.state.editValue);
			let newNumberValue = WetNotes.MEASSURES.Temperatur.getMetricOfCurrent(numberValue);
			if(newNumberValue > TEMPERATURE_C_MAX) 
				newNumberValue = TEMPERATURE_C_MAX;
			if(newNumberValue < TEMPERATURE_C_MIN) 
				newNumberValue = TEMPERATURE_C_MIN;
			this.setTemperatureC(newNumberValue);
		}
	}
	editCompleted(){
		this.setState({editMode : false, editValue: WetNotes.formatTemperatur(this.getModelValue4View())});
	}
	handleKeyUp(e){
		switch(e.keyCode){
			case KEY_ENTER:
				this.applyEditValue(e);
				this.editCompleted();
				break;
			case KEY_ESCAPE:
					this.editCompleted();
				break;
			default:
		}
	}
	handleChange(e){
		this.setState({ editValue:  e.target.value});
	}
	handleRangeSet(e){
		const newValue = WetNotes.MEASSURES.Temperatur.getMetricOfCurrent(Number.parseFloat(e.target.value));
		this.setState({editValue : newValue});
		this.setTemperatureC(newValue);
	}
	isEditorValueValid(){
		if(this.state.editValue === "") return false;
		return true;
	}
	getModelValue4View(){
		return WetNotes.MEASSURES.Temperatur.getAdjustedOfMetric(this.temperatureK - PhysicsCore.KELVIN_DELTA_CELSIUS);
	}
	render(){
		const classNameEditableValueViewMode = this.readOnly?"readonlyValue":"editableValue";
		const viewModelValue = this.getModelValue4View();
		const maxValue = WetNotes.MEASSURES.Temperatur.getAdjustedOfMetric(TEMPERATURE_C_MAX);
		const minValue = WetNotes.MEASSURES.Temperatur.getAdjustedOfMetric(TEMPERATURE_C_MIN);
		const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
		return (
			<div className={this.props.className}>
				{(this.readOnly || !this.state.editMode) &&
					<div className={classNameEditableValueViewMode + " form-control font-weight-bold"} onClick={ (e)=> this.startEditMode() }>{WetNotes.formatDepth(viewModelValue)}</div>
				}
				{(!this.readOnly && this.state.editMode) &&
					<div>
					<input className={"form-control "+ classNameAddOn}  required={true} type="number" autoFocus
								onFocus={(e) => this.handleOnFocus(e)}
								onChange={(e) => this.handleChange(e) } 
								onKeyUp={(e) => this.handleKeyUp(e)}
								onBlur={(e) => this.handleOnBlur(e)}
								value={this.state.editValue}>
							</input>
					</div>
				}
				{!this.readOnly && WetNotes.SETTINGS.isDisplaySliders() &&
					<div className="mt-2 mb-2">
						<input type="range" min={ minValue } max={ maxValue } step="1" 
							value={viewModelValue} onChange={(e) => this.handleRangeSet(e)} className="form-control-range"/>
					</div>
				}
			</div>
		)
	}
}
