/* (c) Oleg Gurevich, 2019
 * This stuff here is copyrighted under MIT.
 */
import React, { Component, Suspense, useState } from 'react';
//import logo96 from './ic_launcher96x96.png';
//import logo72 from './ic_launcher72x72.png';
import logo48 from './ic_launcher48x48.png';


import i18next from 'i18next';

import './App.css';
//import { PropertyChangeSupport } from './wetnotes/PropertyChangeSupport'

//import { PartialGas, GasCoefficient, RealGas } from './wetnotes/realgas'
import  {WetNotes, WetNotesSettings}  from './wetnotes/wetnotes.js'
import standardGasSet from './wetnotes/StandardGasSet';
import setOfTankVolumes from './wetnotes/SetOfTankVolumes'
import { createAnId } from './WTNCommons'

//import MdMenu from 'react-ionicons/lib/MdMenu'
import { GasBlenderComponent } from './blender/GasBlender';
import { Disclaimer } from './Disclaimer';
import { PrivacyPolicy } from './PrivacyPolicy';
import { GasMOD } from './mod/GasMOD'
import { About } from './About';
import { Topoff } from './topoff/Topoff';
import { DeltaGas } from './deltaGas/DeltaGas';
import { PSCRScreen } from './pscr/PSCRScreen';
import { GlobalSettingsScreen } from './GlobalSettings';
import { CcrDiluentEndMod } from './ccr/CcrDiluentEndMod';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCodeLinkToAppDialog from './components/QRCodeLinkToAppDialog';

const wtn_screens = { 
  blender : 'blender', 
  deltagas : 'deltagas',
  gasmod : 'gasmod',
  pscr : 'pscr',
  ccrDiluentEndMod : 'ccrDiluentEndMod',
  topoff : 'topoff',
  disclaimer : 'discaimer',
  privacypolicy : 'privacypolicy',
  about : 'about',
  commonsettings : 'commonsettings'
};

class App extends Component {
  constructor(props){
    super(props);
    WetNotes.SETTINGS.setMetricSystem(true);
    this.restoreStateOfSettings();
    this.restoreStateOfStandardGasSet();
    this.restoreSetOfTankVolumes();
    this.state = {currentScreen : this.restoreCurrentScreen()};
   // window.standardGasSet = standardGasSet;
   // window.gasModel = new GasModel(12, 0, 0);
  }

  resetSettings(){
    localStorage.clear(); this.reloadWindow();
  }
  reloadWindow(){
    window.location.reload();
  }
  setScreen(screen){
    this.setState({currentScreen : screen});
    localStorage.setItem('currentScreen', screen);
  }
  restoreCurrentScreen(){
    const loadedScreen = localStorage.getItem('currentScreen');
    return loadedScreen===null?wtn_screens.disclaimer:loadedScreen;
  }
  render() {
    let showScreen = null;
    switch(this.state.currentScreen){
      case wtn_screens.disclaimer:
        showScreen = <Disclaimer toClose={ () => this.setScreen(wtn_screens.blender)}></Disclaimer>;
        break;
      case wtn_screens.privacypolicy:
        showScreen = <PrivacyPolicy toClose={ () => this.setScreen(wtn_screens.blender)}></PrivacyPolicy>;
        break;
      case wtn_screens.about:
        showScreen = <About toClose={ () => this.setScreen(wtn_screens.blender)}></About>;
        break;
      case wtn_screens.topoff:
        showScreen = <Topoff></Topoff>;
        break;        
      case wtn_screens.ccrDiluentEndMod:
        showScreen = <CcrDiluentEndMod></CcrDiluentEndMod>;
        break;        
      case wtn_screens.pscr:
        showScreen = <PSCRScreen></PSCRScreen>;
        break;        
      case wtn_screens.deltagas:
        showScreen = <DeltaGas></DeltaGas>;
        break;        
      case wtn_screens.gasmod:
        showScreen = <GasMOD></GasMOD>
        break;
      case wtn_screens.commonsettings:
        showScreen = <GlobalSettingsScreen></GlobalSettingsScreen>
        break;
      default: 
        showScreen = <GasBlenderComponent></GasBlenderComponent>
        break;
    }
      return (
        <div className="App container-fluid bg-light">
          <WTNHeader app = { this }></WTNHeader>
          {showScreen}
        </div>
    )
  }
  restoreStateOfSettings(){
    const loadedState = localStorage.getItem('gas-blender-toolkit-settings');
    if(loadedState === null){
      WetNotes.SETTINGS.setLanguageUI(i18next.language.substring(0,2));
      return;
    } 
    WetNotes.SETTINGS.setStateJSON(JSON.parse(loadedState));
  }
  restoreStateOfStandardGasSet(){
    const loadedState = localStorage.getItem('gas-blender-standard-gas-set');
    if(loadedState === null) return; 
    standardGasSet.setStateJSON(JSON.parse(loadedState));
  }
  restoreSetOfTankVolumes(){
    const loadedState = localStorage.getItem('gas-blender-set-of-tank-volumes');
    if(loadedState === null) return; 
    setOfTankVolumes.setStateJSON(JSON.parse(loadedState));
  }
  componentDidMount() {
    WetNotes.SETTINGS.addPropertyChangeListener(this)
    standardGasSet.addPropertyChangeListener(this)
    setOfTankVolumes.addPropertyChangeListener(this)
  }
  componentWillUnmount() {
    WetNotes.SETTINGS.removePropertyChangeListener(this)
    standardGasSet.removePropertyChangeListener(this)
    setOfTankVolumes.removePropertyChangeListener(this)
  }
  propertyChange(propertyChangeEvent){
    if(propertyChangeEvent.getSource() === WetNotes.SETTINGS){
       this.saveStateOfSettings();
       if(propertyChangeEvent.getPropertyName() === WetNotesSettings.PROPERTY_LANGUAGE_UI){
         /* reload window to render completly */
         /* this.reloadWindow(); */
          this.setState({lastChangeEvent : propertyChangeEvent});
        }
    }
    if(propertyChangeEvent.getSource() === standardGasSet){
       this.saveStateOfStandardGasSet();
    }
    if(propertyChangeEvent.getSource() === setOfTankVolumes){
       this.saveStateOfSetOfTankVolumes();
    }
  }
  
  saveStateOfStandardGasSet(){
    localStorage.setItem('gas-blender-standard-gas-set', 
        JSON.stringify(standardGasSet.getStateJSON()))
  }
  saveStateOfSetOfTankVolumes(){
    localStorage.setItem('gas-blender-set-of-tank-volumes', 
        JSON.stringify(setOfTankVolumes.getStateJSON()))
  }
  saveStateOfSettings(){
    localStorage.setItem('gas-blender-toolkit-settings', JSON.stringify(WetNotes.SETTINGS.getStateJSON()));
  }
}


export default App;
function WTNHeader(props){
  const app = props.app;
  const anId = createAnId();
  const [showShareLinkDialog, setShowShareLinkDialog] = useState(false);

  // activate amnesie :)
  // localStorage.clear();
  return (
    <Suspense fallback="Loading">
      <header className="navbar fixed-top navbar-light bg-light">
          {/* eslint-disable-next-line */}
        <a className="navbar-brand pt-0 pb-0 " href="#">
          <img src={logo48} size="" alt="logo"/>
          <span className="nowrap ml-4 h5">{i18next.t('Gas blender toolkit')}</span>
        </a>
        <div className="btn-group ml-auto">
        <button className="navbar-toggler" type="button" data-toggle="dropdown" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby={anId}>
            <div className="dropdown-item" onClick = {(e) => app.setScreen(wtn_screens.blender)} >
            {i18next.t('Partial pressure blending')}
            </div>
            <div className="dropdown-item" onClick = {(e) => app.setScreen(wtn_screens.deltagas)} >
              {i18next.t('Continuous blending')}
            </div>
            <div className="dropdown-item" onClick = {(e) => app.setScreen(wtn_screens.topoff)} >
              {i18next.t('Topoff from storage')}
            </div>
            <div className="dropdown-item" onClick = { (e) => app.setScreen(wtn_screens.gasmod)} >
            {i18next.t('Scuba gas MOD')}
            </div>
            <div className="dropdown-item" onClick = { (e) => app.setScreen(wtn_screens.ccrDiluentEndMod)} >
            {i18next.t('ccr.diluent.end.title')}
            </div>
            <div className="dropdown-item" onClick = { (e) => app.setScreen(wtn_screens.pscr)} >
            {i18next.t('pscr.menu.item')}
            </div>
            <div className="dropdown-item" onClick = { (e) => app.setScreen(wtn_screens.commonsettings)} >
              {i18next.t('common.settings')}
            </div>
            <div className="dropdown-divider"></div>
            <div className="dropdown-item" onClick = {(e) => app.resetSettings() } >
              {i18next.t('Reset settings to default')}
            </div> 
            <div className="dropdown-divider"></div>
            <div className="dropdown-item" onClick = { (e) => app.setScreen(wtn_screens.privacypolicy)} >
              {i18next.t('Privacy policy')}
            </div>
            <div className="dropdown-item" onClick = { (e) => app.setScreen(wtn_screens.disclaimer)} >
              {i18next.t('disclaimer')}
            </div>
            <div className="dropdown-item" onClick = { (e) => app.setScreen(wtn_screens.about)}>
              {i18next.t('about')}
            </div>
            <div className="dropdown-item" onClick = { (e) => setShowShareLinkDialog(true)}>
              {i18next.t('share.link.to.app')}
            </div>
          </div>
        </div>
      </div>
      </header>
      <QRCodeLinkToAppDialog isOpen={showShareLinkDialog} onClose={() => setShowShareLinkDialog(false)}></QRCodeLinkToAppDialog>
      <ToastContainer />
    </Suspense>
    )
}

