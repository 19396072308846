/* 
 * Components for blender results displaying
 *
 * Oleg Gurevich, 2019
 */

import React from 'react';

import { COLOR_WETNOTES } from '../WTNCommons'
import i18next from 'i18next';
import { OptionShowDetails, TemperatureControl, OptionUnits, OptionGasCalcMode, OptionPriceHe, OptionPriceO2, OptionPriceN2, OptionCurrencyLabel, OptionLanguageUI } from '../SharedOptions'

import MdSettings from 'react-ionicons/lib/MdSettings'



export function DeltaGasSettings(props){
  // var divStyle = {  minWidth: '24em'};
  
  return (
    // <div style = {divStyle}  className="bg-white p-3 rounded-lg border mb-4">
    <div className="bg-white p-3 rounded-lg border mb-4">
      <h5><MdSettings fontSize="2em" color={COLOR_WETNOTES}></MdSettings> {i18next.t('Blender Options')}</h5>
      <div className="row">
        <div className="col-lg">
        <OptionGasCalcMode></OptionGasCalcMode>
        <TemperatureControl></TemperatureControl>
        </div>
        <div className="col-lg">
        <OptionShowDetails></OptionShowDetails>
        <OptionUnits></OptionUnits>
        <OptionLanguageUI></OptionLanguageUI>
        </div>
      </div>
      <h5>{i18next.t('Price calculation')}</h5>
      <div className="row">
        <div className="col-lg">
        <OptionPriceHe></OptionPriceHe>
        <OptionPriceO2></OptionPriceO2>
        </div>
        <div className="col-lg">
        <OptionPriceN2></OptionPriceN2>
        <OptionCurrencyLabel></OptionCurrencyLabel>
        </div>
      </div>
    </div>
  )
}
