/* 
 * Wetnotes gas blender
 *
 * Oleg Gurevich, 2019
 */
import React, { Component } from 'react';


import  {WetNotes, WetNotesSettings, BlendingMode, GasBlender}  from '../wetnotes/wetnotes.js'
import { GasComponent, COLOR_WETNOTES } from '../WTNCommons'

import { BlenderSettings } from './BlenderSettings'
import { BlenderResults } from './BlenderResults'
import MdSettings from 'react-ionicons/lib/MdSettings'
import MdClose from 'react-ionicons/lib/MdClose'
import i18next from 'i18next'


export class GasBlenderComponent extends Component {
  constructor(props){
    super(props);
    this.id = props.id?props.id:'defaultGasBlender';
    this.gasBlender = new GasBlender();
    this.restoreStateOfBlender();
    this.state = { toggleSettingsOn : false};
  }
  restoreStateOfBlender(){
    const loadedState = localStorage.getItem(this.id);
    if(loadedState === null) return;
    this.gasBlender.setStateJSON(JSON.parse(loadedState));
  }
  componentDidMount() {
    WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_BLENDING_MODE]);
    this.gasBlender.addPropertyChangeListener(this, [GasBlender.PROPERTY_CALCULATED]);
  }
  componentWillUnmount() {
    this.gasBlender.removePropertyChangeListener(this, [GasBlender.PROPERTY_CALCULATED]);
    WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_BLENDING_MODE]);
  }
  propertyChange(propertyChangeEvent){
    if(propertyChangeEvent.getSource() === WetNotes.SETTINGS){
      this.setState({
        lastChangeEvent : propertyChangeEvent
      }); 
    }
    if(propertyChangeEvent.getSource() === this.gasBlender){
      this.saveStateOfBlender();
    }
  }
  saveStateOfBlender(){
    localStorage.setItem(this.id, JSON.stringify(this.gasBlender.getStateJSON()));
  }
  render() {
    const rowToggledOn="row";
    const rowToggledOff="row d-none d-lg-flex";
      return (
      
            <div className="container p-0 mt-2 text-left">
            <h4>{i18next.t('Partial pressure blending')}</h4>
            <div className={!this.state.toggleSettingsOn?rowToggledOn:rowToggledOff}>
              
                <div className="col-sm">
                  <GasComponent tankModel={this.gasBlender.tankOrigin} ></GasComponent> 
                </div>
                { WetNotes.SETTINGS.getBlendingMode() !== BlendingMode.He_AutoEAN &&
                <div className="col-sm">
                  <GasComponent gasModel={this.gasBlender.topoffGas} showPressure={false}></GasComponent>    
                </div>
                }
                { WetNotes.SETTINGS.getBlendingMode() === BlendingMode.He_AutoEAN &&
                <div className="col-sm">
                  <GasComponent gasModel={this.gasBlender.autoEAN} fractionReadOnly={true} showPressure={false}></GasComponent>    
                </div>
                }
                <div className="col-sm wtn-GC-left ">
                  <GasComponent tankModel={this.gasBlender.tankTarget} showVolume={false} ></GasComponent> 
                </div>
              
            </div>
            <div className={!this.state.toggleSettingsOn?rowToggledOn:rowToggledOff}>
              <div className="col-sm">
                <BlenderResults gasBlender={this.gasBlender}></BlenderResults>
              </div>
            </div>
            <div className={this.state.toggleSettingsOn?rowToggledOn:rowToggledOff}>
              <div className="col-sm">
                <BlenderSettings></BlenderSettings>
              </div>
            </div>
            <div className="wtn-settings-toggler d-inline-block d-lg-none" onClick={e => this.setState({toggleSettingsOn: !this.state.toggleSettingsOn})}>
                {this.state.toggleSettingsOn?
                  <MdClose fontSize="3em" color={COLOR_WETNOTES}></MdClose>:<MdSettings fontSize="3em" color={COLOR_WETNOTES}></MdSettings>
                }
            </div>
            </div>
    )
  }
}

