import { GasModel, WetModel } from "./wetnotes";

const DEFAULT_STANDARD_GAS_SET = [ 
	new GasModel(7, 85, 200).setName('TX 7/85'),
	new GasModel(10, 70, 200).setName('TX 10/70'),
	new GasModel(12, 65, 200).setName('TX 12/65'),
	new GasModel(15, 55, 200).setName('TX 15/55'),
	new GasModel(18, 45, 200).setName('TX 18/45'),
	new GasModel(21, 0, 200).setName('Air'),
	new GasModel(21, 35, 200).setName('TX 21/35'),
	new GasModel(30, 30, 200).setName('TX 30/30'),
	new GasModel(32, 0, 200).setName('EAN 32'),
	new GasModel(36, 0, 200).setName('EAN 36'),
	new GasModel(50, 0, 200).setName('EAN 50'),
	new GasModel(50, 25, 200).setName('TX 50/25'),
	new GasModel(100, 0, 200).setName('Oxygen'),
];

class StandardGasSet extends WetModel{
	static get PROPERTY_GAS_SET() { return "prop_standard_gas_set" };
	constructor(){
		super();
		this.gasSet = [...DEFAULT_STANDARD_GAS_SET];
		
	}
	resetGasSet(){
		const oldValue = [...this.gasSet];
		this.gasSet = [...DEFAULT_STANDARD_GAS_SET];
		this.propertyChangeSupport.firePropertyChange(StandardGasSet.PROPERTY_GAS_SET, oldValue, this.gasSet);
		return this;
	}
  getStateJSON(){
		const gasSetArrayJSON = [];
		this.gasSet.map(gasModel => gasSetArrayJSON.push(gasModel.getStateJSON()));
		return {
				gasSet : gasSetArrayJSON
		}
	}
	setStateJSON(jsonState = {}){
		if(jsonState.gasSet !== undefined) {
			const gasSetArrayJSON = jsonState.gasSet;
			this.gasSet = [];
			gasSetArrayJSON.map( jsonOfGasModel => this.gasSet.push(new GasModel().setStateJSON(jsonOfGasModel)));
		}
		return this;
	}
	getGasSet(){
		return this.gasSet;
	}
	removeGas(gasModel){
		const indexOfGasModel = this.findGas(gasModel);
		if(indexOfGasModel < 0) return;
		const oldValue = [...this.gasSet];
		this.gasSet.splice(indexOfGasModel, 1);
		this.propertyChangeSupport.firePropertyChange(StandardGasSet.PROPERTY_GAS_SET, oldValue, this.gasSet);
	}
	isGasModelEqual(modelA, modelB){
		return (modelA.getO2() === modelB.getO2() && modelA.getHe() === modelB.getHe());
	}
	findGas(gasModel){
		let foundIndex = -1;
		this.gasSet.some((gasModelItem, index) => { 
			const matches = this.isGasModelEqual(gasModelItem, gasModel);
			if(matches) foundIndex = index;
			return matches; });
		return foundIndex;
	}
	addGas(gasModel){
		const addingGas = new GasModel(21, 0, 0).applyGasFraction(gasModel);
		const index = this.findGas(addingGas);
		if(index > 0) return this;
		const oldValue = [...this.gasSet];
		this.gasSet.push(addingGas);
		this.gasSet.sort(GasModel.compare)
		this.propertyChangeSupport.firePropertyChange(StandardGasSet.PROPERTY_GAS_SET, oldValue, this.gasSet);
		return this;
	}

}
const standardGasSet = new StandardGasSet();
export default standardGasSet;
