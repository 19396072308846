import { WetModel } from "./wetnotes";

const DEFAULT_SET_OF_TANK_VOLUMES_dm3 = [ 5.55, 8, 10, 11.1, 12, 18, 20, 24, 36, 50 ] 

class SetOfTankVolumes extends WetModel{
	static get PROPERTY_SET_OF_TANK_VOLUMES() { return "prop_set_of_tank_volumes" };
	constructor(){
		super();
		this.setOfTankVolumes_dm3 = [...DEFAULT_SET_OF_TANK_VOLUMES_dm3];
		
	}
	resetDefault(){
		const oldValue = [...this.setOfTankVolumes_dm3];
		this.setOfTankVolumes_dm3 = [...DEFAULT_SET_OF_TANK_VOLUMES_dm3];
		this.propertyChangeSupport.firePropertyChange(SetOfTankVolumes.PROPERTY_SET_OF_TANK_VOLUMES, 
			oldValue, this.setOfTankVolumes_dm3);
		return this;
	}
  getStateJSON(){
		return {
				setOfTankVolumes : [...this.setOfTankVolumes_dm3]
		}
	}
	setStateJSON(jsonState = {}){
		if(jsonState.setOfTankVolumes !== undefined) {
			this.setOfTankVolumes_dm3 = [...jsonState.setOfTankVolumes];
		}
		return this;
	}
	getSetOfTankVolumes_dm3(){
		return this.setOfTankVolumes_dm3;
	}
	removeTankVolume_dm3(tankVolume_dm3){
		const indexOfTankVolume = this.setOfTankVolumes_dm3.indexOf(tankVolume_dm3);
		if(indexOfTankVolume < 0) return false;
		const oldValue = [...this.setOfTankVolumes_dm3];
		this.setOfTankVolumes_dm3.splice(indexOfTankVolume, 1);
		this.propertyChangeSupport.firePropertyChange(SetOfTankVolumes.PROPERTY_SET_OF_TANK_VOLUMES,
			oldValue, this.setOfTankVolumes_dm3);
		return true;	
	}
	addTankVolume_dm3(tankVolume_dm3){
		const index = this.setOfTankVolumes_dm3.indexOf(tankVolume_dm3)
		if(index > 0) return this
		const oldValue = [...this.setOfTankVolumes_dm3];
		this.setOfTankVolumes_dm3.push(tankVolume_dm3);
		this.setOfTankVolumes_dm3.sort((a, b) => a - b)
		this.propertyChangeSupport.firePropertyChange(
			SetOfTankVolumes.PROPERTY_SET_OF_TANK_VOLUMES, 
			oldValue, this.setOfTankVolumes_dm3);
		return this;
	}
	

}
const setOfTankVolumes = new SetOfTankVolumes();
export default setOfTankVolumes;
