import React, {useState} from 'react'
import setOFTankVolumes from './wetnotes/SetOfTankVolumes'
import { uuidv4 } from './WTNCommons';
import { WetNotes } from './wetnotes/wetnotes';

const unknownFunction = () => {
	console.warn("undefined function")
}
export default function PickUpTankVolumes(props) {
	const { setVolume = unknownFunction, tankModel } = props
	const [ id ] = useState("sotv_" + uuidv4().replace(/-/g, ''))
	return(
		<div className="dropdown p-0 d-inline">
			<button className="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id={id} 
				data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				{/* {i18next.t('PickUp')} */}
			</button>
			<div className="dropdown-menu" aria-labelledby={id} 
				>
					{setOFTankVolumes.getSetOfTankVolumes_dm3().map((volume_dm3, index) => (
						<button key={index} className="dropdown-item" 
							type="button" 
							onClick={(e) => {e.stopPropagation(); setVolume(volume_dm3)}}
						>{WetNotes.formatVolume(WetNotes.MEASSURES.model2ViewVolumeOrCapacity(
							tankModel,
							volume_dm3))}</button>
					))}
      </div>
    </div>
	)
}