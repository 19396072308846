/* 
 * Wetnotes gas blender
 *
 * Oleg Gurevich, 2019
 */
import React, { Component } from 'react';

import i18next from 'i18next'

import  {WetNotes, WetNotesSettings, GasModel }  from '../wetnotes/wetnotes.js'
import { GasComponent, COLOR_WETNOTES } from '../WTNCommons'
import { MODSettings } from './MODSettings.jsx';

import MdSettings from 'react-ionicons/lib/MdSettings'
import MdClose from 'react-ionicons/lib/MdClose'



const gasMODRelevantSettings = [WetNotesSettings.PROPERTY_MAX_END, WetNotesSettings.PROPERTY_MAX_PPO2, 
    WetNotesSettings.PROPERTY_MIN_PPO2, WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_He_TO_N2,
    WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_O2_TO_N2,  WetNotesSettings.PROPERTY_SHOW_DETAILS]
export class GasMOD extends Component {
  constructor(props){
    super(props);
    this.id = props.id !== undefined?props.id:'defaultGasMOD';
    this.gasMOD = new GasModel(32, 0).setName('Scuba gas');
    this.restoreStateOfGasMOD();
    this.state = { toggleSettingsOn : false};
  }
  restoreStateOfGasMOD(){
    const loadedState = localStorage.getItem(this.id);
    if(loadedState === null) return;
    this.gasMOD.setStateJSON(JSON.parse(loadedState));
  }
  componentDidMount() {
    // WetNotes.SETTINGS.addPropertyChangeListener(this, gasMODRelevantSettings);
    this.gasMOD.addPropertyChangeListener(this);
  }
  componentWillUnmount() {
    this.gasMOD.removePropertyChangeListener(this);
    // WetNotes.SETTINGS.removePropertyChangeListener(this, gasMODRelevantSettings);
  }
  propertyChange(propertyChangeEvent){
    // if(propertyChangeEvent.getSource() === WetNotes.SETTINGS){
    //   this.setState({
    //     lastChangeEvent : propertyChangeEvent
    //   }); 
    // }
    if(propertyChangeEvent.getSource() === this.gasMOD){
      if(propertyChangeEvent.getPropertyName() === GasModel.PROPERTY_O2 && this.gasMOD.getO2() < 4){
        this.gasMOD.setO2(4); 
        return;
      }
      this.saveStateOfGasMOD();
    }
  }
  saveStateOfGasMOD(){
    localStorage.setItem(this.id, JSON.stringify(this.gasMOD.getStateJSON()));
  }
  render() {
    const rowToggledOn="row";
    const rowToggledOff="row d-none d-lg-flex";
      return (
            <div className = "container p-0 mt-2 text-left">
            <h4>{i18next.t('MOD - max (min) operative depth')}</h4>
            <div className = "row">
              <div className = "col-lg-5">
                <div className={this.state.toggleSettingsOn?rowToggledOff:rowToggledOn}>
                  <div className = "col">
                    <GasComponent gasModel = {this.gasMOD} showPressure = {false}></GasComponent>
                  </div>
                </div>
                <div className={this.state.toggleSettingsOn?rowToggledOn:rowToggledOff}>
                  <div className = "col">
                    <MODSettings></MODSettings>
                  </div>
                </div>
              </div>
              <div className = "col-lg-7">
              <div className={this.state.toggleSettingsOn?rowToggledOff:rowToggledOn}>
                <div className = "col">
                  <MODResults gasMOD = {this.gasMOD}></MODResults>
                </div>
                </div>
              </div>
            </div>
            
            <div className="wtn-settings-toggler d-inline-block d-lg-none" onClick={e => this.setState({toggleSettingsOn: !this.state.toggleSettingsOn})}>
                {this.state.toggleSettingsOn?
                  <MdClose fontSize="3em" color={COLOR_WETNOTES}></MdClose>:<MdSettings fontSize="3em" color={COLOR_WETNOTES}></MdSettings>
                }
            </div>
            </div>
    )
  }
}
class MODResults extends Component{
  constructor(props){
    super(props);
    this.gasMOD = props.gasMOD;
  }
  componentDidMount() {
    WetNotes.SETTINGS.addPropertyChangeListener(this, gasMODRelevantSettings);
    this.gasMOD.addPropertyChangeListener(this);
  }
  componentWillUnmount() {
    this.gasMOD.removePropertyChangeListener(this);
    WetNotes.SETTINGS.removePropertyChangeListener(this, gasMODRelevantSettings);
  }
  propertyChange(propertyChangeEvent){
       this.setState({
         lastChangeEvent : propertyChangeEvent
       }); 
  }
  render(){
    const minODView = WetNotes.MEASSURES.Depth.getAdjustedOfMetric(this.getMinOperativeDepth());
    const maxODView = WetNotes.MEASSURES.Depth.getAdjustedOfMetric(this.getMaxOperativeDepth());
    const minOD = this.getMinOperativeDepth();
    const maxOD = this.getMaxOperativeDepth();

    const maxEND = WetNotes.MEASSURES.Depth.getAdjustedOfMetric(WetNotes.SETTINGS.getMaxEND());
    const maxENDView = WetNotes.formatDepth(maxEND);
    const depthLabel = WetNotes.MEASSURES.Depth.getLabel();
    const correspondsENDMeters = this.getDepthMeters4End(WetNotes.SETTINGS.getMaxEND());
    const showENDColumn = correspondsENDMeters !== undefined;
    const correspondsEND = correspondsENDMeters === undefined?i18next.t('choosed gas has no narcotic effect'):
      WetNotes.formatDepth(WetNotes.MEASSURES.Depth.getAdjustedOfMetric(correspondsENDMeters));
    return(
      <div className={"bg-white p-3 rounded-lg border mb-4"}>
      <h5>{i18next.t('Operative Depth')}</h5>
      <div className = "row text-center">
        { minOD > 0 &&
        <div className = "col">
          <div className="alert alert-danger">{i18next.t('Minimum')}: <span className="h5">{WetNotes.formatDepth(minODView)}</span> {depthLabel}</div>
        </div>
        }
        <div className = "col">
        <div className="alert alert-danger">{i18next.t('Maximum')}: <span className="h5">{WetNotes.formatDepth(maxODView)}</span> {depthLabel}</div>
        </div>
      </div>
      <h5>{i18next.t('Equivalent Narcotic Depth (END)')}</h5>
      <div className="alert alert-warning">{i18next.t('Maximum END')}: {maxENDView} {depthLabel} {i18next.t('corresponds to depth')}: <span className="h5">{correspondsEND}</span> 
      &nbsp;{correspondsENDMeters === undefined?'':depthLabel}</div>
      <h5>{i18next.t('MOD table')}</h5>
      <table className = "table table-responsive-sm table-sm table-striped">
        {this.renderTableHead(showENDColumn)}
        {this.renderTableBody(minOD, maxOD, showENDColumn)}
      </table>
      </div>
    )
  }
  renderTableBody(minOD, maxOD, showENDColumn){
    const resultTableData = this.createResultTableData();
    return (
        <tbody>
          {
              resultTableData.map((item) => (<ResultTableRow rowData = {item} gasMOD = {this.gasMOD} minOD = { minOD } maxOD = { maxOD } showENDColumn = {showENDColumn}></ResultTableRow>))
          }
        </tbody>
    )

  }
  renderTableHead(showENDColumn){
    const depthLabel = WetNotes.MEASSURES.Depth.getLabel();
    const densityLabel = WetNotes.MEASSURES.DensityGramLiter.getLabel();
    return (
      <thead>
          <tr>
            <th scope="col">{i18next.t('Depth')} ({depthLabel})</th>
          {showENDColumn &&
            <th scope="col">{i18next.t('END')} ({depthLabel})</th>
          }
          
          <th scope="col">{i18next.t('gas.density')} ({densityLabel})</th>
          
          <th scope="col">{i18next.t('Narcotic p.p.')}</th>
            <th scope="col">{i18next.t('O2 p.p.')}</th>
            { this.getHe() > 0 &&
            <th scope="col">{i18next.t('He p.p.')}</th>
            }
            { this.gasMOD.getN2() > 0 &&
            <th scope="col">{i18next.t('N2 p.p.')}</th>
            }
          </tr>
        </thead>
    )
  }
  /** 
	 * @return min operative depth in meters
	 */
	getMinOperativeDepth(){
		// const mod = Math.ceil(WetNotes.SETTINGS.getMinPpO2() * 100.0 / this.getO2() * 10 - 10);
		const mod = WetNotes.SETTINGS.getMinPpO2() * 100.0 / this.getO2() * 10 - 10;
		if(mod < 0) return 0;
		return mod;
	}
	/** 
	 * @return max operative depth in meters
	 */
	getMaxOperativeDepth(){
		// const mod = Math.floor(WetNotes.SETTINGS.getMaxPpO2() * 100.0 / this.getO2() * 10 - 10);
		const mod = WetNotes.SETTINGS.getMaxPpO2() * 100.0 / this.getO2() * 10 - 10;
		if(mod < 0) return 0;
		return mod;
	}
	/** 
	 * @return END in meters
	 */

	getEND(depthMeters){
    const kNarcO2N = WetNotes.SETTINGS.getNacroticEffectFactorO2ToN2();
		const kNarcHe2N = WetNotes.SETTINGS.getNacroticEffectFactorHeToN2();
		let end = ((((100.0 - this.getO2() - this.getHe())  + this.getHe() * kNarcHe2N + this.getO2()* kNarcO2N) )
				/ (79.0 + 21.0 * kNarcO2N) * (10.0 + depthMeters) - 10);
		if(end < 0) return undefined;
		return end;
	}

	getDepthMeters4End(endMeters){
    const kNarcO2N = WetNotes.SETTINGS.getNacroticEffectFactorO2ToN2();
		const kNarcHe2N = WetNotes.SETTINGS.getNacroticEffectFactorHeToN2();
		const gasNarcPercent = (((100.0 - this.getO2() - this.getHe())  + this.getHe() * kNarcHe2N + this.getO2()* kNarcO2N) );
		if(gasNarcPercent === 0) return undefined;
		const depthMeters4End = (endMeters + 10) * (79.0 + 21.0 * kNarcO2N) / gasNarcPercent - 10.0;
		return depthMeters4End;
	}

	getO2() {
		return this.gasMOD.getO2();
	}
  getDensity(depthMeters){
    const density = this.gasMOD.getDensityAt1Ata() * (depthMeters / 10.0 + 1);
    return density;
  }
	getHe() {
		return this.gasMOD.getHe();
	}
	getNarcPP(d) {
		const kNarcO2N = WetNotes.SETTINGS.getNacroticEffectFactorO2ToN2();
		const kNarcHe2N = WetNotes.SETTINGS.getNacroticEffectFactorHeToN2();
    return (this.gasMOD.getN2() + this.getHe() * kNarcHe2N + this.getO2() * kNarcO2N) *
       ( d / 10.0 + 1) / 100;
	}
	getPPO2(d) {
		return (this.getO2() * (d / 10.0 + 1)) / 100.0;

	}
	getPPN2(d) {
		return (this.gasMOD.getN2() * (d / 10.0 + 1)) / 100.0;
	}
	getPPHe(d) {
		return (this.getHe() * (d / 10.0 + 1)) / 100.0;
  }
  
  createResultTableData() {
		const resultTableData = [];
		if(this.getO2() === 0) return resultTableData;

		const maxMOD=this.getMaxOperativeDepth();
		const minMOD=this.getMinOperativeDepth();

		let depthStep = 2;
		let startDepth = minMOD;

		startDepth = Math.floor(startDepth / depthStep) * depthStep;

		if(!WetNotes.SETTINGS.isMetricSystem()){
      // redefine start depth and step value
      // use 10 feets as step on imperial system
			depthStep = 10.0 / WetNotes.MEASSURES.Depth.getK2Imperial();
			let imperialStartDepth = WetNotes.MEASSURES.Depth.getAdjustedOfMetric(minMOD);
			imperialStartDepth =  Math.floor(imperialStartDepth / 10) * 10;
			startDepth = imperialStartDepth / WetNotes.MEASSURES.Depth.getK2Imperial();
    }
    
    if((maxMOD - minMOD) / depthStep > 96){
      depthStep = depthStep * 3;
    }else
    if((maxMOD - minMOD) / depthStep > 42){
      depthStep = depthStep * 2;
    }

		for(let d = startDepth; d < maxMOD; d = d + depthStep){
      // row's data
      const resultTableRow = {};
			resultTableRow.depth = d;
			resultTableRow.end = this.getEND(d);
			resultTableRow.narcPP = this.getNarcPP(d);
			resultTableRow.ppO2 = this.getPPO2(d);
			resultTableRow.ppN2 = this.getPPN2(d);
			resultTableRow.ppHe = this.getPPHe(d);
			resultTableRow.density = this.getDensity(d);
			//resultTableRow.ead =  this.getEAD(d);
			resultTableData.push(resultTableRow);
		}
		// row's data
    const resultTableRow = {};
    const d = maxMOD;
    resultTableRow.depth = d;
    resultTableRow.end = this.getEND(d);
    resultTableRow.narcPP = this.getNarcPP(d);
    resultTableRow.ppO2 = this.getPPO2(d);
    resultTableRow.ppN2 = this.getPPN2(d);
    resultTableRow.ppHe = this.getPPHe(d);
    //resultTableRow.ead =  this.getEAD(d);
    resultTableRow.density = this.getDensity(d);
    resultTableData.push(resultTableRow);
    		return resultTableData;
	}
}
function TdGasDensity(props){
  return (
    <td>
      {WetNotes.MEASSURES.DensityGramLiter.getAdjustedOfMetric(props.density).toFixed(3)}
    </td>
  )
}
function ResultTableRow(props){
  const maxOD = props.maxOD;
  const minOD = props.minOD;
  const gasMOD = props.gasMOD;
  const showENDColumn = props.showENDColumn;
  const rowData = props.rowData;
  const maxEND = WetNotes.SETTINGS.getMaxEND();
  const endDataDefined = rowData.end !== undefined;
  const endExceeded = showENDColumn && rowData.end >= maxEND;
  const modRangeExceeded = rowData.depth < minOD || rowData.depth >= maxOD;

  let trClassName = "";
  if(endExceeded) trClassName="table-warning"
  if(modRangeExceeded) trClassName="table-danger"
  return (
    <tr className = {trClassName}>
      <td>{WetNotes.formatDepth(WetNotes.MEASSURES.Depth.getAdjustedOfMetric(rowData.depth))}</td>

      {showENDColumn && endDataDefined &&
      <td className = {endExceeded?"text-danger font-weight-bold":""} >{WetNotes.formatDepth(WetNotes.MEASSURES.Depth.getAdjustedOfMetric(rowData.end))}</td>
      }
       {showENDColumn && !endDataDefined &&
      <td>-</td>
      }
     
      <TdGasDensity density = {rowData.density}></TdGasDensity>
     
      <td>{WetNotes.formatPercent(rowData.narcPP)}</td>
      <td className = {modRangeExceeded?"text-danger font-weight-bold":""} >{WetNotes.formatPercent(rowData.ppO2)}</td>
      {gasMOD.getHe() > 0 &&
      <td>{WetNotes.formatPercent(rowData.ppHe)}</td>
      }
      {gasMOD.getN2() > 0 &&
      <td>{WetNotes.formatPercent(rowData.ppN2)}</td>
      }     
    </tr>     
  )
}
