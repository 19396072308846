import React from 'react';

import i18next from 'i18next';
import  {WetNotes,  GasModel, TankModel, UIUtils, WetNotesSettings }  from './wetnotes/wetnotes.js'

import imgTank from './tankwhite.png';
import MdBookmark from 'react-ionicons/lib/MdBookmark';
import standardGasSet from './wetnotes/StandardGasSet';
import setOfTankVolumes from './wetnotes/SetOfTankVolumes'
import PickUpTankVolumes from './PickUpTankVolume.js';
import PickUpNominalWorkingPressureBar from './PickUpNominalWorkingPressureBar'

export const uuidv4 = require('uuid/v4');

export const COLOR_MAX_PPO2 = "#f8d7da";
export const COLOR_MIN_PPO2 = "#f8d7da";
export const COLOR_MAX_END = "#ffeeba";
const COLOR_O2 = "green";
const COLOR_He = "#0FFFFF";
const COLOR_N2 = "#e4e812";
const COLOR_O2_HEX = 0x28a745;
const COLOR_He_HEX = 0x0FFFFF;
const COLOR_N2_HEX = 0xE4E812;
export const COLOR_WETNOTES = "#17a2b8";
export const ANDROID_APP_USER_AGENT_SUBSTRING = "Android wrapper for Gas blender toolkit"
export const CSS_CLASSNAME_PANEL = 'bg-white p-3 rounded-lg border mb-4';

export const KEY_ENTER = 13;
export const KEY_ESCAPE = 27;

const GC_className_Of_COLUMN = "col-4";
const GC_className_Of_COLUMN_MERGED = "col-8";

// workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
export const firefoxWorkaroundDelyinMsToIgnoreOnblure = 125;

export class StandardGasPicker extends React.Component{
  constructor(props){
    super(props);
    this.id = (undefined === props.id)?"sgp_" + uuidv4().replace(/-/g, ''):props.id;
  }
  componentDidMount() {
		standardGasSet.addPropertyChangeListener(this);
	}
	componentWillUnmount() {
		standardGasSet.removePropertyChangeListener(this);
	}
	propertyChange(propertyChangeEvent){
    this.setState({lastChangeEvent : propertyChangeEvent}); 
	}
  handlePeekUp(e, gasModel) {
      this.props.targetGasModel.applyGasFraction(gasModel);
  }
 
  render(){
    return (
    <div className="row justify-content-left text-left">
      <div className={GC_className_Of_COLUMN_MERGED}>
        <div className="dropdown mt-2 mb-2">
          <button className="btn btn-outline-secondary dropdown-toggle" type="button" id={this.id} 
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {i18next.t('PickUp standard gas')}
          </button>
          <div className="dropdown-menu" aria-labelledby={this.id} >
            {standardGasSet.getGasSet().map((gasModel, index) => (
              <button key={index} className="dropdown-item" type="button" onClick={(e) => this.handlePeekUp(e, gasModel)}>{gasModel.getGasName()}</button>
            ))}
          
          </div>
        </div>
      </div>
  </div>
    )
    }
}

function buildBackgroudImageString(color){
  return "linear-gradient(to right, "+ UIUtils.getGRBAColorString(color, 0.2) +", "+ 
  UIUtils.getGRBAColorString(color, 0.4) +", "+
  UIUtils.getGRBAColorString(color, 0.7)
  +", "+ UIUtils.getGRBAColorString(color, 0.8)  +", "+ UIUtils.getGRBAColorString(color, 0.2)+")"
}

function TankVisualisation(props){
  const gasModel = props.gasModel;
  const totalHeight = 115;
  const hightO2 = Math.round(totalHeight * gasModel.getFiO2());
  const hightHe = Math.round(totalHeight * gasModel.getFiHe());
  const hightN2 = totalHeight - hightHe - hightO2;
  const startHe = 48;
  const startN2 = startHe + hightHe;
  const startO2 = startN2 + hightN2; 
  const zeroPressure = 0 === gasModel.getPressure_Bar();

  const backgroundImageO2 = buildBackgroudImageString(COLOR_O2_HEX);
  const backgroundImageHe = buildBackgroudImageString(COLOR_He_HEX);
  const backgroundImageN2 = buildBackgroudImageString(COLOR_N2_HEX);
  const leftPixel = "7px"
  const divStyleOfColorsSlices = {
    o2 : {left: leftPixel, backgroundImage: backgroundImageO2, 
          top: startO2 + "px", width: "35px", height: hightO2 + "px" },
    he : {left: leftPixel, backgroundImage: backgroundImageHe, 
          top: startHe + "px", width: "35px", height: hightHe + "px" },
    n2 : {left: leftPixel, backgroundImage: backgroundImageN2, 
          top: startN2 + "px", width: "35px", height: hightN2 + "px" },
  };
  return (
    <div className="text-center">
      <div className="d-none d-sm-inline-block position-relative">
        <img src={imgTank} alt="Scubatank" />
        {!zeroPressure &&
        <div>
          <div className="position-absolute" style= { divStyleOfColorsSlices.o2}></div>
          <div className="position-absolute" style= { divStyleOfColorsSlices.he}></div>
          <div className="position-absolute" style= { divStyleOfColorsSlices.n2}></div>
        </div> 
        }
      </div>
    </div>
  );
}

function GasFractionsRow(props){
  const classNameAddOn = "h5";
  return (
  <div className="row">
    <GasOxygenValue readOnly={props.readOnly} className={GC_className_Of_COLUMN + " " + classNameAddOn} gasModel = {props.gasModel}></GasOxygenValue>
    <GasHeliumValue readOnly={props.readOnly} className={GC_className_Of_COLUMN + " " + classNameAddOn} gasModel = {props.gasModel}></GasHeliumValue>
    <div className={GC_className_Of_COLUMN + " readonlyValue " + classNameAddOn}>{props.gasModel.getN2().toFixed(2)}</div>
  </div> 
  )
}
function GasFractionLabelsRow(props){
  return (
    <div className="row">
      <div className={GC_className_Of_COLUMN}>
      <MdBookmark color={COLOR_O2}></MdBookmark>
        <label className="control-label">O2 %</label>
      </div>
      <div className={GC_className_Of_COLUMN}>
      <MdBookmark color={COLOR_He}></MdBookmark>
        <label className="control-label">He %</label>
        </div> 
      <div className={GC_className_Of_COLUMN}>  
      <MdBookmark color={COLOR_N2}></MdBookmark>
        <label className="control-label">N2 %</label>
      </div>
    </div> 
  )
}
export function UnitsLiterPerMinute(props){
  const unitsText = WetNotes.MEASSURES.Volume.getLabel() + "/ " + i18next.t('minute.short');
  return (
        <span className={props.className}>{unitsText}</span>
  )
}
export function UnitsMeter(props){
  const unitsText = WetNotes.MEASSURES.Depth.getLabel();
  return (
        <span className={props.className}>{unitsText}</span>
  )
}
export function UnitsTemperature(props){
  const unitsText = WetNotes.MEASSURES.Temperatur.getLabel();
  return (
        <span className={props.className}>{unitsText}&#x2070;</span>
  )
}
export function UnitsAta(props){
  const unitsText = i18next.t('atm')
  return (
        <span className={props.className}>{unitsText}</span>
  )
}
export function UnitsLiterCui(props){
  const unitsText = WetNotes.MEASSURES.Volume.getLabel();
  return (
        <span className={props.className}>{unitsText}</span>
  )
}
export function UnitsLiterCuft(props){
  const unitsText = WetNotes.MEASSURES.LiterCuft.getLabel();
  return (
        <span className={props.className}>{unitsText}</span>
  )
}
function GasPressureVolumeLabelsRow(props){
  const {volumeReadOnly} = props
  const labelTextPressure = i18next.t('Pressure') + " (" + WetNotes.MEASSURES.Pressure.getLabel() + ")";
  const volumeCapacity = WetNotes.SETTINGS.isMetricSystem()?i18next.t('Volume'):i18next.t('capacity');
  const labelTextVolume = volumeCapacity + " (" + WetNotes.MEASSURES.LiterCuft.getLabel() + ")";
  const {dataModel} = props
  return (
    <div className="row">
    { props.showPressure &&
      <div className={GC_className_Of_COLUMN}>
        <label className="control-label">{labelTextPressure}</label>
      </div>
    }
    { props.showVolume &&
      <div className={GC_className_Of_COLUMN_MERGED}>
        <label className="control-label">{labelTextVolume}</label>
        { !volumeReadOnly && <>
          &nbsp;
          <PickUpTankVolumes tankModel={dataModel} 
            setVolume={(volume_dm3) => dataModel.setVolume_dm3(volume_dm3)}/>
           </> 
        }
        <span>
            <PickUpNominalWorkingPressureBar tankModel={dataModel} 
            setNominalWorkingPressureBar={(newValue) => {
              dataModel.setNominalWorkingPressureBar(newValue)
            }
              }/>
        </span>
        
      </div> 
    }
      
    </div> 
  )
}
export class TankVolumeValue extends React.Component{
    constructor(props){
      super(props);
      this.tankModel = props.tankModel;
      this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
      this.state = {
        editMode: false, 
        editValue: WetNotes.formatVolume(this.getModelValue4View())
      };
    }
    propertyChange(propertyChangeEvent){
       this.setState({
        lastChangeEvent : propertyChangeEvent, 
        editValue: WetNotes.formatVolume(this.getModelValue4View())
      }); 
    }
  
    componentDidMount() {
      this.tankModel.addPropertyChangeListener(this, [TankModel.PROPERTY_VOLUME]);
      WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
    }
    
    componentWillUnmount() {
      this.tankModel.removePropertyChangeListener(this, [TankModel.PROPERTY_VOLUME]);
      WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
    }
    handleOnFocus(e){
      selectTextOfInputField(e)
    }
    startEditMode(){
      this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
    }
    handleOnBlur(e){
      // workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
      if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
      // end of workaround

      this.applyEditValue(e);
      this.editCompleted();
    }
    applyEditValue(e){
      if (this.isEditorValueValid()){
        const numberValue = Number.parseFloat(this.state.editValue);
        let newVolumeLiter = WetNotes.MEASSURES.view2ModelVolumeOrCapacity(numberValue, this.tankModel);
        if(newVolumeLiter > WetNotes.SETTINGS.volumeLiter_max) newVolumeLiter = WetNotes.SETTINGS.volumeLiter_max;
        if(newVolumeLiter < WetNotes.SETTINGS.volumeLiter_min) newVolumeLiter = WetNotes.SETTINGS.volumeLiter_min;
        this.tankModel.setVolume_dm3(newVolumeLiter);
      }
    }
    editCompleted(){
      this.setState({editMode : false, editValue: WetNotes.formatVolume(this.getModelValue4View())});
    }
    handleKeyUp(e){
      switch(e.keyCode){
        case KEY_ENTER:
          this.applyEditValue(e);
          this.editCompleted();
          break;
        case KEY_ESCAPE:
            this.editCompleted();
          break;
        default:
      }
    }
  
    handleChange(e){
      this.setState({ editValue:  e.target.value});
    }
    handleRangeSet(e){
      const newVolumeLiter = WetNotes.MEASSURES.view2ModelVolumeOrCapacity(Number.parseFloat(e.target.value), this.tankModel);
      this.tankModel.setVolume_dm3(newVolumeLiter);
    }
    isEditorValueValid(){
      const editValue = this.state.editValue
      if(this.state.editValue === "") return false;
      const numberValue = Number.parseFloat(editValue);
      if(numberValue < 0) return false;
      return true;
    }
    getModelValue4View(){
      return WetNotes.MEASSURES.model2ViewVolumeOrCapacity(this.tankModel);
    }
   
    render(){
      const classNameEditableValueViewMode = this.readOnly?"readonlyValue":"editableValue";
      const viewModelValue = this.getModelValue4View();
      const maxValue = WetNotes.MEASSURES.model2ViewVolumeOrCapacity(this.tankModel, WetNotes.SETTINGS.volumeLiter_max);
      const minValue = WetNotes.MEASSURES.model2ViewVolumeOrCapacity(this.tankModel, WetNotes.SETTINGS.volumeLiter_min);
      const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
      return (
        <div>
          {(this.readOnly || !this.state.editMode) &&
            <div className = {classNameEditableValueViewMode}
            >
              <span onClick={ (e)=> this.startEditMode() }>
                {WetNotes.formatVolume(viewModelValue)}
              </span>&nbsp;
              
            </div>
          }
          {(!this.readOnly && this.state.editMode) &&
            <div>
            <input className={"form-control "+ classNameAddOn}  required={true} type="number" autoFocus
                  
                  onFocus={(e) => this.handleOnFocus(e)}
                  onChange={(e) => this.handleChange(e) } 
                  onKeyUp={(e) => this.handleKeyUp(e)}
                  onBlur={(e) => this.handleOnBlur(e)}
                  value={this.state.editValue}>
                </input>
            </div>
          }
          {!this.readOnly && WetNotes.SETTINGS.isDisplaySliders() &&
            <div className="mt-2 mb-2">
              <input type="range" min={ minValue } max={ maxValue } step="1" 
                value={viewModelValue} onChange={(e) => this.handleRangeSet(e)} className="form-control-range"/>
            </div>
          }
        </div>
      )
    }
  }
class GasPressureValue extends React.Component{
  constructor(props){
    super(props);
    this.gasModel = props.gasModel;
    this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
    this.state = {
      editMode: false, 
      editValue: WetNotes.formatPressure(this.getModelValue4View())
    };
 
  }
 
  propertyChange(propertyChangeEvent){
     this.setState({
      lastChangeEvent : propertyChangeEvent, 
      editValue: WetNotes.formatPressure(this.getModelValue4View())
    }); 
  }
  componentDidMount() {
    this.gasModel.addPropertyChangeListener(this, [GasModel.PROPERTY_PRESSURE]);
    WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
  }
  
  componentWillUnmount() {
    this.gasModel.removePropertyChangeListener(this, [GasModel.PROPERTY_PRESSURE]);
    WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
  }
  handleOnFocus(e){
    selectTextOfInputField(e)
  }
  
  startEditMode(){
    this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
  }
  handleOnBlur(e){
    // workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
    if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
    // end of workaround

    this.applyEditValue(e);
    this.editCompleted();
  }
  applyEditValue(e){
    if (this.isEditorValueValid()){
      const numberValue = Number.parseFloat(this.state.editValue);
      let newPressureBar = WetNotes.MEASSURES.Pressure.getMetricOfCurrent(numberValue);
      if(newPressureBar > WetNotes.SETTINGS.pressure_max_bar) newPressureBar = WetNotes.SETTINGS.pressure_max_bar;
      if(newPressureBar < WetNotes.SETTINGS.pressure_min_bar) newPressureBar = WetNotes.SETTINGS.pressure_min_bar;
      this.gasModel.setPressure_Bar(newPressureBar);
    }
  }

  editCompleted(){
    this.setState({editMode : false, editValue: WetNotes.formatPressure(this.getModelValue4View())});
  }
  handleKeyUp(e){
    switch(e.keyCode){
      case KEY_ENTER:
        this.applyEditValue(e);
        this.editCompleted();
        break;
      case KEY_ESCAPE:
          this.editCompleted();
        break;
      default:
    }
  }

  handleChange(e){
    this.setState({ editValue:  e.target.value});
  }
  handleRangeSet(e){
    const newPressureBar = WetNotes.MEASSURES.Pressure.getMetricOfCurrent(Number.parseFloat(e.target.value));
    this.gasModel.setPressure_Bar(newPressureBar);
  }
  isEditorValueValid(){
    const editValue = this.state.editValue
    if(this.state.editValue === "") return false;
    const numberValue = Number.parseFloat(editValue);
    if(numberValue < 0) return false;
    return true;
  }
  getModelValue4View(){
    return WetNotes.MEASSURES.Pressure.getAdjustedOfMetric(this.gasModel.getPressure_Bar());
  }
 
  render(){
    const classNameEditableValueViewMode = this.readOnly?"readonlyValue":"editableValue";
    const viewModeValue = this.getModelValue4View();
    const maxValue = WetNotes.MEASSURES.Pressure.getAdjustedOfMetric(WetNotes.SETTINGS.pressure_max_bar);
    const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
    return (
      <div>
        {(this.readOnly || !this.state.editMode) &&
          <div className={classNameEditableValueViewMode} 
            onClick={ (e)=> this.startEditMode() }>{WetNotes.formatPressure(viewModeValue)}</div>
        }
        {(!this.readOnly && this.state.editMode) &&
          <div>
          <input className={"form-control "+ classNameAddOn}  required={true} type="number" autoFocus
                
                onFocus={(e) => this.handleOnFocus(e)}
                onChange={(e) => this.handleChange(e) } 
                onKeyUp={(e) => this.handleKeyUp(e)}
                onBlur={(e) => this.handleOnBlur(e)}
                value={this.state.editValue}>
              </input>
          </div>
        }
        {!this.readOnly && WetNotes.SETTINGS.isDisplaySliders() &&
          <div className="mt-2 mb-2">
            <input type="range" min={ WetNotes.SETTINGS.pressure_min_bar } max={ maxValue } step="1" 
              value={viewModeValue} onChange={(e) => this.handleRangeSet(e)} className="form-control-range"/>
          </div>
        }
      </div>
    )
  }
}
class GasOxygenValue extends React.Component{
  constructor(props){
    super(props);
    this.gasModel = props.gasModel;
    this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
    this.state = {
      editMode: false, 
      editValue: WetNotes.formatPercent(this.getModelValue4View())
    };
 
  }
 
  propertyChange(propertyChangeEvent){
     this.setState({
      lastChangeEvent : propertyChangeEvent, 
      editValue: WetNotes.formatPercent(this.getModelValue4View())
    }); 
  }
  componentDidMount() {
    this.gasModel.addPropertyChangeListener(this, [GasModel.PROPERTY_O2]);
    WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
  }
  
  componentWillUnmount() {
    this.gasModel.removePropertyChangeListener(this, [GasModel.PROPERTY_O2]);
    WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
  }
  handleOnFocus(e){
    selectTextOfInputField(e)
  }
  
  startEditMode(){
    this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
  }
  handleOnBlur(e){
    // workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
    if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
    // end of workaround

    this.applyEditValue(e);
    this.editCompleted();
  }
  applyEditValue(e){
    if (this.isEditorValueValid()){
      const numberValue = Number.parseFloat(this.state.editValue);
      let newPercent = numberValue;
      if(newPercent > 100) newPercent = 100;
      if(newPercent < 0) newPercent = 0;
      this.gasModel.setO2(newPercent);
    }
  }

  editCompleted(){
    this.setState({editMode : false, editValue: WetNotes.formatPercent(this.getModelValue4View())});
  }
  handleKeyUp(e){
    switch(e.keyCode){
      case KEY_ENTER:
        this.applyEditValue(e);
        this.editCompleted();
        break;
      case KEY_ESCAPE:
          this.editCompleted();
        break;
      default:
    }
  }

  handleChange(e){
    this.setState({ editValue:  e.target.value});
  }
  handleRangeSet(e){
    const newPercent = Number.parseFloat(e.target.value);
    this.gasModel.setO2(newPercent);
  }
  isEditorValueValid(){
    const editValue = this.state.editValue
    if(this.state.editValue === "") return false;
    const numberValue = Number.parseFloat(editValue);
    if(numberValue < 0 || numberValue > 100) return false;
    return true;
  }
  getModelValue4View(){
    return this.gasModel.getO2();
  }
 
  render(){

    const classNameEditableValueViewMode = this.readOnly?"readonlyValue":"editableValue";
    const viewModeValue = this.getModelValue4View();
    const maxValue = 100;
    const minValue = 0;
    const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
    return (
      <div className={this.props.className}>
        {(this.readOnly || !this.state.editMode) &&
          <div className = {classNameEditableValueViewMode} 
              onClick={ (e)=> this.startEditMode() }>{WetNotes.formatPercent(viewModeValue)}</div>
        }
        {(!this.readOnly && this.state.editMode) &&
          <div>
          <input className={"form-control "+ classNameAddOn}  required={true} type="number" autoFocus
                
                onFocus={(e) => this.handleOnFocus(e)}
                onChange={(e) => this.handleChange(e) } 
                onKeyUp={(e) => this.handleKeyUp(e)}
                onBlur={(e) => this.handleOnBlur(e)}
                value={this.state.editValue}>
              </input>
          </div>
        }
        {!this.readOnly && WetNotes.SETTINGS.isDisplaySliders() &&
          <div className="mt-2 mb-2">
            <input type="range" min={ minValue } max={ maxValue } step="1" 
              value={viewModeValue} onChange={(e) => this.handleRangeSet(e)} className="form-control-range"/>
          </div>
        }
      </div>
    )
  }
}
class GasHeliumValue extends React.Component{
  constructor(props){
    super(props);
    this.gasModel = props.gasModel;
    this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
    this.state = {
      editMode: false, 
      editValue: WetNotes.formatPercent(this.getModelValue4View())
    };
 
  }
 
  propertyChange(propertyChangeEvent){
     this.setState({
      lastChangeEvent : propertyChangeEvent, 
      editValue: WetNotes.formatPercent(this.getModelValue4View())
    }); 
  }
  componentDidMount() {
    this.gasModel.addPropertyChangeListener(this, [GasModel.PROPERTY_HE]);
    WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
  }
  
  componentWillUnmount() {
    this.gasModel.removePropertyChangeListener(this, [GasModel.PROPERTY_HE]);
    WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
  }
  handleOnFocus(e){
    selectTextOfInputField(e)
  }
  
  startEditMode(){
    this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
  }
  handleOnBlur(e){
    // workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
    if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
    // end of workaround

    this.applyEditValue(e);
    this.editCompleted();
  }
  applyEditValue(e){
    if (this.isEditorValueValid()){
      const numberValue = Number.parseFloat(this.state.editValue);
      let newPercent = numberValue;
      if(newPercent > 100) newPercent = 100;
      if(newPercent < 0) newPercent = 0;
      this.gasModel.setHe(newPercent);
    }
  }

  editCompleted(){
    this.setState({editMode : false, editValue: WetNotes.formatPercent(this.getModelValue4View())});
  }
  handleKeyUp(e){
    switch(e.keyCode){
      case KEY_ENTER:
        this.applyEditValue(e);
        this.editCompleted();
        break;
      case KEY_ESCAPE:
          this.editCompleted();
        break;
      default:
    }
  }

  handleChange(e){
    this.setState({ editValue:  e.target.value});
  }
  handleRangeSet(e){
    const newPercent = Number.parseFloat(e.target.value);
    this.gasModel.setHe(newPercent);
  }
  isEditorValueValid(){
    const editValue = this.state.editValue
    if(this.state.editValue === "") return false;
    const numberValue = Number.parseFloat(editValue);
    if(numberValue < 0 || numberValue > 100) return false;
    return true;
  }
  getModelValue4View(){
    return this.gasModel.getHe();
  }
 
  render(){
    const classNameEditableValueViewMode = this.readOnly?"readonlyValue":"editableValue";
    const viewModeValue = this.getModelValue4View();
    const maxValue = 100;
    const minValue = 0;
    const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
    return (
      <div className={this.props.className}>
        {(this.readOnly || !this.state.editMode) &&
          <div className = {classNameEditableValueViewMode}
            onClick={ (e)=> this.startEditMode() }>{WetNotes.formatPercent(viewModeValue)}</div>
        }
        {(!this.readOnly && this.state.editMode) &&
          <div>
          <input className={"form-control "+ classNameAddOn}  required={true} type="number" autoFocus
                
                onFocus={(e) => this.handleOnFocus(e)}
                onChange={(e) => this.handleChange(e) } 
                onKeyUp={(e) => this.handleKeyUp(e)}
                onBlur={(e) => this.handleOnBlur(e)}
                value={this.state.editValue}>
              </input>
          </div>
        }
        {!this.readOnly && WetNotes.SETTINGS.isDisplaySliders() &&
          <div className="mt-2 mb-2">
            <input type="range" min={ minValue } max={ maxValue } step="1" 
              value={viewModeValue} onChange={(e) => this.handleRangeSet(e)} className="form-control-range"/>
          </div>
        }
      </div>
    )
  }
}
function GasPressureVolumeValuesRow(props){
  const classNameAddOn = "h5";
  const { pressureReadOnly, showPressure, showVolume, volumeReadOnly } = props
  return (
    <div className="row">
      { showPressure &&
        <div className={GC_className_Of_COLUMN + " " + classNameAddOn} >
          <GasPressureValue gasModel = {props.dataModel} readOnly = {pressureReadOnly}></GasPressureValue>
        </div>
      }
      { showVolume &&
          <div className={GC_className_Of_COLUMN + " " + classNameAddOn}>
          <TankVolumeValue tankModel = {props.dataModel} readOnly = {volumeReadOnly}></TankVolumeValue>
          </div>
      }
     
    </div> 
    )
}
/**
 * GUI Component of gasModel or tankModel
 * @property (required) gasModel or tankModel
 * @property (optional) fractionReadOnly, showPressure, pressureReadOnly, showVolume, volumeReadOnly
 */
class GasPressureVolumeControls extends React.Component{
  constructor(props){
    super(props);
    this.dataModel = props.dataModel;
       
    this.showPressure = (undefined === props.showPressure)?false:props.showPressure;
    this.pressureReadOnly = (undefined === props.pressureReadOnly)?false:props.pressureReadOnly;

    this.showVolume = (undefined === props.showVolume)?false:props.showVolume;
    this.volumeReadOnly = (undefined === props.volumeReadOnly)?false:props.volumeReadOnly;
  }
  render(){
    return(
      <div>
        <GasPressureVolumeLabelsRow dataModel={this.dataModel} showPressure={this.showPressure} 
         volumeReadOnly = {this.volumeReadOnly}  pressureReadOnly = {this.pressureReadOnly}
         showVolume={this.showVolume}/>
        <GasPressureVolumeValuesRow dataModel = {this.dataModel} 
          showPressure={this.showPressure} 
          volumeReadOnly = {this.volumeReadOnly}
          pressureReadOnly = {this.pressureReadOnly}
          showVolume={this.showVolume}></GasPressureVolumeValuesRow>
          
      </div>
    )
  }
}



/**
 * GUI Component of gasModel or tankModel
 * @property (required) gasModel or tankModel
 * @property (optional) fractionReadOnly, showPressure, pressureReadOnly, showVolume, volumeReadOnly
 */
export class GasComponent extends React.Component{
  constructor(props){
    super(props);
    
    this.gasModel = props.gasModel;
    this.tankModel = props.tankModel;
    this.isTankModel = this.gasModel === undefined;
    if(this.gasModel === undefined) this.gasModel = this.tankModel;
    
    this.showTankVisualisation =  (undefined === props.showTankVisualisation)?true:props.showTankVisualisation;

    this.showFraction =  (undefined === props.showFraction)?true:props.showFraction;
    this.fractionReadOnly = (undefined === props.fractionReadOnly)?false:props.fractionReadOnly;
    
    this.bulkWrapper = (undefined === props.bulkWrapper)?false:props.bulkWrapper;
    
    this.showPressure = (undefined === props.showPressure)?true:props.showPressure;
    this.pressureReadOnly = (undefined === props.pressureReadOnly)?false:props.pressureReadOnly;
    
    this.showVolume = (undefined === props.showVolume)?true:props.showVolume;
    if (this.tankModel === undefined) this.showVolume = false; // gas has no volume attribute
    this.volumeReadOnly = (undefined === props.volumeReadOnly)?false:props.volumeReadOnly;
    
    this.state = {showInputFields: false };
    
  }
 
  propertyChange(propertyChangeEvent){
    // console.log("changed " + propertyChangeEvent.propertyName + "old:" + propertyChangeEvent.oldValue + 
    //   "new: " + propertyChangeEvent.newValue);
     this.setState({lastChangeEvent : propertyChangeEvent}); 
  }

  componentDidMount() {
    this.gasModel.addPropertyChangeListener(this);
    WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_SHOW_DETAILS, 
      WetNotesSettings.PROPERTY_REAL_GAS_MODEL]);
    if(this.isTankModel) setOfTankVolumes.addPropertyChangeListener(this);  
  }

  componentWillUnmount() {
    this.gasModel.removePropertyChangeListener(this);
    WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_SHOW_DETAILS,
      WetNotesSettings.PROPERTY_REAL_GAS_MODEL]);
    if(this.isTankModel) setOfTankVolumes.removePropertyChangeListener(this); 
  }
 
  handleO2RangeSet(e){
    let percents = WetNotes.ensurePercentValue(e.target.value);
    this.gasModel.setO2(percents);
  }

  handleHeRangeSet(e){
    let percents = WetNotes.ensurePercentValue(e.target.value);
    this.gasModel.setHe(percents);
  }
    
  render() {
    var divStyle = this.bulkWrapper?this.props.style:{};
    let retailWrapperClassName = "bg-white p-3 rounded-lg border mb-4 text-left";
    if( this.props.slimFit === undefined) retailWrapperClassName += " gasComponentMinWidth"
    const divClassName = this.bulkWrapper?"text-left":retailWrapperClassName;
    return (
    
      <div style={divStyle} className={divClassName}>
        <div className="text-left h5">{i18next.t(this.gasModel.getName())}</div>
        <div>
          <div className="row">
          {this.showTankVisualisation &&
            <div className="col-sm-2 text-left">
              <TankVisualisation gasModel={this.gasModel}></TankVisualisation>
            </div>
          }
            <div className="col-sm-10">
              {this.showFraction &&
              <div>
                <div>
                  <div>
                    <GasFractionLabelsRow></GasFractionLabelsRow>
                    <GasFractionsRow readOnly = {this.fractionReadOnly} gasModel={this.gasModel}></GasFractionsRow>
                  </div>
                </div>
                  { !this.fractionReadOnly && 
                    
                    <StandardGasPicker  targetGasModel={this.gasModel} ></StandardGasPicker>
                    
                  }
                </div>
              }
            {/* Pressure and Volume Controls */}
              <GasPressureVolumeControls 
                dataModel={this.gasModel} showPressure={this.showPressure}
                showVolume={this.showVolume} pressureReadOnly={this.pressureReadOnly} 
                volumeReadOnly={this.volumeReadOnly} />
            </div>
          </div>
        </div>
        { this.isTankModel && WetNotes.SETTINGS.isShowDetails() &&
          <div className="justify-content-center font-weight-light">
            <TankMasseInfo tankModel={this.tankModel}></TankMasseInfo>,&nbsp; 
            <TankGasAmountInfo tankModel={this.tankModel}></TankGasAmountInfo>,&nbsp;
            <TankBarliterInfo tankModel={this.tankModel}></TankBarliterInfo>
          </div>
        }
        {this.props.children}
      </div>

    )
  }
}


export function TankMasseInfo(props){
  return (
    <span>{i18next.t('Mass')}: <DisplayMass mass ={props.tankModel.getMasse()}></DisplayMass></span>
  )
}
export function TankGasAmountInfo(props){
  return (
    <span>{i18next.t('Amount')}: <DisplayAmount amount ={props.tankModel.getAmount_mol()}></DisplayAmount></span>
  )
}

/**
 * 
 * @param {*} props.tankModel 
 */
export function TankBarliterInfo(props){
  const tankModel = props.tankModel;
  return (
    <span>{WetNotes.barliterValueToView(tankModel).toFixed(1)} {WetNotes.barliterLabelToView()}</span>
  )
}

export function createAnId(){
  return "wtncmp_" + uuidv4().replace(/-/g, '');
}

export function amountToDisplay(amount){
  return WetNotes.formatAmount(amount);
}
export function amountVolumeToDisplay(tankModel){
  return WetNotes.barliterValueToView(tankModel).toFixed(1);
}
export function pressureToDisplay(pressureMetric){
  const displayValue = WetNotes.MEASSURES.Pressure.getAdjustedOfMetric(pressureMetric);
  return  WetNotes.formatPressure(displayValue);
}
/**
 * Display pressure with label in current units
 * @param props.pressure 
 */
export function DisplayPressure(props){
  const formatedValue = pressureToDisplay(props.pressure);
  return (
    <div className="d-inline">
      <span className="font-weight-bold">{formatedValue}</span> <span>{WetNotes.MEASSURES.Pressure.getLabel()}</span>
    </div>
    )
}

export function massToDisplay(massMetricValue){
  const displayValue = WetNotes.MEASSURES.Masse.getAdjustedOfMetric(massMetricValue);
  return WetNotes.formatMasse(displayValue);
}

/**
 * Display mass with label in current units
 * @param props.mass
 */
export function DisplayMass(props){
  const metricValue = props.mass;
  const displayValue = WetNotes.MEASSURES.Masse.getAdjustedOfMetric(metricValue);
  const formatedValue = WetNotes.formatMasse(displayValue);
  return (
    <div className="d-inline">
      <span>{formatedValue}</span> <span>{WetNotes.MEASSURES.Masse.getLabel()}</span>
    </div>
    )
}
/**
 * Display amount with label in current units
 * @param props.amount
 */
export function DisplayAmount(props){
  const formatedValue = amountToDisplay(props.amount);
  return (
    <div className="d-inline">
      <span>{formatedValue}</span> <span>{i18next.t('mole')}</span>
    </div>
    )
}

/**
 * Basic Component bound to WetModel for View and Edit mode
 * check an overwrite methods before render()
 */
export class WTN_Number_Model_Component extends React.Component{
  
  modelToView(){
    //return WetNotes.MEASSURES.Temperatur.getAdjustedOfMetric(WetNotes.SETTINGS.getTemperatureC());
  }
  formatToView(viewValue){
    //return WetNotes.formatTemperatur(viewValue);
  }


  componentDidMount() {
    //WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_TEMPERATURE, WetNotesSettings.PROPERTY_UNITS]);
  }

  componentWillUnmount() {
    //WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_TEMPERATURE, WetNotesSettings.PROPERTY_UNITS]);
  }
  isEditorValueValid(){
    // if(this.state.editValue === "") return false;
    return false;
  }
  applyEditValueOnModel(e){
    if (this.isEditorValueValid()){
      //const numberValue = Number.parseFloat(this.state.editValue);
     /* 
      let newNumberValue = WetNotes.MEASSURES.Temperatur.getMetricOfCurrent(numberValue);
      if(newNumberValue > WetNotes.SETTINGS.temperatureK_max - PhysicsCore.KELVIN_DELTA_CELSIUS) 
        newNumberValue = WetNotes.SETTINGS.temperatureK_max - PhysicsCore.KELVIN_DELTA_CELSIUS;
      if(newNumberValue < WetNotes.SETTINGS.temperatureK_min - PhysicsCore.KELVIN_DELTA_CELSIUS) 
        newNumberValue = WetNotes.SETTINGS.temperatureK_min - PhysicsCore.KELVIN_DELTA_CELSIUS;
      WetNotes.SETTINGS.setTemperatureC(newNumberValue);
      */
    }
  }
  getLabel(props){
    return "the Label";
  }
  render(props){
    return (
      <div className="form-group row">
        <label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>{this.getLabel(props)}</label>
        <div className="col-sm-7">
          {this.getValueControl(props)}
        </div>
      </div>
    )
  }

  /* Base implementation */
  getEditModeControl(props){
    const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
    return (
        <input id={this.id} className={"form-control "+ classNameAddOn}  required={true} type="number" autoFocus
          onFocus={(e) => this.handleOnFocus(e)}
          onChange={(e) => this.handleChange(e) } 
          onKeyUp={(e) => this.handleKeyUp(e)}
          onBlur={(e) => this.handleOnBlur(e)}
          value={this.state.editValue}>
        </input>
    )
  }
  constructor(props){
    super(props);
    this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
    this.state = {
    editMode: false, 
    editValue: this.formatToView(this.modelToView())
    };
    this.id = (undefined === props.id)?"bndvlcntrl_" + uuidv4().replace(/-/g, ''):props.id; 
  }
  propertyChange(propertyChangeEvent){
    this.setState({
      lastChangeEvent : propertyChangeEvent, 
      editValue: this.formatToView(this.modelToView())
    }); 
  }
  getViewModeControl(props){
    return (
      <span className="h4 form-control" onClick={ (e)=> this.startEditMode() }>{ this.state.editValue}</span>
    )
  }
  getValueControl(props){
    return (!this.readOnly && this.state.editMode)?this.getEditModeControl(props):this.getViewModeControl(props);
  }
  handleOnFocus(e){
    selectTextOfInputField(e)
  }
  startEditMode(){
    this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
  }
  handleOnBlur(e){
    // workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
    if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
    // end of workaround
  
    this.applyEditValueOnModel(e);
    this.editCompleted();
  }
  
  editCompleted(){
    this.setState({editMode : false, editValue: this.formatToView(this.modelToView())});
  }
  handleKeyUp(e){
    switch(e.keyCode){
      case KEY_ENTER:
        this.applyEditValueOnModel(e);
        this.editCompleted();
        break;
      case KEY_ESCAPE:
          this.editCompleted();
        break;
      default:
    }
  }

  handleChange(e){
    this.setState({ editValue:  e.target.value});
  }
}

export const selectTextOfInputField = (e) => {
  // https://stackoverflow.com/questions/3272089/programmatically-selecting-text-in-an-input-field-on-ios-devices-mobile-safari
  e.target.select();
  e.persist()
  setTimeout(function() {
      const originType = e.target.type
      e.target.type = 'text'
      e.target.setSelectionRange(0, e.target.value.length)
      e.target.type = originType
  }, 1);
    
}
/**
 * Basic Component bound to WetModel for View and Edit mode
 * check an overwrite methods before render()
 */
export class WTN_String_Model_Component extends React.Component{
  
  modelToView(){
    //return WetNotes.MEASSURES.Temperatur.getAdjustedOfMetric(WetNotes.SETTINGS.getTemperatureC());
  }
  formatToView(viewValue){
    //return WetNotes.formatTemperatur(viewValue);
  }


  componentDidMount() {
    //WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_TEMPERATURE, WetNotesSettings.PROPERTY_UNITS]);
  }

  componentWillUnmount() {
    //WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_TEMPERATURE, WetNotesSettings.PROPERTY_UNITS]);
  }
  isEditorValueValid(){
    // if(this.state.editValue === "") return false;
    return false;
  }
  applyEditValueOnModel(e){
    if (this.isEditorValueValid()){
      //const stringValue = this.state.editValue;
      // set something (stringValue)
    }
  }
  getLabel(props){
    return "the Label";
  }
  render(props){
    return (
      <div className="form-group row">
        <label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>{this.getLabel(props)}</label>
        <div className="col-sm-7">
          {this.getValueControl(props)}
        </div>
      </div>
    )
  }

  /* Base implementation */
  getEditModeControl(props){
    const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
    return (
        <input id={this.id} className={"form-control "+ classNameAddOn}  required={true} type="text" autoFocus
          onFocus={(e) => this.handleOnFocus(e)}
          onChange={(e) => this.handleChange(e) } 
          onKeyUp={(e) => this.handleKeyUp(e)}
          onBlur={(e) => this.handleOnBlur(e)}
          value={this.state.editValue}>
        </input>
    )
  }
  constructor(props){
    super(props);
    this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
    this.state = {
    editMode: false, 
    editValue: this.formatToView(this.modelToView())
    };
    this.id = (undefined === props.id)?"bndvlcntrl_" + uuidv4().replace(/-/g, ''):props.id; 
  }
  propertyChange(propertyChangeEvent){
    this.setState({
      lastChangeEvent : propertyChangeEvent, 
      editValue: this.formatToView(this.modelToView())
    }); 
  }
  getViewModeControl(props){
    return (
      <span className="h4 form-control" onClick={ (e)=> this.startEditMode() }>{ this.state.editValue}</span>
    )
  }
  getValueControl(props){
    return (!this.readOnly && this.state.editMode)?this.getEditModeControl(props):this.getViewModeControl(props);
  }
  handleOnFocus(e){
    e.target.select();
  }
  startEditMode(){
    this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
  }
  handleOnBlur(e){
    // workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
    if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
    // end of workaround
  
    this.applyEditValueOnModel(e);
    this.editCompleted();
  }
  
  editCompleted(){
    this.setState({editMode : false, editValue: this.modelToView()});
  }
  handleKeyUp(e){
    switch(e.keyCode){
      case KEY_ENTER:
        this.applyEditValueOnModel(e);
        this.editCompleted();
        break;
      case KEY_ESCAPE:
          this.editCompleted();
        break;
      default:
    }
  }

  handleChange(e){
    this.setState({ editValue:  e.target.value});
  }
}
export function WTNPanel(props){
  const retailWrapperClassName = "bg-white p-3 rounded-lg border mb-4";
  const bulkWrapperClassName = "p-3 mb-4";
  const divClassName = props.bulkWrapper?bulkWrapperClassName:retailWrapperClassName;
  return(
    <div style = {props.style} className={divClassName}>
      {props.children}
    </div>
  )
}