import i18next from 'i18next'
import React from 'react';

import { WetNotes, WetNotesSettings } from '../wetnotes/wetnotes';
import { PSCRResultBuilder } from './PSCRResultBuilder';
import { UnitsMeter, firefoxWorkaroundDelyinMsToIgnoreOnblure, KEY_ENTER, KEY_ESCAPE } from '../WTNCommons';
import { FormRowGroup } from '../SharedOptions';
import {
  AreaChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Area
}  from 'recharts';

const relevantSettings = [WetNotesSettings.PROPERTY_LANGUAGE_UI, WetNotesSettings.PROPERTY_MAX_END,
	WetNotesSettings.PROPERTY_MAX_PPO2, WetNotesSettings.PROPERTY_MIN_PPO2, WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_He_TO_N2,
	WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_O2_TO_N2, WetNotesSettings.PROPERTY_SHOW_DETAILS, WetNotesSettings.PROPERTY_UNITS];

const DEFAULT_DEPTH_METER = 21;
const DEPTH_MIN = 0;
const DEPTH_MAX = 248;
export class O2DropComponent extends React.Component {
	constructor(props){
		super(props);
		this.pscrModel = props.pscrModel;
		this.id = props.id !== undefined?props.id:'pscr_default_o2_drop_component';
		this.resultBuilder = new PSCRResultBuilder(this.pscrModel);
		this.state = this.loadState();
	}
	componentDidMount() {
		this.pscrModel.addPropertyChangeListener(this);
		WetNotes.SETTINGS.addPropertyChangeListener(this, relevantSettings);
	}
	componentWillUnmount() {
		this.pscrModel.removePropertyChangeListener(this);
		WetNotes.SETTINGS.removePropertyChangeListener(this, relevantSettings);
	}
	propertyChange(propertyChangeEvent){
    this.setState({lastChangeEvent : propertyChangeEvent}); 
	}
	loadState(){
		const loadedState = localStorage.getItem(this.id);
		if(loadedState === null) return { depth : DEFAULT_DEPTH_METER};
		return JSON.parse(loadedState);
	}
	setDepth(depth){
		this.setState({depth: depth});
		this.saveState();
	}
	
	saveState(){
		const persistentState = { depth : this.state.depth };
		localStorage.setItem(this.id, JSON.stringify(persistentState));
	}
	render() {
		const chartData = this.resultBuilder.buildChartData(this.state.depth);
		return (
			<div>
				<h5>{i18next.t('pscr.o2.drop.comp.title')}</h5>
				<FormRowGroup>
					<DepthLabel></DepthLabel>
					<DepthControl depth={this.state.depth} onSetDepth={ (newDepth ) => this.setDepth(newDepth)}></DepthControl>
					
				</FormRowGroup>
				<ChartO2Lowering chartData = { chartData }></ChartO2Lowering>
			</div>
		)
	}
}
function DepthLabel(props){
	return (
		<label className="control-label">{i18next.t('Depth')} (<UnitsMeter></UnitsMeter>)</label>
	)
}
class DepthControl extends React.Component{
	constructor(props){
		super(props);
		this.pscrModel = props.pscrModel;
		this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
		this.depth = props.depth;
		this.state = {
			editMode: false, 
			editValue: WetNotes.formatDepth(this.getModelValue4View())
		};
	}
	componentDidMount() {
		WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	
	componentWillUnmount() {
	  WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	setDepth(depth){
		this.depth = depth;
		this.props.onSetDepth(this.depth);
	}
	propertyChange(propertyChangeEvent){
		 this.setState({
			lastChangeEvent : propertyChangeEvent, 
			editValue: WetNotes.formatDepth(this.getModelValue4View())
		}); 
	}
	handleOnFocus(e){
		e.target.select();
	}
	startEditMode(){
		this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
	}
	handleOnBlur(e){
		// workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
		if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
		// end of workaround
		this.applyEditValue(e);
		this.editCompleted();
	}
	applyEditValue(e){
		if (this.isEditorValueValid()){
			const numberValue = Number.parseFloat(this.state.editValue);
			let newValue = WetNotes.MEASSURES.Depth.getMetricOfCurrent(numberValue);
			if(newValue > DEPTH_MAX) newValue = DEPTH_MAX;
			if(newValue < DEPTH_MIN) newValue = DEPTH_MIN;
			this.setDepth(newValue);
		}
	}
	editCompleted(){
		this.setState({editMode : false, editValue: WetNotes.formatDepth(this.getModelValue4View())});
	}
	handleKeyUp(e){
		switch(e.keyCode){
			case KEY_ENTER:
				this.applyEditValue(e);
				this.editCompleted();
				break;
			case KEY_ESCAPE:
					this.editCompleted();
				break;
			default:
		}
	}
	handleChange(e){
		this.setState({ editValue:  e.target.value});
	}
	handleRangeSet(e){
		const newValue = WetNotes.MEASSURES.Depth.getMetricOfCurrent(Number.parseFloat(e.target.value));
		this.setState({editValue : newValue});
		this.setDepth(newValue);
	}
	isEditorValueValid(){
		const editValue = this.state.editValue;
		if(this.state.editValue === "") return false;
		const numberValue = Number.parseFloat(editValue);
		if(numberValue < 0) return false;
		return true;
	}
	getModelValue4View(){
		return WetNotes.MEASSURES.Depth.getAdjustedOfMetric(this.depth);
	}
	render(){
		const classNameEditableValueViewMode = this.readOnly?"readonlyValue":"editableValue";
		const viewModelValue = this.getModelValue4View();
		const maxValue = WetNotes.MEASSURES.Depth.getAdjustedOfMetric(DEPTH_MAX);
		const minValue = WetNotes.MEASSURES.Depth.getAdjustedOfMetric(DEPTH_MIN);
		const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
		return (
			<div className={this.props.className}>
				{(this.readOnly || !this.state.editMode) &&
					<div className={classNameEditableValueViewMode + " form-control font-weight-bold"} onClick={ (e)=> this.startEditMode() }>{WetNotes.formatDepth(viewModelValue)}</div>
				}
				{(!this.readOnly && this.state.editMode) &&
					<div>
					<input className={"form-control "+ classNameAddOn}  required={true} type="number" autoFocus
								onFocus={(e) => this.handleOnFocus(e)}
								onChange={(e) => this.handleChange(e) } 
								onKeyUp={(e) => this.handleKeyUp(e)}
								onBlur={(e) => this.handleOnBlur(e)}
								value={this.state.editValue}>
							</input>
					</div>
				}
				{!this.readOnly && WetNotes.SETTINGS.isDisplaySliders() &&
					<div className="mt-2 mb-2">
						<input type="range" min={ minValue } max={ maxValue } step="1" 
							value={viewModelValue} onChange={(e) => this.handleRangeSet(e)} className="form-control-range"/>
					</div>
				}
			</div>
		)
	}
}
function ChartO2Lowering(props){
	return (
		<ResponsiveContainer width="100%" height={280}>
			<AreaChart  data={props.chartData}
					margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
				<defs>
					<linearGradient id="colorFiO2" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
						<stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
					</linearGradient>
					<linearGradient id="colorFiO2Stabilized" x1="0" y1="0" x2="0" y2="1">
						<stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
						<stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
					</linearGradient>
				</defs>
				<XAxis dataKey="name" />
				<YAxis label={{ value: i18next.t('fiO2.axis.label'), angle: -90, position: 'insideLeft' }}  padding={{ right: 40 }} />
				<CartesianGrid strokeDasharray="3 3" />
				<Tooltip />
				<Legend verticalAlign="top" height={36}/>
				<Area type="monotone" name= {i18next.t('pscr.lowering.fi.o2')} dataKey="fiO2" stroke="#8884d8" fillOpacity={1} fill="url(#colorFiO2)" />
				<Area type="monotone" name = {i18next.t('fiO2.established')} dataKey="fiO2Stabilized" stroke="#82ca9d" fillOpacity={1} fill="url(#colorFiO2Stabilized)" />
			</AreaChart>
		</ResponsiveContainer>
	)
}