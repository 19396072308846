import React from 'react';
import { WetNotes } from './wetnotes/wetnotes.js';
import IosThermometerOutline from 'react-ionicons/lib/IosThermometerOutline';
import i18next from 'i18next';
import { COLOR_WETNOTES } from './WTNCommons.jsx';

export function StartAtTemperaturInfo() {
  const viewValueOfTemperatur = WetNotes.formatTemperatur(WetNotes.MEASSURES.Temperatur.getAdjustedOfMetric(WetNotes.SETTINGS.getTemperatureC()));
  return (<span>
    <IosThermometerOutline color={COLOR_WETNOTES}/> {i18next.t('start.at.temperature')} {viewValueOfTemperatur} {WetNotes.MEASSURES.Temperatur.getLabel()}&#x2070;
      </span>);
}
