/* (c) Oleg Gurevich, 2019
 * This stuff here is copyrighted under MIT.
 * The original java code has been written by Michael Protogerakis
 * (c) Michael Protogerakis, 2010
 * This stuff here is copyrighted under LGPL.
 * In case of questions pls contact:
 * michael@protogerakis.de
 */

export class PartialGas {
    constructor(x, part) {
        this.x = x;
        this.part = part;
    }
}
export let GasCoefficient = {
    OXYGEN: {
        a: 137.8e-3,
        b: 0.0318e-3,
        M: 31.999,
        T: [100.00, 110.00,
            120.00, 130.00, 140.00, 150.00, 160.00, 170.00, 180.00, 190.00,
            200.00, 210.00, 220.00, 230.00, 240.00, 250.00, 260.00, 270.00,
            280.00, 290.00, 300.00, 310.00, 320.00, 330.00, 340.00, 350.00,
            360.00, 370.00, 380.00, 390.00, 400.00, 410.00, 420.00, 430.00,
            440.00, 450.00, 460.00, 470.00, 480.00, 490.00, 500.00, 510.00,
            520.00, 530.00, 540.00, 550.00, 560.00, 570.00, 580.00, 590.00,
            600.00, 610.00, 620.00, 630.00, 640.00, 650.00, 660.00, 670.00,
            680.00, 690.00, 700.00, 710.00, 720.00, 730.00, 740.00, 750.00,
            760.00, 770.00, 780.00, 790.00, 800.00, 810.00, 820.00, 830.00,
            840.00, 850.00, 860.00, 870.00, 880.00, 890.00, 900.00, 910.00,
            920.00, 930.00, 940.00, 950.00, 960.00, 970.00, 980.00, 990.00,
            1000.0
        ],
        cv: [20.885, 20.931, 20.937, 20.918, 20.895,
            20.875, 20.860, 20.849, 20.842, 20.839, 20.839, 20.843, 20.851,
            20.863, 20.879, 20.899, 20.925, 20.955, 20.991, 21.032, 21.078,
            21.130, 21.187, 21.248, 21.314, 21.385, 21.460, 21.539, 21.622,
            21.707, 21.796, 21.888, 21.981, 22.077, 22.175, 22.274, 22.374,
            22.475, 22.577, 22.679, 22.781, 22.883, 22.985, 23.087, 23.189,
            23.289, 23.390, 23.489, 23.587, 23.684, 23.781, 23.876, 23.969,
            24.062, 24.153, 24.243, 24.332, 24.419, 24.505, 24.589, 24.672,
            24.753, 24.834, 24.912, 24.989, 25.065, 25.140, 25.213, 25.285,
            25.355, 25.424, 25.492, 25.558, 25.623, 25.687, 25.750, 25.811,
            25.871, 25.931, 25.988, 26.045, 26.101, 26.156, 26.209, 26.262,
            26.313, 26.364, 26.413, 26.462, 26.510, 26.557
        ],
        name: "Oxygen"
    },
    HELIUM: {
        a: 3.45e-3,
        b: 0.0237e-3,
        M: 4.0026 * 2.0,
        T: [100.00,
            110.00, 120.00, 130.00, 140.00, 150.00, 160.00, 170.00,
            180.00, 190.00, 200.00, 210.00, 220.00, 230.00, 240.00,
            250.00, 260.00, 270.00, 280.00, 290.00, 300.00, 310.00,
            320.00, 330.00, 340.00, 350.00, 360.00, 370.00, 380.00,
            390.00, 400.00, 410.00, 420.00, 430.00, 440.00, 450.00,
            460.00, 470.00, 480.00, 490.00, 500.00, 510.00, 520.00,
            530.00, 540.00, 550.00, 560.00, 570.00, 580.00, 590.00,
            600.00, 610.00, 620.00, 630.00, 640.00, 650.00, 660.00,
            670.00, 680.00, 690.00, 700.00, 710.00, 720.00, 730.00,
            740.00, 750.00, 760.00, 770.00, 780.00, 790.00, 800.00,
            810.00, 820.00, 830.00, 840.00, 850.00, 860.00, 870.00,
            880.00, 890.00, 900.00, 910.00, 920.00, 930.00, 940.00,
            950.00, 960.00, 970.00, 980.00, 990.00, 1000.0
        ],
        cv: [12.475, 12.475, 12.474, 12.474, 12.474, 12.473,
            12.473, 12.473, 12.473, 12.473, 12.473, 12.473, 12.473,
            12.473, 12.472, 12.472, 12.472, 12.472, 12.472, 12.472,
            12.472, 12.472, 12.472, 12.472, 12.472, 12.472, 12.472,
            12.472, 12.472, 12.472, 12.472, 12.472, 12.472, 12.472,
            12.472, 12.472, 12.472, 12.472, 12.472, 12.472, 12.472,
            12.472, 12.472, 12.472, 12.472, 12.472, 12.472, 12.472,
            12.472, 12.472, 12.472, 12.472, 12.472, 12.472, 12.472,
            12.472, 12.472, 12.472, 12.472, 12.472, 12.472, 12.472,
            12.472, 12.472, 12.472, 12.472, 12.472, 12.472, 12.472,
            12.472, 12.472, 12.472, 12.472, 12.472, 12.472, 12.472,
            12.472, 12.472, 12.472, 12.472, 12.472, 12.472, 12.472,
            12.472
        ],
        name: "Helium"
    },
    ARGON: {
        a: 136.3e-3,
        b: 0.0322e-3,
        M: 39.948,
        T: [100.00, 110.00, 120.00, 130.00, 140.00,
            150.00, 160.00, 170.00, 180.00, 190.00, 200.00, 210.00,
            220.00, 230.00, 240.00, 250.00, 260.00, 270.00, 280.00,
            290.00, 300.00, 310.00, 320.00, 330.00, 340.00, 350.00,
            360.00, 370.00, 380.00, 390.00, 400.00, 410.00, 420.00,
            430.00, 440.00, 450.00, 460.00, 470.00, 480.00, 490.00,
            500.00, 510.00, 520.00, 530.00, 540.00, 550.00, 560.00,
            570.00, 580.00, 590.00, 600.00, 610.00, 620.00, 630.00,
            640.00, 650.00, 660.00, 670.00, 680.00, 690.00, 700.00
        ],
        cv: [12.807, 12.695, 12.628, 12.586, 12.558, 12.539,
            12.525, 12.515, 12.507, 12.501, 12.497, 12.493, 12.490,
            12.488, 12.486, 12.484, 12.483, 12.482, 12.481, 12.480,
            12.479, 12.478, 12.478, 12.477, 12.477, 12.477, 12.476,
            12.476, 12.476, 12.476, 12.475, 12.475, 12.475, 12.475,
            12.475, 12.474, 12.474, 12.474, 12.474, 12.474, 12.474,
            12.474, 12.474, 12.474, 12.474, 12.474, 12.474, 12.473,
            12.473, 12.473, 12.473, 12.473, 12.473, 12.473, 12.473,
            12.473, 12.473, 12.473, 12.473, 12.473, 12.473
        ],
        name: "Argon"
    },
    NEON: {
        a: 21.0e-3,
        b: 0.0169e-3,
        M: 20.1797,
        T: [100.00, 110.00,
            120.00, 130.00, 140.00, 150.00, 160.00, 170.00, 180.00,
            190.00, 200.00, 210.00, 220.00, 230.00, 240.00, 250.00,
            260.00, 270.00, 280.00, 290.00, 300.00, 310.00, 320.00,
            330.00, 340.00, 350.00, 360.00, 370.00, 380.00, 390.00,
            400.00, 410.00, 420.00, 430.00, 440.00, 450.00, 460.00,
            470.00, 480.00, 490.00, 500.00, 510.00, 520.00, 530.00,
            540.00, 550.00, 560.00, 570.00, 580.00, 590.00, 600.00,
            610.00, 620.00, 630.00, 640.00, 650.00, 660.00, 670.00,
            680.00, 690.00, 700.00
        ],
        cv: [12.479, 12.478,
            12.477, 12.477, 12.476, 12.476, 12.476, 12.475, 12.475,
            12.475, 12.475, 12.474, 12.474, 12.474, 12.474, 12.474,
            12.474, 12.474, 12.474, 12.474, 12.473, 12.473, 12.473,
            12.473, 12.473, 12.473, 12.473, 12.473, 12.473, 12.473,
            12.473, 12.473, 12.473, 12.473, 12.473, 12.473, 12.473,
            12.473, 12.473, 12.473, 12.473, 12.473, 12.473, 12.472,
            12.472, 12.472, 12.472, 12.472, 12.472, 12.472, 12.472,
            12.472, 12.472, 12.472, 12.472, 12.472, 12.472, 12.472,
            12.472, 12.472, 12.472
        ],
        name: "Neon"
    },
    HYDROGEN: {
        a: 25.0e-3,
        b: 0.0267e-3,
        M: 2.016,
        T: [103.00, 107.50, 112.00,
            116.50, 121.00, 125.50, 130.00, 134.50, 139.00, 143.50,
            148.00, 152.50, 157.00, 161.50, 166.00, 170.50, 175.00,
            179.50, 184.00, 188.50, 193.00, 197.50, 202.00, 206.50,
            211.00, 215.50, 220.00, 224.50, 229.00, 233.50, 238.00,
            242.50, 247.00, 251.50, 256.00, 260.50, 265.00, 269.50,
            274.00, 278.50, 283.00, 287.50, 292.00, 296.50, 301.00,
            305.50, 310.00, 314.50, 319.00, 323.50, 328.00, 332.50,
            337.00, 341.50, 346.00, 350.50, 355.00, 359.50, 364.00,
            368.50, 373.00, 377.50, 382.00, 386.50, 391.00, 395.50,
            400.00
        ],
        cv: [14.436, 14.701, 14.968, 15.235,
            15.501, 15.763, 16.019, 16.270, 16.513, 16.748, 16.975,
            17.194, 17.403, 17.603, 17.794, 17.977, 18.150, 18.315,
            18.472, 18.620, 18.761, 18.895, 19.021, 19.140, 19.253,
            19.360, 19.461, 19.556, 19.646, 19.731, 19.811, 19.887,
            19.958, 20.025, 20.088, 20.147, 20.203, 20.256, 20.305,
            20.351, 20.394, 20.435, 20.473, 20.508, 20.541, 20.572,
            20.600, 20.627, 20.652, 20.674, 20.695, 20.714, 20.732,
            20.749, 20.763, 20.777, 20.789, 20.801, 20.811, 20.820,
            20.829, 20.836, 20.843, 20.849, 20.855, 20.860, 20.865
        ],
        name: "Hydrogen"
    },
    CARBONDIOXID: {
        a: 362.0e-3,
        b: 0.0425e-3,
        M: 44.011,
        T: [298.15],
        cv: [37.11],
        name: "Carbondioxid"
    },
    NITROGEN: {
        a: 140.8e-3,
        b: 0.0391e-3,
        M: 28.013,
        T: [100.00, 110.00,
            120.00, 130.00, 140.00, 150.00, 160.00, 170.00, 180.00,
            190.00, 200.00, 210.00, 220.00, 230.00, 240.00, 250.00,
            260.00, 270.00, 280.00, 290.00, 300.00, 310.00, 320.00,
            330.00, 340.00, 350.00, 360.00, 370.00, 380.00, 390.00,
            400.00, 410.00, 420.00, 430.00, 440.00, 450.00, 460.00,
            470.00, 480.00, 490.00, 500.00, 510.00, 520.00, 530.00,
            540.00, 550.00, 560.00, 570.00, 580.00, 590.00, 600.00,
            610.00, 620.00, 630.00, 640.00, 650.00, 660.00, 670.00,
            680.00, 690.00, 700.00, 710.00, 720.00, 730.00, 740.00,
            750.00, 760.00, 770.00, 780.00, 790.00, 800.00, 810.00,
            820.00, 830.00, 840.00, 850.00, 860.00, 870.00, 880.00,
            890.00, 900.00, 910.00, 920.00, 930.00, 940.00, 950.00,
            960.00, 970.00, 980.00, 990.00, 1000.0
        ],
        cv: [21.049, 20.966, 20.916, 20.883, 20.862, 20.847, 20.836,
            20.828, 20.822, 20.818, 20.815, 20.812, 20.810, 20.809,
            20.809, 20.808, 20.809, 20.810, 20.812, 20.815, 20.819,
            20.823, 20.830, 20.837, 20.846, 20.857, 20.869, 20.884,
            20.900, 20.918, 20.939, 20.962, 20.987, 21.014, 21.043,
            21.075, 21.109, 21.146, 21.184, 21.225, 21.268, 21.313,
            21.360, 21.409, 21.459, 21.512, 21.566, 21.621, 21.678,
            21.737, 21.796, 21.857, 21.919, 21.982, 22.045, 22.110,
            22.175, 22.241, 22.307, 22.374, 22.442, 22.509, 22.577,
            22.645, 22.713, 22.781, 22.849, 22.917, 22.985, 23.053,
            23.121, 23.188, 23.256, 23.322, 23.389, 23.455, 23.521,
            23.586, 23.651, 23.715, 23.779, 23.842, 23.905, 23.967,
            24.029, 24.090, 24.150, 24.210, 24.270, 24.328, 24.386
        ],
        name: "Nitrogen"
    }
};
export class RealGas {
    /** J / (mol K) - allgemeine Gaskonstante in SI-Units
     */
    static get R() { return 8.31451; }
    static get MAX_ITERATE() { return 1000; } // Has to be replaced with better function
    static get MAX_FEHLER() { return 2e-15; }
    static get T_h_bezug() { return 298.15; }
    static get p_h_bezug() { return 1.013e5; }
    static getR() {
        return this.R;
    }
    static Z(T, p, a, b) {
            return (p * this.V_m3(p, 1.0, T, a, b) / (this.R * T));
        }
        /** calculates a parameter for van der waals calculations
         * @param partialgasmix
         * @return a parameter for van der waals calculations
         */
    static eval_a(partialgasmix) {
            let sum = 0;
            for (let i = 0; i <= (partialgasmix.length - 1); i++) {
                for (let j = 0; j <= (partialgasmix.length - 1); j++) {
                    sum = sum + Math.sqrt(partialgasmix[i].part.a * partialgasmix[j].part.a) * partialgasmix[i].x *
                        partialgasmix[j].x;
                }
            }
            return sum;
        }
        /**
         * @param partialgasmix - array of PartialGas values
         * @return returns the molar mass
         */
    static eval_M(partialgasmix) {
            let sum = 0;
            for (let i = 0; i <= (partialgasmix.length - 1); i++) {
                sum = sum + partialgasmix[i].x * partialgasmix[i].part.M;
            }
            return sum;
        }
        /** calculates b parameter for van der waals calculations
         * @param partialgasmix - array of PartialGas values
         * @return b parameter for van der waals calculations
         */
    static eval_b(partialgasmix) {
        let sum = 0;
        for (let i = 0; i <= (partialgasmix.length - 1); i++) {
            for (let j = 0; j <= (partialgasmix.length - 1); j++) {
                sum = sum + Math.sqrt(partialgasmix[i].part.b * partialgasmix[j].part.b) * partialgasmix[i].x *
                    partialgasmix[j].x;
            }
        }
        return sum;
    }
    static p_Pa(V, n, T, a, b) {
        return (((n * this.R * T) / (V - (b * n))) - ((a * n * n) / (V * V)));
    }
    static T_K(p, V, n, a, b) {
        return ((p + a * n * n / (V * V)) * (V - b * n) / (n * this.R));
    }
    static V_m3(p, n, T, a, b) {
        let x = (n * this.R * T / p); // Startwert der Iteration mit der ideales
        // Gasgleichung
        let x1;
        /*
         * % 2 2 Iteration mit NewtonV. % a n 2 a n (V - b n) Dies ist die
         * Ableitung % p + ---- - ---------------- von f nach V % 2 3 % V V
         */
        let i = 0;
        while (i < this.MAX_ITERATE) {
            i++;
            x1 = x -
                (this.f(p, x, n, T, a, b) / (p + (a * n * n / (x * x)) - (2 *
                    a * n * n * (x - b * n) / (x * x * x))));
            if (Math.abs(x - x1) < this.MAX_FEHLER) {
                break;
            }
            x = x1;
        }
        return x;
    }
    static n_mol(p, V, T, a, b) {
        let x = (p * V / (this.R * T)); // Startwert aus IGG
        let x1 = x;
        /*
         * / 2 \ Iteration mit NewtonV. % 2 a n (V - b n) | a n | Dies ist die
         * Ableitung von f % --------------- - R T - b | p + ---- | nach n % 2 |
         * 2 | % V \ V /
         */
        let i = 0;
        while (i < this.MAX_ITERATE) {
            i++;
            x1 = x -
                (this.f(p, V, x, T, a, b) / ((2 * a * x * (V - b * x) / (V * V)) -
                    this.R * T - b * (p + (a * x * x / (V * V)))));
            if (Math.abs(x - x1) < this.MAX_FEHLER) { // Das geht auch besser - aber
                // wie ??
                break;
            }
            x = x1;
        }
        return x;
    }
    static f(p, V, n, T, a, b) {
        return ((p + a * n * n / (V * V)) * (V - b * n) - (n * this.R * T));
    }
}