/* 
 * Components for shared options (settings) 
 *
 * Oleg Gurevich, 2019
 */

import React from 'react';
import  {WetNotes, WetNotesSettings}  from './wetnotes/wetnotes.js'

import { PhysicsCore } from './wetnotes/PhysicsCore'
import IosThermometerOutline from 'react-ionicons/lib/IosThermometerOutline'
import { createAnId, COLOR_WETNOTES, WTN_Number_Model_Component, WTN_String_Model_Component } from './WTNCommons'
import i18next from 'i18next';

import MdSwap from 'react-ionicons/lib/MdSwap'

export class OptionGasCalcMode extends React.Component{
    constructor(props){
      super(props);
     
      this.id = (undefined === props.id)?createAnId():props.id; 
    }
    componentDidMount() {
      WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_REAL_GAS_MODEL]);
    }
  
    componentWillUnmount() {
      WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_REAL_GAS_MODEL]);
    }
    propertyChange(propertyChangeEvent){
      this.setState({
        lastChangeEvent : propertyChangeEvent
      }); 
    }
    render(props){
      return (
        <div className="form-group row">
          <label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>{i18next.t('Gas model')}</label>
          <div className="col-sm-7">
            <div className="input-group" onClick={() => WetNotes.SETTINGS.setRealGasModel(!WetNotes.SETTINGS.isRealGasModel())}>
                <div className="input-group-prepend">
                  <div className="input-group-text"><MdSwap color={COLOR_WETNOTES} ></MdSwap></div>
                </div>
                <div className="form-control">{WetNotes.SETTINGS.isRealGasModel()?i18next.t('Real gas (vdW)'):i18next.t('Ideal gas')}</div>
            </div>
          </div>
        </div>
      )
    }
  }

export class OptionLanguageUI extends React.Component{
  constructor(props){
    super(props);
    this.id = (undefined === props.id)?createAnId():props.id; 
  }
  componentDidMount() {
    WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_LANGUAGE_UI]);
  }

  componentWillUnmount() {
    WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_LANGUAGE_UI]);
  }
  propertyChange(propertyChangeEvent){
    this.setState({
      lastChangeEvent : propertyChangeEvent
    }); 
  }
  render(props){
   	const options = [];
		options.push(<option key={0} value={'en'}>English</option>)
		options.push(<option key={1} value={'de'}>Deutsch</option>)
		options.push(<option key={2} value={'ua'}>Українська</option>)
		options.push(<option key={3} value={'ru'}>Русский</option>)
 
    return (
      <div className="form-group row">
      
        <label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>
        {i18next.t('Language')} </label>
        <div className="col-sm-7">
          <select id={this.id} className="form-control" value={WetNotes.SETTINGS.getLanguageUI()} 
              onChange={(event) => WetNotes.SETTINGS.setLanguageUI(event.target.value)}>
							{ options }
          </select>
          
        </div>
      </div>
    )
  }
}
export class OptionUnits extends React.Component{
    constructor(props){
      super(props);
     
      this.id = (undefined === props.id)?createAnId():props.id; 
    }
    componentDidMount() {
      WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS]);
    }
  
    componentWillUnmount() {
      WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS]);
    }
    propertyChange(propertyChangeEvent){
      this.setState({
        lastChangeEvent : propertyChangeEvent
      }); 
    }
    render(props){
      return (
        <div className="form-group row">
          <label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>{i18next.t('Units')}</label>
          <div className="col-sm-7">
            <div className="input-group" onClick={() => WetNotes.SETTINGS.setMetricSystem(!WetNotes.SETTINGS.isMetricSystem())}>
              <div className="input-group-prepend">
                <div className="input-group-text"><MdSwap color={COLOR_WETNOTES} ></MdSwap></div>
              </div>
              <div 
              className="form-control ">{WetNotes.SETTINGS.isMetricSystem()?i18next.t('Metric system'):i18next.t('Imperial system')}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  

export class OptionShowDetails extends React.Component{
    constructor(props){
      super(props);
     
      this.id = (undefined === props.id)?createAnId():props.id; 
    }
    componentDidMount() {
      WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_SHOW_DETAILS]);
    }
  
    componentWillUnmount() {
      WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_SHOW_DETAILS]);
    }
    propertyChange(propertyChangeEvent){
      this.setState({
        lastChangeEvent : propertyChangeEvent
      }); 
    }
    render(props){
      return (
        <div className="form-group row">
          <label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>{i18next.t('Show details')}</label>
          <div className="col-sm-7">
            <div className="input-group" onClick={() => WetNotes.SETTINGS.setShowDetails(!WetNotes.SETTINGS.isShowDetails())}>
              <div className="input-group-prepend">
                <div className="input-group-text"><MdSwap color={COLOR_WETNOTES} ></MdSwap></div>
              </div>
              <div className="form-control">{WetNotes.SETTINGS.isShowDetails()?i18next.t('verbose'):i18next.t('normal')} 
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
export class OptionDisplaySliders extends React.Component{
    constructor(props){
      super(props);
     
      this.id = (undefined === props.id)?createAnId():props.id; 
    }
    componentDidMount() {
      WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
    }
  
    componentWillUnmount() {
      WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
    }
    propertyChange(propertyChangeEvent){
      this.setState({
        lastChangeEvent : propertyChangeEvent
      }); 
    }
    render(props){
      return (
        <div className="form-group row">
          <label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>{i18next.t('display.sliders')}</label>
          <div className="col-sm-7">
            <div className="input-group" onClick={() => WetNotes.SETTINGS.setDisplaySliders(!WetNotes.SETTINGS.isDisplaySliders())}>
              <div className="input-group-prepend">
                <div className="input-group-text"><MdSwap color={COLOR_WETNOTES} ></MdSwap></div>
              </div>
              <div className="form-control">{WetNotes.SETTINGS.isDisplaySliders()?i18next.t('show'):i18next.t('hide')} 
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  
export class TemperatureControl extends WTN_Number_Model_Component{
    constructor(props){
      super(props);
      this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
      this.state = {
      editMode: false, 
      editValue: this.formatToView(this.modelToView())
    };
    }
    modelToView(){
      return WetNotes.MEASSURES.Temperatur.getAdjustedOfMetric(WetNotes.SETTINGS.getTemperatureC());
    }
    formatToView(viewValue){
      return WetNotes.formatTemperatur(viewValue);
    }
  
  
    componentDidMount() {
      WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_TEMPERATURE, WetNotesSettings.PROPERTY_UNITS]);
    }
  
    componentWillUnmount() {
      WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_TEMPERATURE, WetNotesSettings.PROPERTY_UNITS]);
    }
    isEditorValueValid(){
      if(this.state.editValue === "") return false;
      return true;
    }
    applyEditValueOnModel(e){
      if (this.isEditorValueValid()){
        const numberValue = Number.parseFloat(this.state.editValue);
        let newNumberValue = WetNotes.MEASSURES.Temperatur.getMetricOfCurrent(numberValue);
        if(newNumberValue > WetNotes.SETTINGS.temperatureK_max - PhysicsCore.KELVIN_DELTA_CELSIUS) 
          newNumberValue = WetNotes.SETTINGS.temperatureK_max - PhysicsCore.KELVIN_DELTA_CELSIUS;
        if(newNumberValue < WetNotes.SETTINGS.temperatureK_min - PhysicsCore.KELVIN_DELTA_CELSIUS) 
          newNumberValue = WetNotes.SETTINGS.temperatureK_min - PhysicsCore.KELVIN_DELTA_CELSIUS;
        WetNotes.SETTINGS.setTemperatureC(newNumberValue);
      }
    }
    getLabel(props){
      return (
        <span>
          <IosThermometerOutline></IosThermometerOutline> {i18next.t('Temperature')} {WetNotes.MEASSURES.Temperatur.getLabel()}&#x2070;
        </span>
      )
    }
  }
  export class OptionPriceHe extends WTN_Number_Model_Component{
    constructor(props){
      super(props);
      this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
      this.state = {
      editMode: false, 
      editValue: this.formatToView(this.modelToView())
    };
    }
    formatToView(viewValue){
      return WetNotes.formatPrice(viewValue);
    }
    
    componentDidMount() {
      WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_PRICE_HE, WetNotesSettings.PROPERTY_UNITS]);
    }
    
    componentWillUnmount() {
      WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_PRICE_HE, WetNotesSettings.PROPERTY_UNITS]);
    }
    isEditorValueValid(){
      if(this.state.editValue === "") return false;
      return true;
    }
    modelToView(){
      return WetNotes.priceBarliterToView(WetNotes.SETTINGS.getPriceHe());
    }
    applyEditValueOnModel(e){
      if (this.isEditorValueValid()){
        const numberValue = Number.parseFloat(this.state.editValue);
        let newNumberValue = numberValue;
        if(numberValue < 0) newNumberValue = 0;
        if (!WetNotes.SETTINGS.isMetricSystem()) {
          newNumberValue = WetNotes.MEASSURES.LiterCuft.convert2Imperial(newNumberValue / WetNotes.getPriceUnitsFactor());
        }
        WetNotes.SETTINGS.setPriceHe(newNumberValue);
      }
    }
    getLabel(props){
      return (
        <span>
         {i18next.t('Price He per ') + WetNotes.MEASSURES.BarLiter.getLabel()}
        </span>
      )
    }
  }
  export class OptionPriceN2 extends WTN_Number_Model_Component{
    constructor(props){
      super(props);
      this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
      this.state = {
      editMode: false, 
      editValue: this.formatToView(this.modelToView())
    };
    }
    formatToView(viewValue){
      return WetNotes.formatPrice(viewValue);
    }
    
    componentDidMount() {
      WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_PRICE_N2, WetNotesSettings.PROPERTY_UNITS]);
    }
    
    componentWillUnmount() {
      WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_PRICE_N2, WetNotesSettings.PROPERTY_UNITS]);
    }
    isEditorValueValid(){
      if(this.state.editValue === "") return false;
      return true;
    }
    modelToView(){
      return WetNotes.priceBarliterToView(WetNotes.SETTINGS.getPriceN2());
    }
    applyEditValueOnModel(e){
      if (this.isEditorValueValid()){
        const numberValue = Number.parseFloat(this.state.editValue);
        let newNumberValue = numberValue;
        if(numberValue < 0) newNumberValue = 0;
        if (!WetNotes.SETTINGS.isMetricSystem()) {
          newNumberValue = WetNotes.MEASSURES.LiterCuft.convert2Imperial(newNumberValue / WetNotes.getPriceUnitsFactor());
        }
        WetNotes.SETTINGS.setPriceN2(newNumberValue);
      }
    }
    getLabel(props){
      return (
        <span>
         {i18next.t('Price N2 per ') + WetNotes.MEASSURES.BarLiter.getLabel()}
        </span>
      )
    }
  }
  export class OptionPriceO2 extends WTN_Number_Model_Component{
    constructor(props){
      super(props);
      this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
      this.state = {
      editMode: false, 
      editValue: this.formatToView(this.modelToView())
    };
    }
   
    formatToView(viewValue){
      return WetNotes.formatPrice(viewValue);
    }
  
    componentDidMount() {
      WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_PRICE_O2, WetNotesSettings.PROPERTY_UNITS]);
    }
  
    componentWillUnmount() {
      WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_PRICE_O2, WetNotesSettings.PROPERTY_UNITS]);
    }
    isEditorValueValid(){
      if(this.state.editValue === "") return false;
      return true;
    }
    modelToView(){
      return WetNotes.priceBarliterToView(WetNotes.SETTINGS.getPriceO2());
    }
    applyEditValueOnModel(e){
      if (this.isEditorValueValid()){
        const numberValue = Number.parseFloat(this.state.editValue);
        let newNumberValue = numberValue;
        if(numberValue < 0) newNumberValue = 0;
        if (!WetNotes.SETTINGS.isMetricSystem()) {
          newNumberValue = WetNotes.MEASSURES.LiterCuft.convert2Imperial(newNumberValue / WetNotes.getPriceUnitsFactor());
        }
        WetNotes.SETTINGS.setPriceO2(newNumberValue);
      }
    }
    getLabel(props){
      return (
        <span>
          {i18next.t('Price O2 per ') + WetNotes.MEASSURES.BarLiter.getLabel()}
        </span>
      )
    }
  }
  export class OptionCurrencyLabel extends WTN_String_Model_Component{
    constructor(props){
      super(props);
      this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
      this.state = {
      editMode: false, 
      editValue: this.modelToView()
    };
    }
   
    componentDidMount() {
      WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_CURRENCY_LABEL]);
    }
  
    componentWillUnmount() {
      WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_CURRENCY_LABEL]);
    }
    isEditorValueValid(){
      if(this.state.editValue === "" || this.state.editValue.length > 12) return false;
      if(!isNaN(this.state.editValue)) return false;
      return true;
    }
    modelToView(){
      return WetNotes.SETTINGS.getCurrencyLabel();
    }
    applyEditValueOnModel(e){
      if (this.isEditorValueValid()){
        WetNotes.SETTINGS.setCurrencyLabel(this.state.editValue);
      }
    }
    getLabel(props){
      return (
        <span>
          {i18next.t('Currency symbol')}
        </span>
      )
    }
  }
  export class OptionPriceTopoff extends WTN_Number_Model_Component{
    constructor(props){
      super(props);
      this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
      this.state = {
        editMode: false, 
        editValue: this.formatToView(this.modelToView())
      };
    }
    formatToView(viewValue){
      return WetNotes.formatPrice(viewValue);
    }
    componentDidMount() {
      WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_PRICE_TopOff, WetNotesSettings.PROPERTY_TopOff_flat_price, WetNotesSettings.PROPERTY_UNITS]);
    }
    
    componentWillUnmount() {
      WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_PRICE_O2, WetNotesSettings.PROPERTY_TopOff_flat_price, WetNotesSettings.PROPERTY_UNITS]);
    }
    isEditorValueValid(){
      if(this.state.editValue === "") return false;
      return true;
    }
    modelToView(){
      if(WetNotes.SETTINGS.isPriceFlatModeTopoff())  return WetNotes.SETTINGS.getPriceTopoff();
      return WetNotes.priceBarliterToView(WetNotes.SETTINGS.getPriceTopoff());
    }
    applyEditValueOnModel(e){
      if (this.isEditorValueValid()){
        const numberValue = Number.parseFloat(this.state.editValue);
        let newNumberValue = numberValue;
        if(numberValue < 0) newNumberValue = 0;
        if (!WetNotes.SETTINGS.isPriceFlatModeTopoff() && !WetNotes.SETTINGS.isMetricSystem()) {
          newNumberValue = WetNotes.MEASSURES.LiterCuft.convert2Imperial(newNumberValue / WetNotes.getPriceUnitsFactor());
        }
        WetNotes.SETTINGS.setPriceTopoff(newNumberValue);
      }
    }
    getLabel(props){
      const pricePerBarliter = i18next.t('Price Topoff per ') + WetNotes.MEASSURES.BarLiter.getLabel();
      const priceFlat = i18next.t('Topoff fee');
      let labelText = (WetNotes.SETTINGS.isPriceFlatModeTopoff()?priceFlat:pricePerBarliter);
      return (
        <span>
          {labelText}
        </span>
      )
    }
  }
  export class OptionFlatPriceTopoff extends React.Component{
    constructor(props){
      super(props);
     
      this.id = (undefined === props.id)?createAnId():props.id; 
    }
    componentDidMount() {
      WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_TopOff_flat_price, WetNotesSettings.PROPERTY_UNITS]);
    }
  
    componentWillUnmount() {
      WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_TopOff_flat_price, WetNotesSettings.PROPERTY_UNITS]);
    }
    propertyChange(propertyChangeEvent){
      this.setState({
        lastChangeEvent : propertyChangeEvent
      }); 
    }
    render(props){
      const pricePerBarliter = i18next.t('per ') + WetNotes.MEASSURES.BarLiter.getLabel();
      return (
        
        <div className="form-group row">
          <label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>{i18next.t('Topoff fee')}</label>
          <div className="col-sm-7">
          <div className="input-group" onClick={() => WetNotes.SETTINGS.setPriceFlatModeTopoff(!WetNotes.SETTINGS.isPriceFlatModeTopoff())}>
                <div className="input-group-prepend">
                  <div className="input-group-text"><MdSwap color={COLOR_WETNOTES} ></MdSwap></div>
                </div>
                <div className="form-control">{WetNotes.SETTINGS.isPriceFlatModeTopoff()?i18next.t('Flat'):pricePerBarliter}</div>
            </div>
          </div>
        </div>
        
      )
    }
  }
  /**
   * children[1] - control
   * @param labelText - id of control
   * @param controlId - id of control
   */
  export function FormRowGroup(props){
    return(
      <div className="form-group row">
        <label className="col-sm-5 col-form-label text-sm-right" >{props.children[0]}</label>
        <div className="col-sm-7">
        {props.children[1]}
        </div>
      </div>
      )
  }