/* 
 *
 * Oleg Gurevich, 2019
 */

import React from 'react';

import { COLOR_WETNOTES } from '../WTNCommons'
import { useTranslation } from 'react-i18next';
import { OptionUnits, OptionGasCalcMode, TemperatureControl, OptionShowDetails, OptionLanguageUI } from '../SharedOptions'

import MdSettings from 'react-ionicons/lib/MdSettings'


export function TopoffSettings(props){
  const { t } = useTranslation();
  return (
    <div className="bg-white p-3 rounded-lg border mb-4">
      <h5><MdSettings fontSize="2em" color={COLOR_WETNOTES}></MdSettings> {t('Topoff Options')}</h5>
      <div className="row">
        <div className="col-lg">
          <OptionGasCalcMode></OptionGasCalcMode>
          <TemperatureControl></TemperatureControl>
        </div>
        <div className="col-lg">
          <OptionShowDetails></OptionShowDetails>
          <OptionUnits></OptionUnits>
          <OptionLanguageUI></OptionLanguageUI>
        </div>
      </div>
    </div>
  )
}


