import React from 'react';
import i18next from 'i18next';
import IosThermometerOutline from 'react-ionicons/lib/IosThermometerOutline';
import IosTrendingUp from 'react-ionicons/lib/IosTrendingUp';
import { IsochoreComponent } from './IsochoreComponent.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function IconHeatedTank(props) {
  return (<span>
    <IosTrendingUp color="red"></IosTrendingUp>
    <IosThermometerOutline color="red"></IosThermometerOutline>
  </span>);
}
export class IsochoreDialogToggler extends React.Component{
  constructor(props){
    super(props);
    this.state = { modal: false };
  }
  toggle(){
    this.setState({modal : !this.state.modal});
  }
  render(){
  return (<span>
    <button type="button" className="btn btn-outline-warning btn-sm" title={i18next.t('isochore.comp.title')}
      onClick = { (e) => this.toggle() }>
      <IconHeatedTank></IconHeatedTank>
    </button>
    <Modal isOpen={this.state.modal} toggle={ () => this.toggle()}>
      <ModalHeader toggle={ () => this.toggle()}>
        <IconHeatedTank></IconHeatedTank> {i18next.t('isochore.comp.modal.title')}
      </ModalHeader>
      <ModalBody className="backgroundColor">
        <IsochoreComponent tankModel={this.props.tankModel}></IsochoreComponent>
      </ModalBody>
      <ModalFooter  className="backgroundColor">
       <button type="button" className="btn btn-secondary" onClick = { (e) => this.toggle() }>{i18next.t('close')}</button>
      </ModalFooter>
    </Modal>
  </span>);
}
}
