import { WetModel, GasModel } from "../wetnotes/wetnotes";
import { DiverModel } from "../wetnotes/Diver";
import { EnvironmentModel } from "../wetnotes/Environment";

export class PSCRModel extends WetModel{
	static get PROPERTY_VOLUME_RATIO() { return "prop_pscr_volume_ratio" };
	static get PROPERTY_DUMP_RATIO_OF_INJECTED_GAS() { return "prop_pscr_dump_ratio_of_injected_gas" };
	static get PROPERTY_VOLUME() { return "prop_pscr_volume" };
	static get PROPERTY_PSCR_CHANGED() { return "prop_pscr_changed" };
	constructor(){
		super();
		this.volume_dm3 = 5.5;
		this.volumeRatio = 10; // 5 .. 15
		this.dumpPercentOfInjectedGas = 0; // 0 .. 15

		this.gasModel = new GasModel(32, 0, 200).setName("pscr.supply.gas");
		this.diverModel = new DiverModel();
		this.environmentModel = new EnvironmentModel();
		this.installListeners();
		
	}
	propertyChange(propertyChangeEvent){
		this.propertyChangeSupport.firePropertyChange(PSCRModel.PROPERTY_PSCR_CHANGED, false, true);
	}
	installListeners() {
		this.addPropertyChangeListener(this, relevantProperties);
		this.gasModel.addPropertyChangeListener(this);
		this.diverModel.addPropertyChangeListener(this);
		this.environmentModel.addPropertyChangeListener(this);
	}

	setStateJSON(jsonState = {}){
		if(jsonState.gasModel !== undefined) this.gasModel.setStateJSON(jsonState.gasModel);
		if(jsonState.diverModel !== undefined) this.diverModel.setStateJSON(jsonState.diverModel);
		if(jsonState.environmentModel !== undefined) this.environmentModel.setStateJSON(jsonState.environmentModel);

		if(jsonState.volume_dm3 !== undefined) this.setVolume_dm3(jsonState.volume_dm3);
		if(jsonState.volumeRatio !== undefined) this.setVolumeRatio(jsonState.volumeRatio);
		if(jsonState.dumpPercentOfInjectedGas !== undefined) this.setDumpPercentOfInjectedGas(jsonState.dumpPercentOfInjectedGas);
	}
	getStateJSON(){
		return {
			gasModel : this.gasModel.getStateJSON(),
			diverModel : this.diverModel.getStateJSON(),
			environmentModel : this.environmentModel.getStateJSON(),

			volume_dm3 : this.getVolume_dm3(),
			volumeRatio : this.getVolumeRatio(),
			dumpPercentOfInjectedGas : this.getDumpPercentOfInjectedGas()
		}
	}
	getVolume_dm3(){ return this.volume_dm3 }
	getDumpRatioOfInjectedGas(){ return this.getDumpPercentOfInjectedGas() / 100.0 }
	getDumpPercentOfInjectedGas(){ return this.dumpPercentOfInjectedGas }
	getVolumeRatio(){ return this.volumeRatio }

	setVolume_dm3(newValue) {
		let oldValue = this.volume_dm3;
		this.volume_dm3 = newValue;
		this.propertyChangeSupport.firePropertyChange(PSCRModel.PROPERTY_VOLUME, oldValue, newValue);
		return this;
	}
	setVolumeRatio(newValue) {
		let oldValue = this.volumeRatio;
		this.volumeRatio = newValue;
		this.propertyChangeSupport.firePropertyChange(PSCRModel.PROPERTY_VOLUME_RATIO, oldValue, newValue);
		return this;
	}
	setDumpPercentOfInjectedGas(newValue) {
		let oldValue = this.dumpPercentOfInjectedGas;
		this.dumpPercentOfInjectedGas = newValue;
		this.propertyChangeSupport.firePropertyChange(PSCRModel.PROPERTY_DUMP_RATIO_OF_INJECTED_GAS, oldValue, newValue);
		return this;
	}
	getFiO2AtTime(depth_meter, timeInSeconds){
		if(timeInSeconds === 0) return this.gasModel.getFiO2();
		const pAmbInBar = this.environmentModel.getPressureAtDepth_Bar(depth_meter);
		const fiO2 = this.gasModel.getFiO2();
		const dumpedVolume = this.diverModel.getRmv_dm3() * (1.0 / this.getVolumeRatio()) * pAmbInBar * timeInSeconds / 60.0;
		const consumedVolume = this.diverModel.getO2Consumption_dm3() * timeInSeconds / 60.0;
		const degree = -1 * dumpedVolume * (1 - this.getDumpRatioOfInjectedGas()) / this.getVolume_dm3() / pAmbInBar;
		const summand1=(fiO2 - ((dumpedVolume * (1 - this.getDumpRatioOfInjectedGas()) + consumedVolume) * 
			fiO2 - consumedVolume )/(dumpedVolume * (1 - this.getDumpRatioOfInjectedGas())) ) * Math.exp(degree);
		const fiO2AtTime=((dumpedVolume * (1 - this.getDumpRatioOfInjectedGas()) + consumedVolume)* fiO2 - consumedVolume ) / 
			(dumpedVolume * (1 - this.getDumpRatioOfInjectedGas())) + summand1;
		if(fiO2AtTime < 0) return 0;
		return fiO2AtTime;
	}

	/**
	 * @return  established O2 fraction in loop of pscr
	 */
	getEstablishedFiO2(depth_meter){
		const pAmbInBar = this.environmentModel.getPressureAtDepth_Bar(depth_meter);
		const dumpedVolume = this.diverModel.getRmv_dm3() * (1.0 / this.getVolumeRatio()) * pAmbInBar;
		const fiO2 = this.gasModel.getFiO2();
		const establishedFiO2=((dumpedVolume * (1 - this.getDumpRatioOfInjectedGas()) + this.diverModel.getO2Consumption_dm3()) *
				fiO2 - this.diverModel.getO2Consumption_dm3() ) / (dumpedVolume * (1 - this.getDumpRatioOfInjectedGas()));
		if(establishedFiO2 < 0) return 0;
		return establishedFiO2;
	}

}
const relevantProperties = [PSCRModel.PROPERTY_VOLUME, PSCRModel.PROPERTY_VOLUME_RATIO, PSCRModel.PROPERTY_DUMP_RATIO_OF_INJECTED_GAS];