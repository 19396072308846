exports.__esModule = true;
/* (c) Oleg Gurevich, 2019
 * This stuff here is copyrighted under MIT.
 */
/* exported PropertyChangeSupport */
var PropertyChangeSupport = /** @class */ (function () {
    function PropertyChangeSupport(source) {
        this.source = source;
        this.registeredListeners = [];
    }
    PropertyChangeSupport.prototype.addPropertyChangeListener = function (listener, property_name) {
        var _this = this;
        if (property_name === void 0) { property_name = null; }
        if (property_name == null) {
            this.registeredListeners.push({ propertyName: null, propertyChangeListener: listener });
        }
        else {
            var lsnr_1 = listener;
            property_name.forEach(function (propertyName) {
                _this.registeredListeners.push({ 'propertyName': propertyName, 'propertyChangeListener': lsnr_1 });
            });
        }
    };
    PropertyChangeSupport.prototype.removePropertyChangeListener = function (listener, property_name) {
        var _this = this;
        if (property_name === void 0) { property_name = null; }
        if (property_name == null) {
            this.removeSubscription({ 'propertyName': null, 'propertyChangeListener': listener });
        }
        else {
            var lsnr_2 = listener;
            property_name.forEach(function (propertyName) {
                _this.removeSubscription({ 'propertyName': propertyName, 'propertyChangeListener': lsnr_2 });
            });
        }
    };
    PropertyChangeSupport.prototype.removeSubscription = function (subscription) {
        var foundIndex = this.findRegisteredSubscription(subscription);
        if (foundIndex >= 0)
            this.registeredListeners.splice(foundIndex, 1);
    };
    PropertyChangeSupport.prototype.findRegisteredSubscription = function (subscription) {
        var indexFound = -1;
        this.registeredListeners.forEach(function (subscriber, index) {
            if (subscription.propertyName == null && subscriber.propertyName == null) {
                if (subscription.propertyChangeListener === subscriber.propertyChangeListener) {
                    indexFound = index;
                    return;
                }
            }
            else {
                if (subscriber.propertyName === subscription.propertyName &&
                    subscription.propertyChangeListener === subscriber.propertyChangeListener) {
                    indexFound = index;
                    return;
                }
            }
        });
        return indexFound;
    };
    PropertyChangeSupport.prototype.firePropertyChange = function (property_name, oldValue, newValue) {
        if (property_name == null || oldValue === newValue)
            return;
        var eventToBeFired = new PropertyChangeEvent(this.source, property_name, oldValue, newValue);
        this.registeredListeners.forEach(function (subscription) {
            if (subscription.propertyName == null ||
                property_name === subscription.propertyName) {
                subscription.propertyChangeListener.propertyChange(eventToBeFired);
            }
        });
    };
    return PropertyChangeSupport;
}());
exports.PropertyChangeSupport = PropertyChangeSupport;
var PropertyChangeEvent = /** @class */ (function () {
    function PropertyChangeEvent(source, propertyName, oldValue, newValue) {
        this.source = source;
        this.propertyName = propertyName;
        this.oldValue = oldValue;
        this.newValue = newValue;
    }
    PropertyChangeEvent.prototype.getSource = function () { return this.source; };
    PropertyChangeEvent.prototype.getOldValue = function () { return this.oldValue; };
    PropertyChangeEvent.prototype.getNewValue = function () { return this.newValue; };
    PropertyChangeEvent.prototype.getPropertyName = function () { return this.propertyName; };
    return PropertyChangeEvent;
}());
exports.PropertyChangeEvent = PropertyChangeEvent;
