import React from 'react';

import i18next from 'i18next';
// import { PSCRModel } from "./PSCRModel";
import imgPSCR from '../pscr.png';
import { VolumeRatioLabel, VolumeRatioComponent, VolumeLabel, VolumeComponent, 
  DumpPercentOfInjectedGasLabel, DumpPercentOfInjectedGasComponent } from './PSCR';
import { WetNotes, WetNotesSettings } from '../wetnotes/wetnotes';

export class PSCRComponent extends React.Component{
  constructor(props){
    super(props);
    
    this.pscrModel = props.pscrModel;
    this.nameI18n = (undefined === props.nameI18n)?"noname":props.nameI18n;
    
		this.volumeReadOnly = (undefined === props.volumeReadOnly)?false:props.volumeReadOnly;
		this.dumpratioReadOnly = (undefined === props.dumpratioReadOnly)?false:props.dumpratioReadOnly;
		this.dumpratioOfInjectedReadOnly = (undefined === props.dumpratioOfInjectedReadOnly)?false:props.dumpratioOfInjectedReadOnly;
    
    this.state = { showInputFields: false };
    
  }
 	propertyChange(propertyChangeEvent){
    this.setState({lastChangeEvent : propertyChangeEvent}); 
  }

  componentDidMount() {
		WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS]);
  }

  componentWillUnmount() {
		WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS]);
  }
    
  render() {
    return (
      <div className={this.props.className}>
        <h5>{i18next.t(this.nameI18n)}</h5>
        <div className = "text-left">
          <div className="row">
            <div className="col-sm-3">
              <PSCRVisualisation pscrModel={this.pscrModel}></PSCRVisualisation>
            </div>
            <div className="col-sm-9">
              <div className="row">
                <div className="col-6">
                  	<VolumeRatioLabel></VolumeRatioLabel>
                </div>
								<div className="col-6">
                  	<VolumeLabel></VolumeLabel>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
										<VolumeRatioComponent pscrModel = {this.pscrModel} className="h5"></VolumeRatioComponent>
                </div>
								<div className="col-6">
										<VolumeComponent pscrModel = {this.pscrModel} className="h5"></VolumeComponent>
                </div>
              </div>
							<div className="row">
                <div className="col-6">
                  	<DumpPercentOfInjectedGasLabel></DumpPercentOfInjectedGasLabel>
										<DumpPercentOfInjectedGasComponent className="h5" pscrModel = {this.pscrModel}></DumpPercentOfInjectedGasComponent>
                </div>
              </div>
            	
            </div>
          </div>
        </div>
        {this.props.children}
      </div>

    )
  }
}
function PSCRVisualisation(props){
	return(
		<div className="d-none d-sm-block text-center position-relative">
			<img src={imgPSCR} alt="PSCR" />
		</div>
	)
}