import { WetModel, WetNotes, WetNotesSettings } from "./wetnotes";
import i18next from "i18next";
import React from 'react';
import { firefoxWorkaroundDelyinMsToIgnoreOnblure, KEY_ENTER, KEY_ESCAPE, UnitsMeter } from "../WTNCommons";
import { PhysicsCore } from "./PhysicsCore";

const ELEVATION_MAX = 4600;
const ELEVATION_MIN = 0;

export class EnvironmentModel extends WetModel{
	static get PROPERTY_ELEVATION() { return "prop_elevation" };
	constructor(){
		super();
		this.elevation = 0;
		this.pressureAtSurface_Bar = PhysicsCore.BAR_IN_ONE_ATA;
		this.pressureAtSurface_Pa = PhysicsCore.PaOfATA;
	}
	getElevation() { return this.elevation }
	setStateJSON(jsonState = {}){
		if(jsonState.elevation !== undefined) this.setElevation(jsonState.elevation);
		return this;
	}
	getStateJSON(){
		return {
			elevation : this.getElevation()
		}
	}
	getPressureAtSurface_Bar(){
		return this.pressureAtSurface_Bar;
	}
	getPressureOnSurface_Pa(){
		return this.pressureAtSurface_Pa;
	}
	getPressureAtDepth_Pa(depth_meters){
		const pressurePa = this.pressureAtSurface_Pa + depth_meters * 10000.0;
		return pressurePa;
	}
	getPressureAtDepth_Bar(depth_meters){
		const pressureBar = this.pressureAtSurface_Bar + depth_meters * 0.1;
		return pressureBar;
	}
	setElevation(newValue) {
		let oldValue = this.elevation;
		this.elevation = newValue;
		this.pressureAtSurface_Bar = PhysicsCore.getAirPressureAtElevationBar(this.elevation);
		this.pressureAtSurface_Pa = PhysicsCore.getAirPressureAtElevationPa(this.elevation);
		this.propertyChangeSupport.firePropertyChange(EnvironmentModel.PROPERTY_ELEVATION, oldValue, newValue);
		return this;
	}
}
export class ElevationComponent extends React.Component{
	constructor(props){
		super(props);
		this.environmentModel = props.environmentModel;
		this.readOnly = (props.readOnly === undefined)?false:props.readOnly;
		this.state = {
			editMode: false, 
			editValue: WetNotes.formatDepth(this.getModelValue4View())
		};
	}
	propertyChange(propertyChangeEvent){
		 this.setState({
			lastChangeEvent : propertyChangeEvent, 
			editValue: WetNotes.formatDepth(this.getModelValue4View())
		}); 
	}

	componentDidMount() {
		this.environmentModel.addPropertyChangeListener(this, [EnvironmentModel.PROPERTY_ELEVATION]);
		WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	
	componentWillUnmount() {
		this.environmentModel.removePropertyChangeListener(this, [EnvironmentModel.PROPERTY_RMV]);
		WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_DISPLAY_SLIDERS]);
	}
	handleOnFocus(e){
		e.target.select();
	}
	startEditMode(){
		this.setState({editMode : true, editModeBeginTimestamp : Date.now()});
	}
	handleOnBlur(e){
		// workaround for firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1057858
		if(Date.now() - this.state.editModeBeginTimestamp < firefoxWorkaroundDelyinMsToIgnoreOnblure) return;
		// end of workaround

		this.applyEditValue(e);
		this.editCompleted();
	}
	applyEditValue(e){
		if (this.isEditorValueValid()){
			const numberValue = Number.parseFloat(this.state.editValue);
			let newElevation = WetNotes.MEASSURES.Depth.getMetricOfCurrent(numberValue);
			if(newElevation > ELEVATION_MAX) newElevation = ELEVATION_MAX;
			if(newElevation < ELEVATION_MIN) newElevation = ELEVATION_MIN;
			this.environmentModel.setElevation(newElevation);
		}
	}
	editCompleted(){
		this.setState({editMode : false, editValue: WetNotes.formatDepth(this.getModelValue4View())});
	}
	handleKeyUp(e){
		switch(e.keyCode){
			case KEY_ENTER:
				this.applyEditValue(e);
				this.editCompleted();
				break;
			case KEY_ESCAPE:
					this.editCompleted();
				break;
			default:
		}
	}
	handleChange(e){
		this.setState({ editValue:  e.target.value});
	}
	handleRangeSet(e){
		const newElevation = WetNotes.MEASSURES.Depth.getMetricOfCurrent(Number.parseFloat(e.target.value));
		this.environmentModel.setElevation(newElevation);
	}
	isEditorValueValid(){
		const editValue = this.state.editValue
		if(this.state.editValue === "") return false;
		const numberValue = Number.parseFloat(editValue);
		if(numberValue < 0) return false;
		return true;
	}
	getModelValue4View(){
		return WetNotes.MEASSURES.Depth.getAdjustedOfMetric(this.environmentModel.getElevation());
	}
	render(){
		const classNameEditableValueViewMode = this.readOnly?"readonlyValue":"editableValue";
		const viewModelValue = this.getModelValue4View();
		const maxValue = WetNotes.MEASSURES.Depth.getAdjustedOfMetric(ELEVATION_MAX);
		const minValue = WetNotes.MEASSURES.Depth.getAdjustedOfMetric(ELEVATION_MIN);
		const classNameAddOn = this.isEditorValueValid()?"":"is-invalid";
		return (
			<div className={this.props.className}>
				{(this.readOnly || !this.state.editMode) &&
					<div className = {classNameEditableValueViewMode} onClick={ (e)=> this.startEditMode() }>{WetNotes.formatVolume(viewModelValue)}</div>
				}
				{(!this.readOnly && this.state.editMode) &&
					<div>
					<input className={"form-control "+ classNameAddOn}  required={true} type="number" autoFocus
								onFocus={(e) => this.handleOnFocus(e)}
								onChange={(e) => this.handleChange(e) } 
								onKeyUp={(e) => this.handleKeyUp(e)}
								onBlur={(e) => this.handleOnBlur(e)}
								value={this.state.editValue}>
							</input>
					</div>
				}
				{!this.readOnly && WetNotes.SETTINGS.isDisplaySliders() &&
					<div className="mt-2 mb-2">
						<input type="range" min={ minValue } max={ maxValue } step="1" 
							value={viewModelValue} onChange={(e) => this.handleRangeSet(e)} className="form-control-range"/>
					</div>
				}
			</div>
		)
	}
}
export function ElevationLabel(props){
  const labelText = i18next.t('environment.elevation');
  return (
        <label className="control-label">{labelText} (<UnitsMeter></UnitsMeter>)</label>
  )
}