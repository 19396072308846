/* 
 * gas blender toolkit
 * DeltaGas - continous blending
 *
 * Oleg Gurevich, 2019
 */
import React, { Component } from 'react';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next'

import  { WetNotes, WetNotesSettings }  from '../wetnotes/wetnotes.js'
import { GasComponent, COLOR_WETNOTES, amountVolumeToDisplay, WTNPanel, pressureToDisplay } from '../WTNCommons'

import MdSettings from 'react-ionicons/lib/MdSettings'
import MdClose from 'react-ionicons/lib/MdClose'
import MdArrowRoundForward from 'react-ionicons/lib/MdArrowRoundForward'

import { DeltaGasModel } from './deltaGasModel.js';
import { DeltaGasSettings } from './DeltaGasSettings.jsx';
import { IsochoreDialogToggler } from '../wetnotes/IsochoreDialogToggler.jsx';
import { StartAtTemperaturInfo } from "../StartAtTemperaturInfo";



export class DeltaGas extends Component {
  constructor(props){
    super(props);
    this.id = props.id?props.id:'defaultDeltaGasComponent';
    this.deltaGasModel = new DeltaGasModel();
    this.restoreStateOfDeltaGas();
    this.state = { toggleSettingsOn : false};
  }
  restoreStateOfDeltaGas(){
    const loadedState = localStorage.getItem(this.id);
    if(loadedState !== null){
      this.deltaGasModel.setStateJSON(JSON.parse(loadedState));
    }
  }
  componentDidMount() {
    this.deltaGasModel.addPropertyChangeListener(this, [DeltaGasModel.PROPERTY_DELTAGAS_CALCULATED]);
  }
  componentWillUnmount() {
    this.deltaGasModel.removePropertyChangeListener(this, [DeltaGasModel.PROPERTY_DELTAGAS_CALCULATED]);
  }
  propertyChange(propertyChangeEvent){
    this.saveStateOfDeltaGas();
    // this.setState({lastChangeEvent: propertyChangeEvent});
  }
  saveStateOfDeltaGas(){
    localStorage.setItem(this.id, JSON.stringify(this.deltaGasModel.getStateJSON()));
	}
	render() {
    const rowToggledOn = "row";
		const rowToggledOff = "row d-none d-lg-flex";
    return (
            <div className = "container p-0 mt-2 text-left">
            <h4>{i18next.t('Continuous blending (Delta gas)')}</h4>
            <div className={this.state.toggleSettingsOn?rowToggledOff:rowToggledOn}>
              <div className = "col-sm">
                <GasComponent tankModel = {this.deltaGasModel.tankIs} ></GasComponent>
              </div>
							<div className = "col-sm">
                <GasComponent tankModel = {this.deltaGasModel.tankDesired} showVolume={false} ></GasComponent>
              </div>
              <div className = "col-sm">
              <GasComponent tankModel = {this.deltaGasModel.deltaGas} fractionReadOnly = {true} 
                showPressure={false} showVolume={false}>
              </GasComponent>
              </div>
            </div>

              <div className={this.state.toggleSettingsOn?rowToggledOff:rowToggledOn}>
                <div className = "col-sm">
                <WTNPanel>
                 <DeltaGasResults deltaGasModel = {this.deltaGasModel}></DeltaGasResults>
                </WTNPanel>
                </div>
              </div>
              <div className={this.state.toggleSettingsOn?rowToggledOn:rowToggledOff}>
                  <div className = "col-sm">
                    <DeltaGasSettings></DeltaGasSettings>
                  </div>
              </div>
             
            
            <div className="wtn-settings-toggler d-inline-block d-lg-none" onClick={e => this.setState({toggleSettingsOn: !this.state.toggleSettingsOn})}>
							{this.state.toggleSettingsOn?
								<MdClose fontSize="3em" color={COLOR_WETNOTES}></MdClose>:<MdSettings fontSize="3em" color={COLOR_WETNOTES}></MdSettings>
							}
            </div>
            </div>
    )
  }
}
function CompactResultRow(props){
  const { t } = useTranslation();
  const bar =  WetNotes.MEASSURES.Pressure.getLabel();
  const barliter = WetNotes.barliterLabelToView();
  const dataModelRow = props.dataModelRow;
  const amountInfo = dataModelRow.tankAmountVolume.length > 0 &&
    <span>{t('Added amount')}: {dataModelRow.tankAmountVolume} {barliter}</span>
  const mixtureInfo = dataModelRow.actionMix.length > 0 &&
    <span> {t('blending mixture')} ({dataModelRow.actionMix}) </span>
  return(
    <li><span className="font-weight-bold">{dataModelRow.action} {mixtureInfo} {dataModelRow.deltaPressure} {bar} 
      &nbsp;<MdArrowRoundForward></MdArrowRoundForward> {dataModelRow.tankPressure} {bar} &nbsp;
      { dataModelRow.tankModel !== undefined &&
        <IsochoreDialogToggler tankModel = {dataModelRow.tankModel}></IsochoreDialogToggler>
      }</span><br/>
      {amountInfo}</li>
  )
}
function TableResults(props){
  const dataModel = props.dataModel;
  return (
    <table className="table table-striped">
       <ResultTableHead></ResultTableHead>
      <tbody>
      {
        dataModel.map((item, index) => (<ResultTableRow dataModelTableRow={item} stepNumber = { index + 1 }></ResultTableRow>))
      }
          </tbody>
      </table>
  )
}
function ResultTableHead(props){
  const { t } = useTranslation();
  const bar =  " (" + WetNotes.MEASSURES.Pressure.getLabel() + ")";
  const barliter = " (" + WetNotes.barliterLabelToView() + ")";
  return(
    <thead>
        <tr>
          <th scope="col" className="align-top">{t('Action')}</th>
          <th scope="col" className="align-top">{t('Mixture')}</th>
          <th scope="col" className="align-top">{t('Add') + bar}</th>
          <th scope="col" className="align-top border-left">{t('Tank pressure') + bar}</th>
          <th scope="col" className="align-top">{i18next.t('warming')}</th>
          <th scope="col" className="align-top">{t('Check mix')}</th>
          <th scope="col" className="align-top border-left">{t('Added amount') + barliter}</th>
        </tr>
      </thead>
  )
}
const ResultsRelatedSettigs = [WetNotesSettings.PROPERTY_SHOW_DETAILS, WetNotesSettings.PROPERTY_UNITS,
  WetNotesSettings.PROPERTY_CURRENCY_LABEL, WetNotesSettings.PROPERTY_PRICE_HE, WetNotesSettings.PROPERTY_PRICE_N2, WetNotesSettings.PROPERTY_PRICE_O2];
class DeltaGasResults extends Component{
  constructor(props){
    super(props);
    this.deltaGasModel = props.deltaGasModel;
    this.state = {lastChangeEvent : null};
  }
  componentDidMount() {
    this.deltaGasModel.addPropertyChangeListener(this, [DeltaGasModel.PROPERTY_DELTAGAS_CALCULATED]);
    WetNotes.SETTINGS.addPropertyChangeListener(this, ResultsRelatedSettigs)
  }
  componentWillUnmount() {
    this.deltaGasModel.removePropertyChangeListener(this, [DeltaGasModel.PROPERTY_DELTAGAS_CALCULATED]);
    WetNotes.SETTINGS.removePropertyChangeListener(this, ResultsRelatedSettigs)
  }
  propertyChange(propertyChangeEvent){
    this.setState({lastChangeEvent : propertyChangeEvent});
  }
 
  buildResultDataModel(){
    const showDropGas = this.deltaGasModel.tankIs.getAmount_mol() !== this.deltaGasModel.tankIsCalculated.getAmount_mol();
    const dataModelSteps = [];
    const toleranceDelta = 0.08;
    
    if(showDropGas){
      dataModelSteps.push(this.buildDropRow());
    }
    if(this.deltaGasModel.deltaGas.getAmount_mol() > toleranceDelta){
      dataModelSteps.push(this.buildAddGasRow());
    }
    return dataModelSteps;
  }

  getJsonTableRow(){
    return {
      action : "",
      actionMix : "",
      deltaPressure : "",
      tankPressure : "",
      tankMix : "",
      tankAmountVolume : ""
    }
  }

  buildDropRow(){
    const dataRow = this.getJsonTableRow();
    dataRow.action = i18next.t('Drop');
    //dataRow.actionMix = '-';
    const deltaPressure = this.deltaGasModel.tankIsCalculated.getPressure_Bar() - this.deltaGasModel.tankIs.getPressure_Bar();
    dataRow.deltaPressure = pressureToDisplay(deltaPressure);
  
    const dropedPressure = this.deltaGasModel.tankIsCalculated.getPressure_Bar();
    dataRow.tankPressure = pressureToDisplay(dropedPressure);
    dataRow.tankMix = this.deltaGasModel.tankIsCalculated.getMixString();
    dataRow.tankModel = this.deltaGasModel.tankIsCalculated.clone();
    //dataRow.tankAmountVolume = '-';
    return dataRow;
  }
  
  buildAddGasRow(){
    const dataRow = this.getJsonTableRow();
   
    dataRow.action = i18next.t('Add');
    dataRow.actionMix  = this.deltaGasModel.deltaGas.getMixString();
    
    const deltaPressure = this.deltaGasModel.tankDesired.getPressure_Bar() - this.deltaGasModel.tankIsCalculated.getPressure_Bar();
    dataRow.deltaPressure = pressureToDisplay(deltaPressure);
    
    dataRow.tankPressure = pressureToDisplay(this.deltaGasModel.tankDesired.getPressure_Bar());
    dataRow.tankMix = this.deltaGasModel.tankDesired.getMixString();
    dataRow.tankAmountVolume = amountVolumeToDisplay(this.deltaGasModel.deltaGas);
    dataRow.tankModel = this.deltaGasModel.tankDesired.clone();
    
    return dataRow;
  }

  render(){
    const dataModel = this.buildResultDataModel();
    return (
      <div>
        <h5>{i18next.t('Blender results')}</h5>
        <h6><StartAtTemperaturInfo/></h6>
        <div className="d-lg-block d-none">
          <TableResults dataModel = {dataModel}></TableResults>
        </div>
        <div className="d-lg-none">
          <CompactResults dataModel = {dataModel}></CompactResults>
        </div>
        <Costs deltaGasModel = {this.deltaGasModel}></Costs>
      </div>
    )
  }
}
function CompactResults(props){
  const dataModel = props.dataModel;
  return (
    <ul>
      {
        dataModel.map((item) => (<CompactResultRow dataModelRow={item}></CompactResultRow>))
      }
    </ul>
  )
}
function Costs(props){
  const { t } = useTranslation();
  const deltaGasModel = props.deltaGasModel;
  const priceInfos = [];
  let   priceTotal = 0;
  const priceO2 = WetNotes.SETTINGS.getPriceO2();
  const priceHe = WetNotes.SETTINGS.getPriceHe();
  const priceN2 = WetNotes.SETTINGS.getPriceN2();

  const amountO2 = deltaGasModel.deltaGas.getFiO2() * deltaGasModel.deltaGas.getAmount_mol(); 
  const amountO2BarLiter = deltaGasModel.deltaGas.getFiO2() * deltaGasModel.deltaGas.getBarLiter(); 
  const tankO2 = deltaGasModel.deltaGas.clone().setName(t('Oxygen')).applyGasFraction(DeltaGasModel.OXYGEN);
  tankO2.setAmount_mol(amountO2);
  if(priceO2 > 0 && amountO2 > 0){
    const costO2 = priceO2 * amountO2BarLiter;
    priceTotal += costO2;
    priceInfos.push({ 
      position: tankO2.getName(),
      amount: WetNotes.barliterValueToView(tankO2).toFixed(2),
      amountUnits: WetNotes.barliterLabelToView(),
      price : WetNotes.priceBarliterToView(priceO2).toFixed(4),
      costs: costO2.toFixed(4)
    })
  }

  const amountHe = deltaGasModel.deltaGas.getFiHe() * deltaGasModel.deltaGas.getAmount_mol(); 
  const amountHeBarLiter = deltaGasModel.deltaGas.getFiHe() * deltaGasModel.deltaGas.getBarLiter(); 
  const tankHe = deltaGasModel.deltaGas.clone().setName(t('Helium')).applyGasFraction(DeltaGasModel.HELIUM);
  tankHe.setAmount_mol(amountHe);
  if(priceHe > 0 && amountHe > 0){
    const costHe = priceHe * amountHeBarLiter;
    priceTotal += costHe;
    priceInfos.push({ 
      position: tankHe.getName(),
      amount: WetNotes.barliterValueToView(tankHe).toFixed(2),
      amountUnits: WetNotes.barliterLabelToView(),
      price : WetNotes.priceBarliterToView(priceHe).toFixed(4),
      costs: costHe.toFixed(4)
    })
  }

  const amountN2 = deltaGasModel.deltaGas.getFiN2() * deltaGasModel.deltaGas.getAmount_mol(); 
  const amountN2BarLiter = deltaGasModel.deltaGas.getFiN2() * deltaGasModel.deltaGas.getBarLiter(); 
  const tankN2 = deltaGasModel.deltaGas.clone().setName(t('Nitrogen')).applyGasFraction(DeltaGasModel.NITROGEN);
  tankN2.setAmount_mol(amountN2);
  if(priceN2 > 0 && amountN2 > 0){
    const costN2 = priceN2 * amountN2BarLiter;
    priceTotal += costN2;
    priceInfos.push({ 
      position: tankN2.getName(),
      amount: WetNotes.barliterValueToView(tankN2).toFixed(2),
      amountUnits: WetNotes.barliterLabelToView(),
      price : WetNotes.priceBarliterToView(priceN2).toFixed(4),
      costs: costN2.toFixed(4)
    })
  }

  if(deltaGasModel.deltaGas.getAmount_mol() === 0) return null;
  
  return(
    <div>
      <h5>{t('Costs')}</h5>
      { 
        priceInfos.map((item) => (<div className="row"><div className="col-3">{item.position}: </div><div className="col-9"> {item.price} * {item.amount} {item.amountUnits} = {item.costs} {WetNotes.SETTINGS.getCurrencyLabel()}</div></div>))
      }
      <div className="h5">{t('Total')}: {priceTotal.toFixed(2)} {WetNotes.SETTINGS.getCurrencyLabel()}</div>
    </div>
  )
}
function ResultTableRow(props){
  const dataModelTableRow = props.dataModelTableRow;
  const noWrapStyle = { "whiteSpace" : "nowrap"};
  return(
    <tr>
      <th scope="row">{dataModelTableRow.action}</th>
      <th scope="row" style = {noWrapStyle}>{dataModelTableRow.actionMix}</th>
      <td>{dataModelTableRow.deltaPressure}</td>
      <th scope="row" className="border-left">
      {dataModelTableRow.tankPressure}
      </th>
      <th>
        { dataModelTableRow.tankModel !== undefined &&
        <IsochoreDialogToggler tankModel = {dataModelTableRow.tankModel}></IsochoreDialogToggler>
        }
      </th>
      <td style={noWrapStyle}>{dataModelTableRow.tankMix}</td>
      <td className="border-left">{dataModelTableRow.tankAmountVolume}</td>
    </tr>
  )
}