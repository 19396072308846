/* 
 * Components for blender results displaying
 *
 * Oleg Gurevich, 2019
 */

import React from 'react';
import  {WetNotes, WetNotesSettings, BlendingMode}  from '../wetnotes/wetnotes.js'

import { createAnId, COLOR_WETNOTES } from '../WTNCommons'
import i18next from 'i18next';
import { OptionShowDetails, TemperatureControl, OptionUnits, OptionGasCalcMode, OptionPriceHe, OptionPriceO2, OptionPriceTopoff, OptionFlatPriceTopoff, OptionCurrencyLabel, OptionLanguageUI } from '../SharedOptions'

import MdSettings from 'react-ionicons/lib/MdSettings'



export function BlenderSettings(props){
  // var divStyle = {  minWidth: '24em'};
  return (
    // <div style = {divStyle}  className="bg-white p-3 rounded-lg border mb-4">
    <div className="bg-white p-3 rounded-lg border mb-4">
      <h5><MdSettings fontSize="2em" color={COLOR_WETNOTES}></MdSettings> {i18next.t('Blender Options')}</h5>
      <div className="row">
        <div className="col-lg">
        <OptionBlendingMode></OptionBlendingMode>
        <OptionGasCalcMode></OptionGasCalcMode>
        <TemperatureControl></TemperatureControl>
        </div>
        <div className="col-lg">
        <OptionShowDetails></OptionShowDetails>
        <OptionUnits></OptionUnits>
        <OptionLanguageUI></OptionLanguageUI>
        <OptionCurrencyLabel></OptionCurrencyLabel>
        </div>
      </div>
      <h5>{i18next.t('Price calculation')}</h5>
      <div className="row">
        <div className="col-lg">
        <OptionPriceHe></OptionPriceHe>
        <OptionPriceO2></OptionPriceO2>
        </div>
        <div className="col-lg">
        <OptionFlatPriceTopoff></OptionFlatPriceTopoff>
        <OptionPriceTopoff></OptionPriceTopoff>
        </div>
      </div>
    </div>
  )
}



export class OptionBlendingMode extends React.Component{
  constructor(props){
    super(props);
   
    this.id = (undefined === props.id)?createAnId():props.id; 
  }
  componentDidMount() {
    WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_BLENDING_MODE]);
  }

  componentWillUnmount() {
    WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_BLENDING_MODE]);
  }
  propertyChange(propertyChangeEvent){
    this.setState({
      lastChangeEvent : propertyChangeEvent
    }); 
  }
  render(props){
    return (
      <div className="form-group row">
        <label className="col-sm-5 col-form-label text-sm-right" htmlFor={this.id}>{i18next.t('Fill order')}</label>
        <div className="col-sm-7">
          <select id={this.id} className="form-control" value={WetNotes.SETTINGS.getBlendingMode()} 
              onChange={(event) => WetNotes.SETTINGS.setBlendingMode(event.target.value)}>
           <option value={BlendingMode.He_O2_TopOff}>{i18next.t('He, O2, Topoff')}</option>
           <option value={BlendingMode.O2_He_TopOff}>{i18next.t('O2, He, Topoff')}</option>
           <option value={BlendingMode.O2_TopOff_He}>{i18next.t('O2, Topoff, He')}</option>
           <option value={BlendingMode.He_AutoEAN}>{i18next.t('He, Auto EAN(21% - 45%)')}</option>
          </select>
          
        </div>
      </div>
    )
  }
}

