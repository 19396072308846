import React from 'react';
export function PrivacyPolicyContentRu(props) {
  return (<div>
    <h3>Политика конфиденциальности</h3>
    <p>Дата вступления в силу: 20 апреля 2019 г.</p>


    <p>"Набор газового блендера" Приложение («мы», «мы» или «наш») управляет веб-сайтом https://blender-toolkit.t-provider.de и мобильным приложением "Набор газового блендера" («Сервис»).</p>

    <p>Мы используем ваши данные для предоставления и улучшения Сервиса. Используя Сервис, вы соглашаетесь на сбор и использование информации в соответствии с этой политикой. Если иное не определено в настоящей Политике конфиденциальности, термины, используемые в настоящей Политике конфиденциальности, имеют те же значения, что и в наших Условиях и положениях.</p>

    <h3>Сбор и использование информации</h3>

    <p>Мы собираем несколько различных типов информации для различных целей, чтобы предоставить вам и улучшить наш Сервис.</p>

    <h4>Типы собранных данных</h4>

    <h5>Личные данные</h5>

    <p>Во время использования нашего Сервиса мы можем попросить вас предоставить нам определенную личную информацию, которая может быть использована для установления вашей личности («Личные данные»). Личная информация может включать, но не ограничивается:</p>

    <ul>
      <li>Файлы cookie и данные об использовании</li>
    </ul>

    <h5>Данные об использовании</h5>

    <p>Мы не собираем данные об использовании</p>

    <h5>Отслеживание &amp; Данные Cookies</h5>
    <p>Мы можем использовать файлы cookie и аналогичные технологии отслеживания для отслеживания активности в нашем Сервисе и хранения определенной информации.</p>
    <p>Файлы cookie - это файлы с небольшим объемом данных, которые могут содержать анонимный уникальный идентификатор. Файлы cookie отправляются в ваш браузер с веб-сайта и сохраняются на вашем устройстве. Также используются технологии отслеживания: маяки, теги и сценарии для сбора и отслеживания информации, а также для улучшения и анализа нашего Сервиса.</p>
    <p>Вы можете указать браузеру отказаться от всех файлов cookie или указать, когда они отправляются. Однако, если вы не принимаете куки, вы не сможете использовать некоторые части нашего Сервиса.</p>
    <p>Примеры файлов cookie, которые мы можем использовать:</p>
    <ul>
      <li><strong>Сессионные куки.</strong> Мы можем использовать Session Cookies для работы нашего Сервиса.</li>
    </ul>

    <h3>Использование данных</h3>

    <p>"Набор газового блендера" Приложение использует собранные данные для различных целей:</p>
    <ul>
      <li>Предоставлять и поддерживать Сервис</li>
      <li>Чтобы уведомить Вас об изменениях в нашем Сервисе</li>
    </ul>

    <h3>Передача данных</h3>
    <p>Ваша информация, включая Личные данные, может передаваться и храниться на компьютерах, находящихся за пределами вашего штата, провинции, страны или другой государственной юрисдикции, где законы о защите данных могут отличаться от законов вашей юрисдикции.</p>
    <p>Если вы находитесь за пределами Германии и решили предоставить нам информацию, обратите внимание, что мы передаем данные, включая Персональные данные, в Германию и обрабатываем их там.</p>
    <p>Ваше согласие с настоящей Политикой конфиденциальности, сопровождаемое предоставлением вами такой информации, означает ваше согласие на такую передачу.</p>
    <p>Приложение "Набор газового блендера" предпримет все разумно необходимые шаги для обеспечения безопасного обращения с вашими данными в соответствии с настоящей Политикой конфиденциальности и передачи ваших личных данных в организацию или страну, если не будут приняты надлежащие меры контроля. включая безопасность ваших данных и другой личной информации.</p>

    <h3>Раскрытие данных</h3>

    <h4>Правовые требования</h4>
    <p>"Набор газового блендера" Приложение может раскрывать ваши Личные данные, в случаях, что такие действия необходимы для:</p>
    <ul>
      <li>Чтобы выполнить юридическое обязательство</li>
      <li>Для защиты и защиты прав или собственности приложения "Набор газового блендера"</li>
      <li>Чтобы предотвратить или расследовать возможные правонарушения в связи с Сервисом</li>
      <li>Для защиты личной безопасности пользователей Сервиса или общественности</li>
      <li>Для защиты от юридической ответственности</li>
    </ul>

    <h3>Безопасность данных</h3>
    <p>Безопасность ваших данных важна для нас, но помните, что ни один метод передачи через Интернет или метод электронного хранения не является на 100% безопасным. Хотя мы стремимся использовать коммерчески приемлемые средства для защиты ваших личных данных, мы не можем гарантировать их абсолютную безопасность.</p>

    <h3>Поставщики услуг</h3>
    <p>Мы можем нанимать сторонние компании и частных лиц для оказания помощи нашему Сервису («Поставщики услуг»), для предоставления Сервиса от нашего имени, для оказания связанных с Сервисом услуг или для оказания нам помощи в анализе того, как используется наш Сервис.</p>
    <p>Эти третьи стороны имеют доступ к вашим Личным данным только для выполнения этих задач от нашего имени и обязаны не разглашать или использовать их для каких-либо других целей.</p>



    <h3>Ссылки на другие сайты</h3>
    <p>Наш Сервис может содержать ссылки на другие сайты, которые не управляются нами. Если вы нажмете на ссылку третьей стороны, вы будете перенаправлены на сайт этой третьей стороны. Мы настоятельно рекомендуем вам ознакомиться с Политикой конфиденциальности каждого посещаемого вами сайта.</p>
    <p>Мы не контролируем и не несем ответственности за содержание, политику конфиденциальности или действия любых сторонних сайтов или служб.</p>


    <h3>Рейтинг контента</h3>
    <p>без возрастных ограничений</p>

    <h3>Изменения в настоящей Политике конфиденциальности</h3>
    <p>Мы можем обновлять нашу Политику конфиденциальности время от времени. Мы сообщим вам о любых изменениях, разместив новую Политику конфиденциальности на этой странице.</p>
    <p>До того, как изменения вступят в силу, мы сообщим вам об этом по электронной почте и / или в заметном уведомлении о нашем Сервисе и обновим «дату вступления в силу» в верхней части настоящей Политики конфиденциальности.</p>
    <p>Рекомендуется периодически просматривать настоящую Политику конфиденциальности на предмет любых изменений. Изменения в настоящей Политике конфиденциальности вступают в силу с момента их публикации на этой странице.</p>


    <h3>Связаться с нами</h3>
    <p>Если у вас есть какие-либо вопросы об этой Политике конфиденциальности, пожалуйста, свяжитесь с нами:</p>
    <ul>
      <li>По электронной почте: blender-toolkit@t-provider.de</li>

    </ul>
  </div>);
}
