/* 
 * gas blender toolkit
 * Topoff gas - decanting - überströmen - auftoppen
 *
 * Oleg Gurevich, 2019
 */
import React, { Component } from 'react';

import { useTranslation } from 'react-i18next';

import  {WetNotes, WetNotesSettings}  from '../wetnotes/wetnotes.js'
import { GasComponent, COLOR_WETNOTES, DisplayMass, amountToDisplay, amountVolumeToDisplay } from '../WTNCommons'
// import { MODSettings } from './MODSettings.jsx';

import MdSettings from 'react-ionicons/lib/MdSettings'
import MdClose from 'react-ionicons/lib/MdClose'
import IosHelpCircleOutline from 'react-ionicons/lib/IosHelpCircleOutline'
import { TopoffModel } from './topoffModel.js';
import legend from '../topoff.svg';
import { TopoffSettings } from './TopoffSettings.jsx';
import i18next from 'i18next';
import { IsochoreDialogToggler } from '../wetnotes/IsochoreDialogToggler.jsx';
import { StartAtTemperaturInfo } from "../StartAtTemperaturInfo";


const idPostfixShowLegend = ".showLegend";
export class Topoff extends Component {
  constructor(props){
    super(props);
    this.id = props.id !== undefined?props.id:'defaultTopoffComponent';
    this.topoffModel = new TopoffModel();
    this.initValueShowLegend = true;
    this.restoreStateOfTopoff();
    this.state = { toggleSettingsOn : false, showLegend : this.initValueShowLegend};
  }
  restoreStateOfTopoff(){
    const loadedState = localStorage.getItem(this.id);
    if(loadedState !== null){
      this.topoffModel.setStateJSON(JSON.parse(loadedState));
    }
    const loadedShowLegendStatus = localStorage.getItem(this.id + idPostfixShowLegend);
    if(loadedShowLegendStatus !== null){
      this.initValueShowLegend = "1" === loadedShowLegendStatus;
    }
  }
  componentDidMount() {
    this.topoffModel.addPropertyChangeListener(this, [TopoffModel.PROPERTY_TOPOFF_COMPLETED]);
  }
  componentWillUnmount() {
    this.topoffModel.removePropertyChangeListener(this, [TopoffModel.PROPERTY_TOPOFF_COMPLETED]);
  }
  propertyChange(propertyChangeEvent){
    this.saveStateOfTopoff();
    this.setState({lastChangeEvent: propertyChangeEvent});
  }
  saveStateOfTopoff(){
    localStorage.setItem(this.id, JSON.stringify(this.topoffModel.getStateJSON()));
	}
	setShowLegend(show){
    this.setState({showLegend : show});
    localStorage.setItem(this.id + idPostfixShowLegend, show?"1":"0");
  }
  applyRemnantToStorage(){
    this.topoffModel.storageTank.applyTankModel(this.topoffModel.remnantStorageTank);
  }
  applyResultToSink(){
    this.topoffModel.sinkTank.applyTankModel(this.topoffModel.resultSinkTank);
  }
  isApplyRemnantToStorageDisabled(){
    return  Math.abs(this.topoffModel.storageTank.getAmount_mol() -this.topoffModel.remnantStorageTank.getAmount_mol()) < 
      TopoffModel.PRECISION_VALUE / 2;
  }
  isApplyResultToSinkDisabled(){
    return  Math.abs(this.topoffModel.resultSinkTank.getAmount_mol() -this.topoffModel.sinkTank.getAmount_mol()) < 
      TopoffModel.PRECISION_VALUE / 2;
  }
	render() {
    const rowToggledOn = "row";
		const rowToggledOff = "row d-none d-lg-flex";
    const rowToggledOnJc = "row justify-content-center";
    const rowToggledOffJc = "row d-none d-lg-flex justify-content-center";
    const applyRemnantToStorageDisabled = this.isApplyRemnantToStorageDisabled();
    const applyResultToSinkDisabled = this.isApplyResultToSinkDisabled();
		const divStyle = {  minWidth: '22em' };
		const helpIcon = (this.state.showLegend || this.state.toggleSettingsOn)?"":(
			<span onClick = {() => this.setShowLegend(true)} >
				<IosHelpCircleOutline  fontSize="1.2em" color={COLOR_WETNOTES}></IosHelpCircleOutline>
			</span>
		);
		  return (
            <div className = "container p-0 mt-2 text-left">
            <h4>{i18next.t('Topoff')} {helpIcon}</h4>
													
                <div className={this.state.toggleSettingsOn?rowToggledOffJc:rowToggledOnJc}>
								{ this.state.showLegend &&
									<div className = "col-lg-6">
										<div style={divStyle} className="bg-white p-3 rounded-lg border mb-4 text-left position-relative">
											<div className="wtn-dimiss-absolute d-inline-block " onClick = {() => this.setShowLegend(false)}>
													<MdClose fontSize="1.6em" color={COLOR_WETNOTES}></MdClose>
											</div>
											<TopoffLegend></TopoffLegend>
										</div>
									</div>
								}
                </div>
                <h6><StartAtTemperaturInfo/></h6>
                <div className={this.state.toggleSettingsOn?rowToggledOff:rowToggledOn}>
                  <div className = "col-sm">
                    <GasComponent tankModel = {this.topoffModel.storageTank} ></GasComponent>
                  </div>
									<div className = "col-sm">
                    <GasComponent tankModel = {this.topoffModel.sinkTank} ></GasComponent>
                  </div>
                <div className = "col-sm">
                  <GasComponent gasModel = {this.topoffModel.desiredPressureHolder} 
                    showTankVisualisation = {false} showFraction = {false} ></GasComponent>
                </div>
              </div>

              <div className={this.state.toggleSettingsOn?rowToggledOff:rowToggledOn}>
                <div className = "col-sm order-lg-2">
									<GasComponent tankModel = {this.topoffModel.resultSinkTank} showVolume={false} 
									pressureReadOnly = {true}
									fractionReadOnly = {true}  >
                   <button type = "button" disabled = {applyResultToSinkDisabled} onClick = {() => this.applyResultToSink()}
                   className="btn btn-outline-success">{i18next.t('Apply to sink')}</button> <IsochoreDialogToggler tankModel = {this.topoffModel.resultSinkTank.clone()}></IsochoreDialogToggler> 
                  </GasComponent>
                </div>
                <div className = "col-sm order-lg-3">
                  <TopoffResultTable topoffModel = {this.topoffModel}></TopoffResultTable>
                </div>
                <div className = "col-sm order-lg-1">
                  <GasComponent tankModel = {this.topoffModel.remnantStorageTank} showVolume={false} 
                  pressureReadOnly = {true}
                  showTankVisualisation = {false}
                  showFraction = {false}
                  fractionReadOnly = {true}  >
                  <button type = "button" disabled = {applyRemnantToStorageDisabled} onClick = {() => this.applyRemnantToStorage()} 
                  className="btn btn-outline-success">{i18next.t('Apply to storage')}</button> <IsochoreDialogToggler tankModel = {this.topoffModel.remnantStorageTank.clone()}></IsochoreDialogToggler> 
                  </GasComponent>
                </div>
              </div>
                <div className={this.state.toggleSettingsOn?rowToggledOn:rowToggledOff}>
                  <div className = "col-sm">
                    <TopoffSettings></TopoffSettings>
                  </div>
                </div>
             
            
            <div className="wtn-settings-toggler d-inline-block d-lg-none" onClick={e => this.setState({toggleSettingsOn: !this.state.toggleSettingsOn})}>
							{this.state.toggleSettingsOn?
								<MdClose fontSize="3em" color={COLOR_WETNOTES}></MdClose>:<MdSettings fontSize="3em" color={COLOR_WETNOTES}></MdSettings>
							}
            </div>
            </div>
    )
  }
}
function TopoffLegend(props){
  const ulStyle = {  listStyleType: 'none' };
  const { t } = useTranslation();
	return(
		<div>
			<h5>{t('Legend')}</h5>
			<img className = "w-100" src={legend} alt="legend" />
			<ul style={ulStyle} className="font-weight-normal">
				<li>
					{t('A) Storage tank with topoff gas')};
				</li>
				<li>
					{t('B) Receiver scuba tank (sink) you about to topoff')};
				</li>
				<li>
					{t('topoff.desired.pressure')}
				</li>
			</ul>
		</div>
	)
}
class TopoffResultTable extends Component{
  constructor(props){
    super(props);
    this.topoffModel = props.topoffModel;
    this.bulkWrapper = (undefined === props.bulkWrapper)?false:props.bulkWrapper;
    this.state = {lastChangeEvent : null};
    this.initTransferData();
  }
  componentDidMount() {
    this.topoffModel.addPropertyChangeListener(this, [TopoffModel.PROPERTY_TOPOFF_COMPLETED]);
    WetNotes.SETTINGS.addPropertyChangeListener(this, [WetNotesSettings.PROPERTY_SHOW_DETAILS, WetNotesSettings.PROPERTY_UNITS])
  }
  componentWillUnmount() {
    this.topoffModel.removePropertyChangeListener(this, [TopoffModel.PROPERTY_TOPOFF_COMPLETED]);
    WetNotes.SETTINGS.removePropertyChangeListener(this, [WetNotesSettings.PROPERTY_SHOW_DETAILS, WetNotesSettings.PROPERTY_UNITS])
  }
  propertyChange(propertyChangeEvent){
    this.initTransferData();
    this.setState({lastChangeEvent : propertyChangeEvent});
  }
  initTransferData(){
    this.deltaStorageTank = this.topoffModel.storageTank.clone();
    this.deltaStorageTank.dropGas(this.topoffModel.remnantStorageTank.getAmount_mol());
  }
 
  getTransferredAmount(){
    return this.deltaStorageTank.getAmount_mol();
  }
  getTransferredMass(){
    return this.deltaStorageTank.getMasse();
  }
  render(){
    const showDetails = WetNotes.SETTINGS.isShowDetails();
    const kg =  " (" + WetNotes.MEASSURES.Masse.getLabel() + ")";
    const barliter = " (" + WetNotes.barliterLabelToView() + ")";
    var divStyle = {  minWidth: '22em', };
    const retailWrapperClassName = "bg-white p-3 rounded-lg border mb-4 text-left";
    const bulkWrapperClassName = "p-3 mb-4 text-left";
    const divClassName = this.bulkWrapper?bulkWrapperClassName:retailWrapperClassName;
    return (
      <div style={divStyle} className={divClassName}>

      <h5>{i18next.t('Transfers available')}: {this.topoffModel.getAvailableFillCount()}</h5>
      <table className = "table table-striped">
        <thead>
          <tr>
            <th scope="col">{i18next.t('Transfer')}</th>
            <th scope="col">{i18next.t('Amount') + barliter}</th>
            {showDetails && <th scope="col">{i18next.t('Amount (mol)')}</th>}
            {showDetails && <th scope="col">{i18next.t('Mass') + kg}</th>}
          </tr>
        </thead>
        <tbody>
        <tr>
            <td>{i18next.t('Topoff')}</td>
            <th scope="row">{amountVolumeToDisplay(this.deltaStorageTank)}</th>
            {showDetails && <td>{amountToDisplay(this.getTransferredAmount())}</td>}
            {showDetails && <td><DisplayMass mass = {this.getTransferredMass()}></DisplayMass></td>}
          </tr>
        </tbody>
      </table>
      </div>
    )
  }
}