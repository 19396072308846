/* 
 * About Component
 *
 * 
 * Oleg Gurevich, 2019
 */
import React from 'react';

import { COLOR_WETNOTES, CSS_CLASSNAME_PANEL, ANDROID_APP_USER_AGENT_SUBSTRING } from './WTNCommons'
import MdClose from 'react-ionicons/lib/MdClose'
import btnDonate from './btn_donateCC_LG.gif'
import i18next from 'i18next'
import buildInfo from './buildInfo.js'
import { GIT_COMMIT_HASH } from "./git-commit-hash";

/**
 * @param provide function props.toClose can be used
 */
export function About(props){
  return (
    <div className = "mt-4 " >
      <div className = "row justify-content-center" >
        <div className = "col col-md-8" >
          <div className={CSS_CLASSNAME_PANEL}>
          <AboutContent toClose = {props.toClose}></AboutContent>
        </div>
        <div className='text-right mb-2'>
				  <small className='text-muted'>git commit hash: {GIT_COMMIT_HASH}</small>
			</div>
      </div>
      </div>
    </div>
  )
}
function DonateButton(props){
  return(
    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_new">
    <input type="hidden" name="cmd" value="_s-xclick" />
    <input type="hidden" name="hosted_button_id" value="PYPYFWJUB2QSG" />
    <input type="image" src="https://www.paypalobjects.com/en_US/DK/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
    <img alt="" border="0" src={btnDonate} width="1" height="1" />
  </form>
)
}
/**
 * @param provide function props.toClose can be used
 */
function AboutContent(props){
  const toClose = props.toClose === undefined ? '':props.toClose;
  return (
    <div className="position-relative">
    <div className="wtn-dimiss-absolute-disclaimer d-inline-block " onClick = {() => toClose()}>
          <MdClose fontSize="2em" color={COLOR_WETNOTES}></MdClose>
    </div>
      <h2>{i18next.t('about.h2')}</h2>
      <p>Version: v{buildInfo.version} build: {buildInfo.build}</p>
      
      <p>{i18next.t('about.intro')}</p>
{ !navigator.userAgent.includes(ANDROID_APP_USER_AGENT_SUBSTRING) &&
<div>
  <h5>{i18next.t('about.uff')}</h5>
  <p>{i18next.t('about.uff.text')}</p>
  {/* <div className="alert alert-light font-weight-bold">
    {i18next.t('about.pwa')}
  </div> */}
  <div className="alert alert-info">
  <p>{i18next.t('about.donate')}
  </p>
  <DonateButton></DonateButton>
  </div>
</div>
}
<h5>{i18next.t('about.features')}:</h5>
<ul>
  <li>
    {i18next.t('about.features.blending')}
  </li>
  <li>
    {i18next.t('about.features.topoff')}
  </li>
  <li>
    {i18next.t('about.features.fillorder')}
  </li>
  <li>
    {i18next.t('about.features.realgas')}
  </li>
  <li>
    {i18next.t('about.features.costs')}
  </li>
  <li>
    {i18next.t('about.features.units')}
  </li>
  <li>
    {i18next.t('about.features.noadvert')}
  </li>
  <li>
    {i18next.t('about.features.offline')}
  </li>
</ul>
<h5>{i18next.t('about.thanks.h')}</h5>
<p> 
  {i18next.t('about.thanks')}
</p>
<p>{i18next.t('about.contact')}: <a href="mailto: blender-toolkit@t-provider.de">blender-toolkit@t-provider.de</a></p>
<p>
  {i18next.t('copyrights')} &copy; {i18next.t('copyrights.text')}
</p>
</div>
  )
}