import i18next from 'i18next'
import React from 'react';

import { WetNotes, WetNotesSettings } from '../wetnotes/wetnotes';
import { PSCRResultBuilder } from './PSCRResultBuilder';

const relevantSettings = [WetNotesSettings.PROPERTY_LANGUAGE_UI, WetNotesSettings.PROPERTY_MAX_END,
	WetNotesSettings.PROPERTY_MAX_PPO2, WetNotesSettings.PROPERTY_MIN_PPO2, WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_He_TO_N2,
	WetNotesSettings.PROPERTY_NARCOTIC_EFFECT_FACTOR_O2_TO_N2, WetNotesSettings.PROPERTY_SHOW_DETAILS, WetNotesSettings.PROPERTY_UNITS];

export class PSCRTableEstablishedComponent extends React.Component {
	constructor(props){
		super(props);
		this.pscrModel = props.pscrModel;
		this.resultBuilder = new PSCRResultBuilder(this.pscrModel);
	}
	componentDidMount() {
		this.pscrModel.addPropertyChangeListener(this);
		WetNotes.SETTINGS.addPropertyChangeListener(this, relevantSettings);
	}
	componentWillUnmount() {
		this.pscrModel.removePropertyChangeListener(this);
		WetNotes.SETTINGS.removePropertyChangeListener(this, relevantSettings);
	}
	propertyChange(propertyChangeEvent){
    this.setState({lastChangeEvent : propertyChangeEvent}); 
	}
	render() {
		return (
			<div>
				<h5>{i18next.t('pscr.table.established.title')}</h5>
				<table className = "table table-sm table-responsive-sm table-striped">
					<TableHeadRenderer pscrModel = {this.pscrModel} ></TableHeadRenderer>
					<TableBodyRenderer pscrModel = {this.pscrModel} resultBuilder = {this.resultBuilder}></TableBodyRenderer>
					
				</table>
		</div>
		)
	}
}

function TableBodyRenderer(props){
	const resultTableData = props.resultBuilder.createResultTableData();
	return (
			<tbody>
				{
						resultTableData.map((item) => (<ResultTableRow pscrModel = {props.pscrModel} rowData = {item}></ResultTableRow>))
				}
			</tbody>
	)
}
function TableHeadRenderer(props){
	const depthLabel = WetNotes.MEASSURES.Depth.getLabel();
	const showDetails = WetNotes.SETTINGS.isShowDetails();
	return (
		<thead>
			<tr>
				<th scope="col" className="border-right">{i18next.t('Depth')} ({depthLabel})</th>
				{showDetails &&
				<th scope="col">{i18next.t('fiO2.supplied')}</th>
				}
				<th scope="col">{i18next.t('ppO2.supplied')}</th>
				<th scope="col" className="border-left">{i18next.t('fiO2.established')}</th>
				<th scope="col">{i18next.t('O2 p.p.')}</th>
				<th scope="col">{i18next.t('Narcotic p.p.')}</th>
				{ showDetails && props.pscrModel.gasModel.getHe() > 0 &&
				<th scope="col">{i18next.t('He p.p.')}</th>
				}
				{ showDetails && props.pscrModel.gasModel.getN2() > 0 &&
				<th scope="col">{i18next.t('N2 p.p.')}</th>
				}
			</tr>
		</thead>
	)
}
function ResultTableRow(props){
	const rowData = props.rowData;
	//const maxEND = WetNotes.SETTINGS.getMaxEND();
	const maxPPO2 = WetNotes.SETTINGS.getMaxPpO2();
	const minPPO2 = WetNotes.SETTINGS.getMinPpO2();
	const showDetails = WetNotes.SETTINGS.isShowDetails();
	const modRangeExceeded = rowData.ppEstablishedO2 < minPPO2 || rowData.ppEstablishedO2 >= maxPPO2;
	const modRangeSuppliedExceeded = rowData.ppO2supplied < minPPO2 || rowData.ppO2supplied >= maxPPO2;
	const endExceeded = rowData.narcPP > WetNotes.SETTINGS.getNarcPPOfMaxEND();
	let trClassName = "";
	if(endExceeded) trClassName="table-warning"
	if(modRangeExceeded) trClassName="table-danger"
	return (
		<tr className = {trClassName}>
			<td className="border-right">{WetNotes.formatDepth(WetNotes.MEASSURES.Depth.getAdjustedOfMetric(rowData.depth))}</td>
			{showDetails &&
			<td>{WetNotes.formatPercent(rowData.startFiO2)}</td>
			}
			<td className = {modRangeSuppliedExceeded?"text-danger font-weight-bold":""}>{WetNotes.formatPercent(rowData.ppO2supplied)}</td>
			<td className="font-weight-bold border-left">{WetNotes.formatPercent(rowData.establishedFiO2)}</td>
			<td className = {modRangeExceeded?"text-danger font-weight-bold":"font-weight-bold"} >
				{WetNotes.formatPercent(rowData.ppEstablishedO2)}
			</td>
			<td className = {endExceeded?"text-danger font-weight-bold":""} >
			{WetNotes.formatPercent(rowData.narcPP)}</td>
			{ showDetails && props.pscrModel.gasModel.getHe() > 0 &&
			<td>{WetNotes.formatPercent(rowData.ppHe)}</td>
			}
			{ showDetails &&props.pscrModel.gasModel.getN2() > 0 &&
			<td>{WetNotes.formatPercent(rowData.ppN2)}</td>
			}     
		</tr>     
	)
}

