import React from 'react';

import i18next from 'i18next';
import { WetNotes, WetNotesSettings } from './wetnotes';
import { ElevationLabel, ElevationComponent, EnvironmentModel } from './Environment';
import { UnitsAta } from '../WTNCommons';
import { PhysicsCore } from './PhysicsCore';

const relevantSettings = [WetNotesSettings.PROPERTY_UNITS, WetNotesSettings.PROPERTY_SHOW_DETAILS];
export class EnvironmentComponent extends React.Component{
  constructor(props){
    super(props);
    
    this.pscrModel = props.pscrModel;
    this.nameI18n = (undefined === props.nameI18n)?"noname":props.nameI18n;
    
		this.volumeReadOnly = (undefined === props.volumeReadOnly)?false:props.volumeReadOnly;
		this.dumpratioReadOnly = (undefined === props.dumpratioReadOnly)?false:props.dumpratioReadOnly;
		this.dumpratioOfInjectedReadOnly = (undefined === props.dumpratioOfInjectedReadOnly)?false:props.dumpratioOfInjectedReadOnly;
    
    this.state = { showInputFields: false };
    
  }
 	propertyChange(propertyChangeEvent){
    this.setState({lastChangeEvent : propertyChangeEvent}); 
  }

  componentDidMount() {
		WetNotes.SETTINGS.addPropertyChangeListener(this, relevantSettings);
		this.pscrModel.environmentModel.addPropertyChangeListener(this, [EnvironmentModel.PROPERTY_ELEVATION]);
  }

  componentWillUnmount() {
		WetNotes.SETTINGS.removePropertyChangeListener(this, relevantSettings);
		this.pscrModel.environmentModel.removePropertyChangeListener(this, [EnvironmentModel.PROPERTY_ELEVATION]);
  }
    
  render() {
    return (
      <div className={this.props.className}>
        <h5>{i18next.t(this.nameI18n)}</h5>
        <div className = "text-left">
          <div className="row">
            <div className="col-sm-3">
              <EnvironmentVisualisation></EnvironmentVisualisation>
            </div>
            <div className="col-sm-9">
              <div className="row">
                <div className="col-6">
                  	<ElevationLabel></ElevationLabel>
										<ElevationComponent environmentModel = {this.pscrModel.environmentModel} className="h5"></ElevationComponent>
                </div>
								<div className="col-6">
                  <StandardAirPressureLabel></StandardAirPressureLabel>
                  <StandardAirPressure className="h5 readonlyValue" elevation = {this.pscrModel.environmentModel.getElevation()}></StandardAirPressure>
                  { WetNotes.SETTINGS.isShowDetails() &&
                    <div>
											<PartialPressureO2InAirLabel></PartialPressureO2InAirLabel>
											<PartialPressureO2InAir className="h5 readonlyValue" elevation = {this.pscrModel.environmentModel.getElevation()}></PartialPressureO2InAir>
										</div>
									} 
                </div>
              </div>
              
            </div>
          </div>
        </div>
        {this.props.children}
      </div>

    )
  }
}
function EnvironmentVisualisation(props){
	return(
		<div className="d-none d-sm-block position-relative">
			{/* <img src={imgMountain} alt="Environment" /> */}
		</div>
	)
}
function StandardAirPressureLabel(props){
  const labelText = i18next.t('pressure.at.elevation');
  return (
        <label className="control-label">{labelText} (<UnitsAta></UnitsAta>)</label>
  )
}
function PartialPressureO2InAirLabel(props){
  const labelText = i18next.t('ppo2.in.air');
  return (
        <label className="control-label">{labelText}</label>
  )
}
function StandardAirPressure(props){
  const atm = PhysicsCore.getAirPressureAtElevationAta(props.elevation);
  return (
		<div className = {props.className}>{WetNotes.formatPercent(atm)}</div>
  )
}
function PartialPressureO2InAir(props){
  const atm = PhysicsCore.getAirPressureAtElevationAta(props.elevation);
  return (
		<div className = {props.className}>{WetNotes.formatPercent(0.21 * atm)}</div>
  )
}
